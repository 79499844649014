import { defineComponent } from 'vue';
import { FlightAdvisories } from '../../../models/consts';
import { isSameDay, differenceInCalendarDays } from 'date-fns';
import { utilService } from '@/services/util.service';
import flightmixins from '@/modules/products/flight/mixins/flight.mixins';
import NewSeatLabel from '@/modules/products/flight/components/new-seat-label/new-seat-label.vue';
export default defineComponent({
    name: 'FlightPreviewSegment',
    components: {
        NewSeatLabel,
    },
    mixins: [flightmixins],
    props: {
        flightSegment: {
            type: Object,
            default: () => {
                return {};
            },
        },
        isDetails: {
            type: Boolean,
            default: false,
        },
        isMobile: {
            type: Boolean,
            default: false,
        },
        isPricingOptions: {
            type: Boolean,
            default: false,
        },
        isSeparateTickets: {
            type: Boolean,
            default: false,
        },
        isCart: {
            type: Boolean,
            default: false,
        },
        isLastSegment: {
            type: Boolean,
            default: false,
        },
        isOnBySchedulePricingPage: {
            type: Boolean,
            default: false,
        },
        markedSegmentDirection: {
            type: Object,
        },
    },
    methods: {
        getDepartureTime(departureDate) {
            let departureTime = departureDate.split('T')[1];
            departureTime = departureTime.substring(0, departureTime.length - 3);
            return utilService.getUserTimeFormat(departureTime);
        },
        getAdvisoryTooltipContent(advisory) {
            const advisoryText = `${advisory?.title?.charAt(0)?.toUpperCase()}${advisory?.title?.toLowerCase()?.slice(1)}` ||
                getFlightAdvisoriesDisplayName(advisory.imageClass);
            if (advisoryText.toLowerCase().includes('bus service')) {
                return this.$t('flight.advisory.types.busService').toString();
            }
            if (advisoryText.toLowerCase().includes('rail service')) {
                return this.$t('flight.advisory.types.railService').toString();
            }
            if (advisoryText.toLowerCase().includes('mix service')) {
                return this.$t('flight.advisory.types.mixedTransportation').toString();
            }
            return advisoryText;
        },
        formattedDateStr(dateStr) {
            const formattedDate = this.isMobile
                ? this.$filters.date(dateStr, 'dd MMM yy')
                : this.$filters.date(dateStr, 'eee, dd MMM yy');
            return `${formattedDate}`;
        },
    },
    computed: {
        advisories() {
            return utilService.deepClone(this.flightSegment.advisories).sort((a, b) => {
                if (a.imageClass === FlightAdvisories.trainTransportationAdvisory ||
                    a.imageClass === FlightAdvisories.busTransportationAdvisory) {
                    return 1;
                }
                if (b.imageClass === FlightAdvisories.trainTransportationAdvisory ||
                    b.imageClass === FlightAdvisories.busTransportationAdvisory) {
                    return -1;
                }
                return 0;
            });
        },
        flightNumbersForDisplay() {
            let flightNumbersForDisplay = '';
            if (this.isMultiCarrier) {
                this.flightSegment.flights.forEach((flight, index) => {
                    flightNumbersForDisplay += flight.carrier + flight.flightNumber;
                    if (index < this.flightSegment.flights.length - 1) {
                        flightNumbersForDisplay += ', ';
                    }
                });
            }
            else {
                flightNumbersForDisplay = this.flightSegment.flights[0].carrier + this.flightSegment.flights[0].flightNumber;
            }
            return flightNumbersForDisplay;
        },
        isMultiCarrier() {
            const firstCarrierName = this.flightSegment.flights[0].carrierName;
            return !this.flightSegment.flights.every((flight) => flight.carrierName === firstCarrierName);
        },
        isOvernightSegment() {
            return !isSameDay(new Date(this.flightSegment.flights[this.flightSegment.flights.length - 1].arrDate), new Date(this.flightSegment.flights[0].depDate));
        },
        daysDiff() {
            return differenceInCalendarDays(new Date(this.flightSegment.flights[this.flightSegment.flights.length - 1].arrDate), new Date(this.flightSegment.flights[0].depDate));
        },
        isAnyNewSeatFare() {
            let containsNewSeat = false;
            if (this.flightSegment.flights) {
                this.flightSegment.flights.forEach((a) => {
                    if (a.isNewSeatFare) {
                        containsNewSeat = true;
                    }
                });
            }
            return containsNewSeat;
        },
        carrierIcon() {
            let res = '';
            try {
                res = require(`@/assets/img/flights/airlinesIcons/${this.flightSegment.flights[0].carrier}.gif`);
            }
            catch (e) {
                res = require('@/assets/icon/flight.svg');
            }
            return res;
        },
        flightAdvisories() {
            return FlightAdvisories;
        },
        hasStatusSlot() {
            return !!this.$slots['segment-status'];
        },
        operatedBy() {
            let operatedBy = '';
            if (this.flightSegment.flights[0].flightCarrierNameOprtBy) {
                operatedBy = this.flightSegment.flights[0].flightCarrierNameOprtBy;
            }
            else if (this.flightSegment.flights[0].flightNumberOprtBy) {
                operatedBy = this.flightSegment.flights[0].flightNumberOprtBy;
            }
            return operatedBy;
        },
    },
});
