import { httpService } from './http.service';
import { tripService } from '@/services/trip.service';
import { eventBus } from '@/services/event-bus.service';
import { SuppliersId } from '@/modules/products/hotels/models/consts';
import { Products } from '@/types/consts';
import { ElNotification } from 'element-plus';
const hotelGDSSuppliers = [SuppliersId.AMADEUS, SuppliersId.UAPIGALILEO, SuppliersId.UAPIWORLDSPAN, SuppliersId.UAPIGALILEO, SuppliersId.SABRE];
const hotelSuppliersNotifications = ['GDS', SuppliersId.EHOTEL, SuppliersId.EXPEDIARAPID, SuppliersId.GTA, SuppliersId.BOOKINGCOM, SuppliersId.GOGLOBAL, SuppliersId.RATEHAWK];
export const reminderService = {
    getNotificationsAddUser,
    showOptionalNotifications: showNotificationReminders,
    getNotificationsHotelSearch,
    getNotificationsCarSearch,
    initPassengersRequest: getGDSPassengersNotificationRequest,
    getCityCode,
    getNotificationsParkingSearch,
    getNotificationsHexSearch,
    getNotificationsEvolviSearch,
    markRemindersAsRead,
    getNotificationsCart,
    initHotelQuoteRequest,
    initBaseRequest,
    initBaseInfoProduct,
    initRequestInfo,
    getNotificationsParkingQuote,
    getNotificationsHEXQuote,
    getNotificationsGSQuote,
    getNotificationsCarQuote,
    getNotificationsEvolviQuote,
    getNotificationsFlightSearch,
    getNotificationsGroundTransportSearch,
    getNotificationsAmtrakSearch,
    getNotificationsRailSearch,
    getNotificationsRailQuote
};
function initBaseRequest(tripId, corporationId) {
    const notifRequest = {
        Requests: [],
        TripId: tripId,
        CorporateId: corporationId
    };
    return notifRequest;
}
function initBaseInfoProduct(checkIn, checkOut, origins, product, destinations, supplier, pointOfInterestId) {
    const info = {
        Destinations: destinations,
        Origins: origins,
        Product: product,
        Supplier: supplier,
        TravelStartDate: checkIn,
        TravelValidEndDate: checkOut,
        PointOfInterestId: pointOfInterestId,
        Longitude: '',
        Latitude: '',
        LongitudeDropoff: '',
        LatitudeDropoff: '',
    };
    return info;
}
function initRequestInfo() {
    const request = {
        AccountProfile: [],
        PaxProfile: [],
        TripType: 0,
        Info: []
    };
    return request;
}
function createNotificationRequest(accountProfiles, paxProfiles, info) {
    return {
        AccountProfile: accountProfiles,
        PaxProfile: paxProfiles,
        TripType: 0,
        Info: [info]
    };
}
function initHotelQuoteRequest(hotel) {
    const request = initRequestInfo();
    let supplier = '';
    if (hotel.sourceSystem) {
        const lowerSourceSystem = hotel.sourceSystem.toLowerCase();
        const hotelSupplier = hotelSuppliersNotifications.find(a => a.toLowerCase() === lowerSourceSystem);
        if (hotelSupplier) {
            supplier = hotelSupplier;
        }
        else {
            const isGDS = hotelGDSSuppliers.some(a => a.toLowerCase().startsWith(lowerSourceSystem));
            if (isGDS) {
                supplier = 'GDS';
            }
        }
    }
    const info = initBaseInfoProduct(hotel.checkinDate, hotel.checkoutDate, [hotel.cityCode], Products.Hotel, [hotel.cityCode], supplier, 0);
    request.Info.push(info);
    return request;
}
async function getNotificationsAddUser(accountProfile, paxGDSLocator, corporationId, tripId) {
    if (!accountProfile && !paxGDSLocator) {
        return Promise.resolve([]);
    }
    const notificationRequest = initBaseRequest(tripId, corporationId);
    const request = {
        AccountProfile: [accountProfile],
        PaxProfile: [paxGDSLocator],
        TripType: 0,
        Info: []
    };
    notificationRequest.Requests.push(request);
    return await httpService.post('/pushNotifications', notificationRequest);
}
async function getNotificationsCart(quotes, tripid, corporationId) {
    const notify = initBaseRequest(tripid, corporationId);
    const passengerNotifications = await getGDSPassengersNotificationRequest(tripid);
    const hasData = passengerNotifications.AccountProfile.length > 0 || passengerNotifications.PaxProfile.length > 0;
    if (hasData) {
        notify.Requests.push({
            AccountProfile: passengerNotifications.AccountProfile,
            PaxProfile: passengerNotifications.PaxProfile,
            TripType: 0,
            Info: []
        });
    }
    if (quotes) {
        if (quotes.hotelQuotes && quotes.hotelQuotes.length > 0) {
            quotes.hotelQuotes.forEach(a => {
                notify.Requests.push(initHotelQuoteRequest(a));
            });
        }
        if (quotes.flightQuotes && quotes.flightQuotes.length > 0) {
            quotes.flightQuotes.forEach(a => {
                notify.Requests.push(getNotificationsFlightQuote(a));
            });
        }
        if (quotes.amtrakQuotes && quotes.amtrakQuotes.length > 0) {
            quotes.amtrakQuotes.forEach(a => {
                notify.Requests.push(getNotificationsAmtrakQuote(a));
            });
        }
        if (quotes.parkingQuotes && quotes.parkingQuotes.length > 0) {
            quotes.parkingQuotes.forEach(a => {
                notify.Requests.push(getNotificationsParkingQuote(a));
            });
        }
        if (quotes.heathrowExpresQuotes && quotes.heathrowExpresQuotes.length > 0) {
            quotes.heathrowExpresQuotes.forEach(a => {
                notify.Requests.push(getNotificationsHEXQuote(a));
            });
        }
        if (quotes.groundServiceQuotes && quotes.groundServiceQuotes.length > 0) {
            quotes.groundServiceQuotes.forEach(a => {
                notify.Requests.push(getNotificationsGSQuote(a));
            });
        }
        if (quotes.carQuotes && quotes.carQuotes.length > 0) {
            quotes.carQuotes.forEach(a => {
                notify.Requests.push(getNotificationsCarQuote(a));
            });
        }
        if (quotes.evolviQuotes && quotes.evolviQuotes.length > 0) {
            quotes.evolviQuotes.forEach(a => {
                notify.Requests.push(getNotificationsEvolviQuote(a));
            });
        }
        if (quotes.eURailQuotes && quotes.eURailQuotes.length > 0) {
            quotes.eURailQuotes.forEach(a => {
                notify.Requests.push(getNotificationsRailQuote(a));
            });
        }
    }
    const reminders = await httpService.post('/pushNotifications', notify);
    showNotificationReminders(reminders);
    return null;
}
async function markRemindersAsRead(ids, tripId) {
    return await httpService.post(`/pushNotifications?tripId=${tripId}`, ids);
}
function showNotificationReminders(reminders) {
    if (reminders && reminders.length > 0) {
        const optionals = reminders.filter(a => a && a !== null && a.type === 11);
        const mandatory = reminders.filter(a => a && a !== null && a.type === 10);
        if (mandatory && mandatory.length > 0) {
            eventBus.$emit('showReminderPopup', mandatory);
        }
        if (optionals && optionals.length > 0) {
            optionals.forEach(a => {
                //setTimeout to prevent notifications to be on each other (element ui bug)
                setTimeout(() => {
                    ElNotification({
                        title: '',
                        message: a.text,
                        type: 'info',
                        dangerouslyUseHTMLString: true,
                        position: 'top-right',
                        duration: 7000
                    });
                }, 0);
            });
        }
    }
}
async function getNotificationsHotelSearch(request, tripId, corporationId) {
    const notificationRequest = initBaseRequest(tripId, corporationId);
    const dest = getCityCode(request.loc.location);
    const info = initBaseInfoProduct(new Date(request.checkInDate), new Date(request.checkOutDate), [dest], Products.Hotel, [dest], '', 0);
    info.Longitude = request.loc.lng.toString();
    info.Latitude = request.loc.lat.toString();
    const passengersRequest = await getGDSPassengersNotificationRequest(tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsAmtrakSearch(request) {
    const notificationRequest = initBaseRequest(request.tripId, request.user.corporateId);
    const destinations = [];
    const origins = [];
    request.searchData.segments.forEach(seg => {
        const from = seg.from;
        const to = seg.to;
        let match = from.match(/\((.*?)\)/);
        if (match && match[1]) {
            let res = match[1];
            origins.push(res);
            match = to.match(/\((.*?)\)/);
            res = match[1];
            destinations.push(res);
        }
    });
    const info = initBaseInfoProduct(undefined, undefined, origins, Products.Amtrak, destinations, '', null);
    const passengersRequest = await getGDSPassengersNotificationRequest(request.tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsFlightSearch(request) {
    const notificationRequest = initBaseRequest(request.tripId, request.user.corporateId);
    const destination = request.segments[0].destination;
    const origin = request.segments[0].origin;
    const travelStartDate = request.segments[0].departDateAt;
    const travelValidEndDate = request.segments[request.segments.length - 1].departDateAt;
    const info = initBaseInfoProduct(new Date(travelStartDate), new Date(travelValidEndDate), [origin], request.product, [destination], '', 0);
    const passengersRequest = await getGDSPassengersNotificationRequest(request.tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsGroundTransportSearch(request) {
    //   TODO: Ground Transport search (when available)
    return null;
}
async function getNotificationsRailSearch(request, tripId, corporationId) {
    const destination = extractRailStationCode(request.Destination);
    const origin = extractRailStationCode(request.Origin);
    const notificationRequest = initBaseRequest(tripId, corporationId);
    const info = initBaseInfoProduct(new Date(request.DepartDate), request.ReturnDate ? new Date(request.ReturnDate) : new Date(request.DepartDate), [origin], Products.Benerail, [destination], '', 0);
    const passengersRequest = await getGDSPassengersNotificationRequest(tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsEvolviSearch(request, tripId, corporationId) {
    const notificationRequest = initBaseRequest(tripId, corporationId);
    const info = initBaseInfoProduct(new Date(request.DepartDate), request.ReturnDate ? new Date(request.ReturnDate) : new Date(request.DepartDate), [getCityCode(request.Origin)], Products.Evolvi, [getCityCode(request.Destination)], '', 0);
    const passengersRequest = await getGDSPassengersNotificationRequest(tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getNotificationsParkingSearch(request, tripId, corporationId) {
    const notificationRequest = initBaseRequest(tripId, corporationId);
    const info = initBaseInfoProduct(new Date(request.DepartureDate), new Date(request.ArrivalDate), [request.Origin], Products.Parking, [request.Origin], '', 0);
    const passengersRequest = await getGDSPassengersNotificationRequest(tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
function getNotificationsParkingQuote(request) {
    const req = initRequestInfo();
    const info = initBaseInfoProduct(new Date(request.segment.departureDate), new Date(request.segment.arrivalDate), [request.segment.airportCode], Products.Parking, [request.segment.airportCode], '', 0);
    req.Info.push(info);
    return req;
}
function getNotificationsHEXQuote(request) {
    const req = initRequestInfo();
    const info = initBaseInfoProduct(new Date(request.departureDate), new Date(request.departureDate), ['LON'], 'HeathrowExpress', ['LON'], '', 0);
    req.Info.push(info);
    return req;
}
function getNotificationsCarQuote(request) {
    const req = initRequestInfo();
    const info = initBaseInfoProduct(new Date(request.carSegment.pickUpDate), new Date(request.carSegment.dropOffDate), [request.carSegment.pickUpLocation], Products.Car, [request.carSegment.dropOffLocation], request.carSegment.vendorCode, 0);
    req.Info.push(info);
    return req;
}
function getNotificationsAmtrakQuote(request) {
    const req = initRequestInfo();
    const destinations = [];
    const origins = [];
    let supplier = '';
    request.journeySegments.forEach(seg => {
        destinations.push(seg.arrivalStation);
        origins.push(seg.departureStation);
        supplier += `;${seg.companyShortName}`;
    });
    const travelStartDate = request.journeySegments[0]?.journeyStartDate;
    const travelValidEndDate = request.journeySegments[request.journeySegments.length - 1]?.journeyEndDate;
    const info = initBaseInfoProduct(new Date(travelStartDate), new Date(travelValidEndDate), origins, request.product, destinations, supplier, 0);
    req.Info.push(info);
    return req;
}
// flight quote
function getNotificationsFlightQuote(request) {
    const req = initRequestInfo();
    const destinations = [];
    const origins = [];
    let supplier = '';
    request.ruleSegments.forEach(seg => {
        destinations.push(seg.destination);
        origins.push(seg.origin);
        supplier += `;${seg.carrier}`;
    });
    const travelStartDate = request.segments[0]?.flights[0]?.depDate;
    const travelValidEndDate = request.segments[request.segments.length - 1]?.flights[0]?.depDate;
    const info = initBaseInfoProduct(new Date(travelStartDate), new Date(travelValidEndDate), origins, request.product, destinations, supplier, 0);
    req.Info.push(info);
    return req;
}
function getNotificationsEvolviQuote(request) {
    const req = initRequestInfo();
    const into = initBaseInfoProduct(new Date(request.journeyStartDate), new Date(request.journeyEndDate), [], Products.Evolvi, [], '', 0);
    req.Info.push(into);
    return req;
}
function getNotificationsRailQuote(request) {
    const req = initRequestInfo();
    const into = initBaseInfoProduct(new Date(request.journeyStartDate), new Date(request.journeyEndDate), [], Products.Benerail, [], '', 0);
    req.Info.push(into);
    return req;
}
function getNotificationsGSQuote(request) {
    const req = initRequestInfo();
    let origin = getCityCode(request.pickupAddress.street);
    let destination = getCityCode(request.dropoffAddress.street);
    if (!origin || origin.length !== 3) {
        origin = request.pickupAddress.town;
    }
    if (!destination || destination.length !== 3) {
        destination = request.dropoffAddress.town;
    }
    req.Info[0] = initBaseInfoProduct(new Date(request.departureDate), new Date(request.departureDate), [origin], Products.GroundService, [destination], '', 0);
    return req;
}
async function getNotificationsHexSearch(request, tripId, corporationId) {
    const notificationRequest = initBaseRequest(tripId, corporationId);
    const info = initBaseInfoProduct(new Date(request.departureDate), new Date(request.departureDate), ['LON'], 'HeathrowExpress', ['LON'], '', 0);
    const passengersRequest = await getGDSPassengersNotificationRequest(tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
function extractRailStationCode(str) {
    const regex = /.*\((.*)\)/; // Matches everything before the last parentheses
    const match = str.match(regex);
    const code = match ? match[1] : null;
    return code;
}
function getCityCode(str) {
    if (!str || str === '') {
        return 'google';
    }
    if (str.length === 3) {
        return str;
    }
    if (str.length > 3) {
        const leftIndex = str.indexOf('(');
        const rightIndex = str.indexOf(')');
        if (leftIndex > -1 && rightIndex > -1) {
            str = str.substring(leftIndex + 1, leftIndex + 4);
            return str;
        }
    }
    return 'google';
}
async function getNotificationsCarSearch(opts, tripId, corporationId) {
    const notificationRequest = initBaseRequest(tripId, corporationId);
    let dest = '';
    let dept = '';
    if (opts.dropOffLocation.code && opts.dropOffLocation.code !== '') {
        dest = opts.dropOffLocation.code;
    }
    if (opts.pickUpLocation.code && opts.pickUpLocation.code !== '') {
        dept = opts.pickUpLocation.code;
    }
    const info = initBaseInfoProduct(new Date(opts.pickUpDate), new Date(opts.dropOffDate), [dept], Products.Car, [dest], '', 0);
    const getCoordinateString = (coordinate) => (coordinate !== null ? coordinate.toString() : '');
    info.Longitude = getCoordinateString(opts.pickUpLocation?.longitude);
    info.Latitude = getCoordinateString(opts.pickUpLocation?.latitude);
    info.LongitudeDropoff = getCoordinateString(opts.dropOffLocation?.longitude);
    info.LatitudeDropoff = getCoordinateString(opts.dropOffLocation?.latitude);
    const passengersRequest = await getGDSPassengersNotificationRequest(tripId);
    const calculationRequest = createNotificationRequest(passengersRequest.AccountProfile, passengersRequest.PaxProfile, info);
    notificationRequest.Requests.push(calculationRequest);
    const reminders = await httpService.post('/pushNotifications', notificationRequest);
    showNotificationReminders(reminders);
    return null;
}
async function getGDSPassengersNotificationRequest(tripId) {
    const passenger = await tripService.getPassengers(tripId);
    const { accountProfiles, paxProfiles } = passenger.reduce((acc, a) => {
        if (a.gdsCorpLocator) {
            acc.accountProfiles.push(a.gdsCorpLocator);
        }
        if (a.gdsProfileLocator) {
            acc.paxProfiles.push(a.gdsProfileLocator);
        }
        return acc;
    }, { accountProfiles: [], paxProfiles: [] });
    return { AccountProfile: accountProfiles, PaxProfile: paxProfiles };
}
