import { email, numeric, requiredIf } from '@vuelidate/validators';
import { utilService } from '@/services/util.service';
import { useVuelidate } from '@vuelidate/core';
import { defineComponent } from 'vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
export default defineComponent({
    name: 'ReferenceFormContactDetails',
    components: {
        formValidationMsg,
    },
    props: {
        passengers: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            markFields: false,
            isDoNotShareContactInfo: false,
            displayedCollapseTabs: ['contact-details'],
            formData: {
                contactEmail: '',
                contactPhone: {
                    countryCode: '',
                    areaCode: '',
                    number: '',
                },
            },
        };
    },
    setup() {
        return { v$: useVuelidate() };
    },
    async created() {
        await this.populateData();
    },
    methods: {
        async populateData() {
            const user = await this.$store.dispatch({ type: 'authStore/getLoggedInUser', ignoreCaching: true });
            this.isDoNotShareContactInfo = user.isRefusedToSharePersonalData; // is contact information required by default. user can change that. this is just for the first display
            this.formData.contactEmail = this.primaryPassenger?.email || ''; // user email
            // if the booker is the only traveler - take his phone else the primary pax mobile
            const phoneOrMobile = this.isBookerIsOnlyTraveler ? user.phone : this.primaryPassenger?.mobile;
            if (phoneOrMobile) {
                // contact phone
                const [countryCode, areaCode, number] = utilService.splitPhoneToParts(phoneOrMobile);
                this.formData.contactPhone.countryCode = countryCode;
                this.formData.contactPhone.areaCode = areaCode;
                this.formData.contactPhone.number = number;
            }
        },
    },
    computed: {
        primaryPassenger() {
            return this.passengers.find((passenger) => passenger.isPrimary);
        },
        isContactPhoneValidationMsg() {
            return (!this.isDoNotShareContactInfo &&
                ((this.v$.formData.contactPhone.$invalid && this.v$.formData.contactPhone.$anyDirty) ||
                    (this.markFields && this.v$.formData.contactPhone.$invalid)));
        },
        isBookerIsOnlyTraveler() {
            return this.passengers.filter((passenger) => passenger.corporateUserId === this.loggedinUser.id).length === 1;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    validations() {
        return {
            formData: {
                contactEmail: {
                    email,
                    required: requiredIf(() => {
                        return (!this.isDoNotShareContactInfo &&
                            !this.formData.contactPhone.countryCode &&
                            !this.formData.contactPhone.areaCode &&
                            !this.formData.contactPhone.number);
                    }),
                },
                contactPhone: {
                    countryCode: {
                        numeric,
                        required: requiredIf(() => {
                            return !this.isDoNotShareContactInfo && !this.formData.contactEmail;
                        }),
                    },
                    areaCode: {
                        numeric,
                        required: requiredIf(() => {
                            return !this.isDoNotShareContactInfo && !this.formData.contactEmail;
                        }),
                    },
                    number: {
                        numeric,
                        required: requiredIf(() => {
                            return !this.isDoNotShareContactInfo && !this.formData.contactEmail;
                        }),
                    },
                },
            },
        };
    },
});
