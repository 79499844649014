let _config = {
    apiUrl: process.env.VUE_APP_API_URL,
    apiUrlAnonymous: process.env.VUE_APP_API_URL_ANONYMOUS,
    appUrl: process.env.VUE_APP_BASE_URL,
    wsUrl: process.env.VUE_APP_WS_ENDPOINT,
    expenseUrl: process.env.VUE_APP_EXPENSE_ENDPOINT,
    atriisCareUrl: process.env.VUE_APP_ATRIIS_CARE_ENDPOINT,
    staySafeUrl: process.env.VUE_APP_STAY_SAFE_ENDPOINT,
    staySafeUrlMobileApp: process.env.VUE_APP_STAY_SAFE_ENDPOINT_MOBILE,
    env: {
        fb: process.env.VUE_APP_FB,
        isProd: process.env.VUE_APP_ENV_NAME === 'production' || process.env.VUE_APP_ENV_NAME === 'production2',
        isStaging: process.env.VUE_APP_ENV_NAME === 'staging',
        isDev: process.env.VUE_APP_ENV_NAME === 'development',
        name: process.env.VUE_APP_ENV_NAME,
    },
    cloudinary: {
        baseUrl: process.env.VUE_APP_CLOUDINARY_BASE_URL,
        uploadPreset: process.env.VUE_APP_CLOUDINARY_UPLOAD_PRESET,
        cloudName: process.env.VUE_APP_CLOUDINARY_CLOUD_NAME,
    },
    auth: {
        authority: process.env.VUE_APP_IDP_AUTHORITY_ENDPOINT,
        clientId: process.env.VUE_APP_IDP_AUTHORITY_CLIENT_ID,
        ApimSubscription: process.env.VUE_APP_APIM_SUBSCRIPTION_KEY,
        isSkipLogin: process.env.VUE_APP_API_SKIP_LOGIN === 'true',
        //VUE_APP_APIM_SUBSCRIPTION_KEY: process.env.AUTHORITY_CLIENT_ID,
    },
    mapSettings: {
        apiKey: process.env.VUE_APP_GOOGLE_API,
        mapId: process.env.VUE_APP_GOOGLE_MAP_ID,
    },
    miscellaneous: {
        isChristmasMode: process.env.VUE_APP_CHRISTMAS_MODE === 'true',
    },
    // features under development
    featureToggle: {},
};
//If nay FB, replace the values
if (process.env.VUE_APP_FB) {
    _config = _replaceInJson(_config, 'https://3.gtp-staging.com', `https://${process.env.VUE_APP_FB}.gtp-staging.com`);
    _config = _replaceInJson(_config, 'https://tmc2staging.azure-api.net', `https://${process.env.VUE_APP_FB}-be.gtp-staging.com`);
}
/**
 * Recursively replaces all occurrences of a substring (A) with another value (B)
 * in a JSON object, but only if the value is a string.
 *
 * @param {Object|Array} json - The input JSON object or array to process.
 * @param {string} a - The substring to be replaced in string values.
 * @param {string} b - The replacement string for A.
 * @returns {Object|Array} A new JSON object or array with all string values containing A replaced by B.
 *
 * @example
 * const inputJson = {
 *   name: "Alice blue",
 *   age: 25,
 *   preferences: {
 *     color: "blue sky",
 *     food: "pizza",
 *   },
 *   tags: ["blue", "green", "blueberry"],
 * };
 */
function _replaceInJson(json, a, b) {
    // Recursive function to traverse and replace values
    function traverseAndReplace(obj) {
        if (Array.isArray(obj)) {
            // Map over array elements and apply replacements
            return obj.map((item) => traverseAndReplace(item));
        }
        else if (obj !== null && typeof obj === 'object') {
            // Traverse each key-value pair in the object
            return Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, traverseAndReplace(value)]));
        }
        else if (typeof obj === 'string') {
            // Replace the substring if it contains a
            return obj.includes(a) ? obj.replace(new RegExp(a, 'g'), b) : obj;
        }
        else {
            // Return the value as is if it doesn't match conditions
            return obj;
        }
    }
    // Process the input JSON
    return traverseAndReplace(json);
}
export const config = _config;
