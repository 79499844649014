import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d2cbfad2"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "flight-filter is-flex is-flex-direction-column" };
const _hoisted_2 = {
    key: 0,
    class: "mobile-header is-flex is-justify-content-space-between"
};
const _hoisted_3 = { class: "filter-title" };
const _hoisted_4 = { class: "app-filter__content" };
const _hoisted_5 = {
    key: 0,
    class: "filter-container mb-3"
};
const _hoisted_6 = { class: "section-title mb-3" };
const _hoisted_7 = { class: "filter-container" };
const _hoisted_8 = {
    key: 0,
    class: "view-txt-link mb-2"
};
const _hoisted_9 = { class: "view-more-btn" };
const _hoisted_10 = { class: "material-symbols-outlined pt-1" };
const _hoisted_11 = { key: 0 };
const _hoisted_12 = { class: "filter-container" };
const _hoisted_13 = { class: "mb-4 is-size-6" };
const _hoisted_14 = { key: 0 };
const _hoisted_15 = { key: 1 };
const _hoisted_16 = { class: "mt-4" };
const _hoisted_17 = {
    key: 0,
    class: "view-txt-link mb-2"
};
const _hoisted_18 = { class: "view-more-btn" };
const _hoisted_19 = { class: "material-symbols-outlined pt-1" };
const _hoisted_20 = { class: "filter-container" };
const _hoisted_21 = { class: "mb-4 is-size-6" };
const _hoisted_22 = { class: "view-more-btn" };
const _hoisted_23 = { class: "material-symbols-outlined pt-1" };
const _hoisted_24 = ["title"];
const _hoisted_25 = { class: "filter-container" };
const _hoisted_26 = { class: "mb-4 is-size-6" };
const _hoisted_27 = { class: "apply-filter-btn-container" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_img = _resolveComponent("app-img");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_app_select = _resolveComponent("app-select");
    const _component_app_text_search = _resolveComponent("app-text-search");
    const _component_app_price_slider = _resolveComponent("app-price-slider");
    const _component_app_checkbox_group = _resolveComponent("app-checkbox-group");
    const _component_app_time_slider = _resolveComponent("app-time-slider");
    const _component_el_tab_pane = _resolveComponent("el-tab-pane");
    const _component_el_tabs = _resolveComponent("el-tabs");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        (_ctx.isMobile)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_app_btn, {
                    type: "text",
                    onClick: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('close')))
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_app_img, {
                            iconName: "close.svg",
                            isIcon: true
                        })
                    ]),
                    _: 1 /* STABLE */
                }),
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.$t('btn.sortAndFilters')), 1 /* TEXT */),
                _createVNode(_component_app_btn, {
                    type: "text",
                    onClick: _ctx.handleClear
                }, {
                    default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('btn.clear')), 1 /* TEXT */)
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["onClick"])
            ]))
            : _createCommentVNode("v-if", true),
        _createElementVNode("div", _hoisted_4, [
            (_ctx.isMobile)
                ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                    _createElementVNode("h4", _hoisted_6, _toDisplayString(_ctx.$t('filter.sortBy')), 1 /* TEXT */),
                    _createVNode(_component_app_select, {
                        defaultStyle: "",
                        class: "group-select",
                        options: _ctx.sortOptions,
                        modelValue: _ctx.filterBy.sortBy,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.filterBy.sortBy) = $event))
                    }, null, 8 /* PROPS */, ["options", "modelValue"])
                ]))
                : _createCommentVNode("v-if", true),
            _createCommentVNode(" search text "),
            _createElementVNode("div", _hoisted_7, [
                _createVNode(_component_app_text_search, {
                    modelValue: _ctx.filterBy.q,
                    "onUpdate:modelValue": [
                        _cache[2] || (_cache[2] = ($event) => ((_ctx.filterBy.q) = $event)),
                        _ctx.filter
                    ],
                    hasRemoveBtn: "",
                    placeholder: _ctx.$t('flight.searchFlight'),
                    iconPath: "search"
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "placeholder"])
            ]),
            _createCommentVNode(" price "),
            _createVNode(_component_app_price_slider, {
                modelValue: _ctx.filterBy.priceRange,
                "onUpdate:modelValue": [
                    _cache[3] || (_cache[3] = ($event) => ((_ctx.filterBy.priceRange) = $event)),
                    _ctx.filter
                ],
                min: _ctx.filterBy.priceMinMax[0],
                max: _ctx.filterBy.priceMinMax[1],
                userPreferences: _ctx.userPreferences
            }, null, 8 /* PROPS */, ["modelValue", "min", "max", "userPreferences", "onUpdate:modelValue"]),
            _createCommentVNode(" search checkboxFields "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.checkboxFields, (field) => {
                return (_openBlock(), _createElementBlock("div", {
                    key: field.key
                }, [
                    _createVNode(_component_app_checkbox_group, {
                        modelValue: _ctx.filterBy[field.key],
                        "onUpdate:modelValue": [($event) => ((_ctx.filterBy[field.key]) = $event), _ctx.filter],
                        class: _normalizeClass(`${field.key}-filter`),
                        options: field,
                        resultsCountMap: _ctx.resultMap[field.key],
                        hideIfNoresults: "",
                        isOneOptionEnabled: "",
                        isCheckForUpdatedOptions: ""
                    }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "class", "options", "resultsCountMap"]),
                    (field.key === 'stops')
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: _normalizeClass(["filter-container", { 'pb-0': _ctx.filterBy.stopDurationMinMax[1] === 0 }])
                        }, [
                            (_ctx.filterBy.stopDurationMinMax[1] > 0 && !(_ctx.filterBy.stops.includes('No stops') && _ctx.filterBy.stops.length === 1))
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                                    _createVNode(_component_app_btn, {
                                        type: "text",
                                        onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.isShowingLayoverDuration = !_ctx.isShowingLayoverDuration)),
                                        isLinkStyle: "",
                                        hasPadding: false
                                    }, {
                                        default: _withCtx(() => [
                                            _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t('filter.layoverDuration')), 1 /* TEXT */),
                                            _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.isShowingLayoverDuration ? 'expand_less' : 'expand_more'), 1 /* TEXT */)
                                        ]),
                                        _: 1 /* STABLE */
                                    }),
                                    (_ctx.isShowingLayoverDuration)
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                                            _createVNode(_component_app_time_slider, {
                                                modelValue: _ctx.filterBy.stopsDurationRange,
                                                "onUpdate:modelValue": [
                                                    _cache[5] || (_cache[5] = ($event) => ((_ctx.filterBy.stopsDurationRange) = $event)),
                                                    _ctx.filter
                                                ],
                                                min: _ctx.filterBy.stopDurationMinMax[0],
                                                max: _ctx.filterBy.stopDurationMinMax[1],
                                                isTimeStamp: false
                                            }, null, 8 /* PROPS */, ["modelValue", "min", "max", "onUpdate:modelValue"])
                                        ]))
                                        : _createCommentVNode("v-if", true)
                                ]))
                                : _createCommentVNode("v-if", true)
                        ], 2 /* CLASS */))
                        : _createCommentVNode("v-if", true)
                ]));
            }), 128 /* KEYED_FRAGMENT */)),
            _createCommentVNode(" search carriers "),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carriersField, (field) => {
                return (_openBlock(), _createBlock(_component_app_checkbox_group, {
                    class: "carriers-filter",
                    key: field.key,
                    modelValue: _ctx.filterBy[field.key],
                    "onUpdate:modelValue": [($event) => ((_ctx.filterBy[field.key]) = $event), _ctx.filter],
                    options: field,
                    resultsCountMap: _ctx.resultMap[field.key],
                    showSelectAll: "",
                    showClearAll: "",
                    isOneOptionEnabled: "",
                    hideIfNoresults: "",
                    isCheckForUpdatedOptions: ""
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "resultsCountMap"]));
            }), 128 /* KEYED_FRAGMENT */)),
            _createCommentVNode(" time "),
            _createElementVNode("div", _hoisted_12, [
                _createElementVNode("h4", _hoisted_13, _toDisplayString(_ctx.$t('filter.times')), 1 /* TEXT */),
                _createVNode(_component_el_tabs, {
                    modelValue: _ctx.currentTabIndex,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event) => ((_ctx.currentTabIndex) = $event)),
                    class: "time-range-filter-tabs",
                    stretch: ""
                }, {
                    default: _withCtx(() => [
                        _createVNode(_component_el_tab_pane, {
                            label: _ctx.$t('flight.departureTime'),
                            key: "0"
                        }, null, 8 /* PROPS */, ["label"]),
                        _createVNode(_component_el_tab_pane, {
                            label: _ctx.$t('flight.arrivalTime'),
                            key: "1"
                        }, null, 8 /* PROPS */, ["label"])
                    ]),
                    _: 1 /* STABLE */
                }, 8 /* PROPS */, ["modelValue"]),
                (_ctx.currentTabIndex === '0')
                    ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterBy.departTimeRange, (value, key) => {
                            return (_openBlock(), _createBlock(_component_app_time_slider, {
                                key: `depart-${key}`,
                                modelValue: _ctx.filterBy.departTimeRange[key],
                                "onUpdate:modelValue": [($event) => ((_ctx.filterBy.departTimeRange[key]) = $event), _ctx.filter],
                                min: _ctx.filterBy.departTimeMinMax[key][0],
                                max: _ctx.filterBy.departTimeMinMax[key][1],
                                title: `${_ctx.$t('flight.departFrom')} ${key}`
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "min", "max", "title"]));
                        }), 128 /* KEYED_FRAGMENT */))
                    ]))
                    : (_openBlock(), _createElementBlock("div", _hoisted_15, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterBy.arriveTimeRange, (value, key) => {
                            return (_openBlock(), _createBlock(_component_app_time_slider, {
                                key: `arrive-${key}`,
                                modelValue: _ctx.filterBy.arriveTimeRange[key],
                                "onUpdate:modelValue": [($event) => ((_ctx.filterBy.arriveTimeRange[key]) = $event), _ctx.filter],
                                min: _ctx.filterBy.arriveTimeMinMax[key][0],
                                max: _ctx.filterBy.arriveTimeMinMax[key][1],
                                title: `${_ctx.$t('flight.arriveTo')} ${key}`
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "min", "max", "title"]));
                        }), 128 /* KEYED_FRAGMENT */))
                    ])),
                _createElementVNode("div", _hoisted_16, [
                    (Object.keys(_ctx.filterBy.durationRange).length > _ctx.durationRangeDisplayCount)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                            _createVNode(_component_app_btn, {
                                type: "text",
                                onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.isShowingAllOptions = !_ctx.isShowingAllOptions)),
                                isLinkStyle: "",
                                hasPadding: false
                            }, {
                                default: _withCtx(() => [
                                    _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('flight.flightDuration')), 1 /* TEXT */),
                                    _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.isShowingAllOptions ? 'expand_less' : 'expand_more'), 1 /* TEXT */)
                                ]),
                                _: 1 /* STABLE */
                            })
                        ]))
                        : _createCommentVNode("v-if", true),
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterBy.durationRange, (value, key, index) => {
                        return (_openBlock(), _createElementBlock("div", {
                            key: `d${key}`
                        }, [
                            (_ctx.isShowingAllOptions || index < _ctx.durationRangeDisplayCount)
                                ? (_openBlock(), _createBlock(_component_app_time_slider, {
                                    key: 0,
                                    modelValue: _ctx.filterBy.durationRange[key],
                                    "onUpdate:modelValue": [($event) => ((_ctx.filterBy.durationRange[key]) = $event), _ctx.filter],
                                    min: _ctx.filterBy.durationMinMax[key][0],
                                    max: _ctx.filterBy.durationMinMax[key][1],
                                    title: `${_ctx.$t('flight.flightDuration')} ${_ctx.$t('common.from').toLowerCase()} ${key}`,
                                    isTimeStamp: false
                                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "min", "max", "title"]))
                                : _createCommentVNode("v-if", true)
                        ]));
                    }), 128 /* KEYED_FRAGMENT */))
                ])
            ]),
            _createCommentVNode(" One KEY will be London, one KEY will be Paris "),
            _createCommentVNode(" One VALUE will be array of airports in London, One VALUE will be array of airports in Paris "),
            _createElementVNode("div", _hoisted_20, [
                _createElementVNode("h4", _hoisted_21, _toDisplayString(_ctx.$t('filter.airports')), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterBy.availableAirports, (segmentAvailableAirports, index) => {
                    return (_openBlock(), _createElementBlock("div", { key: index }, [
                        (Object.keys(_ctx.filterBy.availableAirports).length > 1)
                            ? (_openBlock(), _createElementBlock("div", {
                                key: 0,
                                class: _normalizeClass(["view-txt-link", { 'mt-4': index > 0 }])
                            }, [
                                _createVNode(_component_app_btn, {
                                    type: "text",
                                    onClick: ($event) => (_ctx.isShowingAllAirports[index] = !_ctx.isShowingAllAirports[index]),
                                    isLinkStyle: "",
                                    hasPadding: false
                                }, {
                                    default: _withCtx(() => [
                                        _createElementVNode("p", _hoisted_22, _toDisplayString(`${Object.keys(segmentAvailableAirports)[0]} ${_ctx.$t('common.to').toLowerCase()}
                ${Object.keys(segmentAvailableAirports)[1]}`), 1 /* TEXT */),
                                        _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.isShowingAllAirports[index] ? 'expand_less' : 'expand_more'), 1 /* TEXT */)
                                    ]),
                                    _: 2 /* DYNAMIC */
                                }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["onClick"])
                            ], 2 /* CLASS */))
                            : _createCommentVNode("v-if", true),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(segmentAvailableAirports, (value, key) => {
                            return (_openBlock(), _createElementBlock("div", { key: key }, [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.airportsField(index, key), (field) => {
                                    return (_openBlock(), _createElementBlock("div", {
                                        key: `${index}-${field.key}`
                                    }, [
                                        (_ctx.isShowingAllAirports?.[index] && _ctx.filterBy.airports?.[index])
                                            ? (_openBlock(), _createBlock(_component_app_checkbox_group, {
                                                class: "airports-filter",
                                                key: _ctx.resultsIndex,
                                                modelValue: _ctx.filterBy[field.key][index][key],
                                                "onUpdate:modelValue": [($event) => ((_ctx.filterBy[field.key][index][key]) = $event), _ctx.filter],
                                                resultsCountMap: _ctx.resultMap[field.key][index],
                                                options: field,
                                                isOneOptionEnabled: "",
                                                title: `${key} ${_ctx.$t('filter.airports')}`
                                            }, {
                                                content: _withCtx(({ val, key }) => [
                                                    _createElementVNode("p", {
                                                        class: "ellipsis airports-content",
                                                        title: _ctx.getAirportsName(key, val)
                                                    }, _toDisplayString(_ctx.getAirportsName(key, val)), 9 /* TEXT, PROPS */, _hoisted_24)
                                                ]),
                                                _: 2 /* DYNAMIC */
                                            }, 1032 /* PROPS, DYNAMIC_SLOTS */, ["modelValue", "onUpdate:modelValue", "resultsCountMap", "options", "title"]))
                                            : _createCommentVNode("v-if", true)
                                    ]));
                                }), 128 /* KEYED_FRAGMENT */))
                            ]));
                        }), 128 /* KEYED_FRAGMENT */))
                    ]));
                }), 128 /* KEYED_FRAGMENT */))
            ]),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.agreementTypeField, (field) => {
                return (_openBlock(), _createBlock(_component_app_checkbox_group, {
                    class: "agreement-type-filter",
                    key: field.key,
                    modelValue: _ctx.filterBy[field.key],
                    "onUpdate:modelValue": [($event) => ((_ctx.filterBy[field.key]) = $event), _ctx.filter],
                    options: field,
                    resultsCountMap: _ctx.resultMap[field.key],
                    isOneOptionEnabled: "",
                    isCheckForUpdatedOptions: ""
                }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "resultsCountMap"]));
            }), 128 /* KEYED_FRAGMENT */)),
            _createCommentVNode(" One KEY will be London, one KEY will be Paris "),
            _createCommentVNode(" One VALUE will be array of advisories in London, One VALUE will be array of advisories in Paris "),
            _createElementVNode("div", _hoisted_25, [
                _createElementVNode("h4", _hoisted_26, _toDisplayString(_ctx.$t('filter.advisory')), 1 /* TEXT */),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filterBy.availableAdvisories, (value, key) => {
                    return (_openBlock(), _createElementBlock("div", { key: key }, [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.advisoriesField(key), (field) => {
                            return (_openBlock(), _createBlock(_component_app_checkbox_group, {
                                class: "advisories-filter",
                                key: field.key,
                                modelValue: _ctx.filterBy[field.key][key],
                                "onUpdate:modelValue": [($event) => ((_ctx.filterBy[field.key][key]) = $event), _ctx.filter],
                                options: field,
                                resultsCountMap: _ctx.resultMap[field.key],
                                isOneOptionEnabled: "",
                                title: `${_ctx.$t('common.from')} ${key}`
                            }, null, 8 /* PROPS */, ["modelValue", "onUpdate:modelValue", "options", "resultsCountMap", "title"]));
                        }), 128 /* KEYED_FRAGMENT */))
                    ]));
                }), 128 /* KEYED_FRAGMENT */))
            ])
        ]),
        _createElementVNode("div", _hoisted_27, [
            _createVNode(_component_app_btn, {
                type: "primary",
                "is-fit-width": "",
                class: "apply-filter-btn",
                onClick: _ctx.handleApplyFilter
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('common.apply')), 1 /* TEXT */)
                ]),
                _: 1 /* STABLE */
            }, 8 /* PROPS */, ["onClick"])
        ])
    ]));
}
