<template>
  <section class="reference-form-contact-details">
    <el-collapse class="mt-5" v-model="displayedCollapseTabs">
      <el-collapse-item name="contact-details" class="contact-details-collapse">
        <template #title>
          <div
            class="contact-details-title-container is-flex-wrap-wrap is-flex is-justify-content-space-between is-align-items-center">
            <div class="is-flex is-align-items-center">
              <div class="product-icon-wrapper is-flex is-justify-content-center is-align-items-center">
                <span class="material-symbols-outlined is-size-5 has-text-white">call</span>
              </div>
              <div>
                <div class="is-size-6 ml-2 has-text-weight-semibold is-capitalized has-text-left">
                  {{ $t('flight.extras.contactDetails') }}
                </div>
              </div>
            </div>
          </div>
        </template>
        <el-card class="box-card">
          <div class="label user-msg mb-3">
            {{ $t('trip.contactDetails.userContactInfoUsageMsg') }}
          </div>

          <div class="app-columns">
            <!-- Traveller Phone Number -->
            <div class="field app-column is-6 is-flex is-flex-direction-column" :class="{
              ' is-justify-content-space-between': !isMobile,
            }">
              <label class="label"> {{ $t('trip.approvals.travelerPhone') }} </label>
              <div>
                <div class="is-flex is-align-items-center">
                  <span class="mr-1">+</span>
                  <el-input class=" is-flex-grow-1  mr-1" :class="{
                    'is-danger':
                      !isDoNotShareContactInfo &&
                      ((v$.formData.contactPhone.countryCode.$invalid && v$.formData.contactPhone.countryCode.$dirty) ||
                        (markFields && v$.formData.contactPhone.countryCode.$invalid)),
                  }" v-model="formData.contactPhone.countryCode" placeholder="000"
                    :disabled="isDoNotShareContactInfo" />

                  <el-input class="is-flex-grow-1  mr-1" :class="{
                    'is-danger':
                      !isDoNotShareContactInfo &&
                      ((v$.formData.contactPhone.areaCode.$invalid && v$.formData.contactPhone.areaCode.$dirty) ||
                        (markFields && v$.formData.contactPhone.areaCode.$invalid)),
                  }" v-model="formData.contactPhone.areaCode" :placeholder="$t('common.enter')"
                    :disabled="isDoNotShareContactInfo" />

                  <el-input class="is-flex-grow-1" :class="{
                    'is-danger':
                      !isDoNotShareContactInfo &&
                      ((v$.formData.contactPhone.number.$invalid && v$.formData.contactPhone.number.$dirty) ||
                        (markFields && v$.formData.contactPhone.number.$invalid)),
                  }" v-model="formData.contactPhone.number" :placeholder="$t('common.enter')"
                    :disabled="isDoNotShareContactInfo" />
                </div>

                <form-validation-msg v-if="isContactPhoneValidationMsg" type="error">
                  <p v-if="
                    !v$.formData.contactPhone.countryCode.required ||
                    !v$.formData.contactPhone.areaCode.required ||
                    !v$.formData.contactPhone.number.required
                  " class="help">
                    {{ $t('validation.required') }}
                  </p>
                  <p v-else class="help">{{ $t('validation.invalidPhone') }}</p>
                </form-validation-msg>
              </div>
            </div>

            <!-- Traveller Email -->

            <div class="field app-column is-6 is-flex  is-flex-direction-column">
              <label class="label">{{ $t('trip.approvals.travelerEmail') }}</label>
              <div>
                <el-input class="email-input" :class="{
                  'is-danger':
                    !isDoNotShareContactInfo &&
                    ((v$.formData.contactEmail.$invalid && v$.formData.contactEmail.$dirty) ||
                      (markFields && v$.formData.contactEmail.$invalid)),
                }" v-model="v$.formData.contactEmail.$model" :placeholder="$t('common.enter')"
                  :disabled="isDoNotShareContactInfo" />

                <form-validation-msg v-if="
                  !isDoNotShareContactInfo &&
                  ((v$.formData.contactEmail.$invalid && v$.formData.contactEmail.$dirty) ||
                    (markFields && v$.formData.contactEmail.$invalid))
                " type="error">
                  <p v-if="!v$.formData.contactEmail.required" class="help">{{ $t('validation.required') }}</p>
                  <p v-else class="help">{{ $t('validation.invalidEmail') }}</p>
                </form-validation-msg>
              </div>
            </div>
          </div>



          <div class="share-checkbox-container mr-2">
            <el-checkbox v-model="isDoNotShareContactInfo" @click="(event) => event.stopPropagation()">
              <span class="app-text-wrap-wrap"> {{ $t('trip.contactDetails.shareContactWithAirlinesMsg') }}</span>
            </el-checkbox>
          </div>
        </el-card>
      </el-collapse-item>
    </el-collapse>
  </section>
</template>

<script src="./reference-form-contact-details.ts"></script>
<style lang="scss" scoped src="./reference-form-contact-details.scss"></style>
