import { tripService } from '@/services/trip.service';
import { flightService } from '@/modules/products/flight/services/flight.service';
import { utilService } from '@/services/util.service';
import { loggerService } from '@/services/logger.service';
import { RoutesNames } from '@/router';
import { Products, ProductStatusName, TripValidationsNames, TripValidationStatusType, TripState, ShareTypes, QuoteStatus, NotificationTypes, BookingStage, AgentSuggestedProducts, ManualQuoteType, ActionTypes, } from '@/types/consts';
import { defineAsyncComponent, defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { compareAsc } from 'date-fns';
import { alertService } from '@/services/alert.service';
import { hotelService } from '@/modules/products/hotels/services/hotel.service';
import { SuppliersId } from '@/modules/products/flight/models/consts';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
import generalQuoteCard from '@/components/trip/quote-cards/general-quote-card/general-quote-card.vue';
import suggestedQuoteCard from '@/components/trip/quote-cards/suggested-quote-card/suggested-quote-card.vue';
import moreDetails from '@/components/trip/quote-more-details/hotel-quote-more-details.vue';
import generalMoreDetails from '@/components/trip/quote-more-details/general-quote-more-details.vue';
import creditCardDetailsCard from '@/components/trip/payments/credit-card-details/credit-card-details-card.vue';
import tripSummary from '@/components/trip/trip-summary/trip-summary.vue';
import appSelect from '@/components/app-select.vue';
import appBreadcrumb from '@/components/app-breadcrumb.vue';
import tripBar from '@/components/trip/trip-bar/trip-bar.vue';
import appShareQuotes from '@/components/app-share-quotes/app-share-quotes.vue';
import productCarousel from '@/components/trip/product-carousel/product-carousel.vue';
import cartSkeleton from '@/components/skeletons/cart-skeleton.vue';
import cancelQuoteModal from '@/components/trip/cancel-quote-modal.vue';
import cancelTripModal from '@/components/trip/cancel-trip-modal.vue';
import crossSellSuggestions from '@/components/trip/cross-sell-suggestions/cross-sell-suggestions.vue';
import copyTripModal from '@/components/trip/copy-trip-modal.vue';
import cartInfoBar from '@/components/trip/cart-info-bar/cart-info-bar.vue';
import selectCreditCard from '@/components/trip/payments/select-credit-card/select-credit-card.vue';
import tripTravelersList from '@/components/trip/trip-travelers-list/trip-travelers-list.vue';
import financialReference from '@/components/trip/financial-reference/financial-reference.vue';
import fusion3ds from '@/components/trip/payments/fusion-3ds/fusion-3ds.vue';
import appNotification from '@/components/app-notification/app-notification.vue';
import appReminderPopup from '@/components/trip/app-reminder-popup/app-reminder-popup.vue';
import staySafeQuoteCard from '@/components/trip/quote-cards/stay-safe-quote-card/stay-safe-quote-card.vue';
import compensationQuoteCard from '@/components/trip/quote-cards/compensation-quote-card/compensation-quote-card.vue';
import tripRelatedFiles from '@/views/trip/trip-related-files/trip-related-files.vue';
import checkPrice from '@/components/trip/check-price/check-price.vue';
import tripBookingResponseErrors from '@/components/trip/trip-booking-response-errors/trip-booking-response-errors.vue';
import bookingResponsePreview from '@/components/trip/booking-response-preview/booking-response-preview.vue';
// const tripBookingResponseErrors = defineAsyncComponent(
//   () =>
//     import(
//       /* webpackChunkName: 'trip-booking-response-errors' */ '@/components/trip/trip-booking-response-errors/trip-booking-response-errors.vue'
//     ),
// );
const tripApprovalSteps = defineAsyncComponent(() => import(/* webpackChunkName: 'approvals' */ '@/components/trip/approval-steps/trip-approval-steps.vue'));
const approvalsBar = defineAsyncComponent(() => import(/* webpackChunkName: 'approvals' */ '@/components/trip/approvals-bar/approvals-bar.vue'));
export default defineComponent({
    name: 'TripCart',
    components: {
        moreDetails,
        tripSummary,
        suggestedQuoteCard,
        appBreadcrumb,
        tripBar,
        cartInfoBar,
        productCarousel,
        cartSkeleton,
        generalQuoteCard,
        generalMoreDetails,
        creditCardDetailsCard,
        appShareQuotes,
        selectCreditCard,
        cancelQuoteModal,
        cancelTripModal,
        copyTripModal,
        appSelect,
        tripApprovalSteps: tripApprovalSteps,
        approvalsBar: approvalsBar,
        financialReference,
        appNotification,
        appReminderPopup,
        fusion3ds,
        staySafeQuoteCard,
        crossSellSuggestions,
        tripRelatedFiles,
        tripTravelersList,
        compensationQuoteCard,
        checkPrice,
        tripBookingResponseErrors,
        bookingResponsePreview,
    },
    data() {
        return {
            isMoreDetailsDrawerOpen: false,
            isCreditCardDetailsDrawerOpen: false,
            isSelectCreditCardDrawerOpen: false,
            selectedQuote: {},
            isLoading: false,
            specialRequests: [],
            isBtnLoading: false,
            termsCheckedMap: {},
            isExtrasValid: false,
            showNoFormOfPaymentModal: false,
            hasValidFormOfPayment: true,
            shareType: null,
            isCancelledList: false,
            showDetailedCancelQuote: false,
            quoteToCancel: {},
            loadingCancellation: false,
            isCancellationSuccess: false,
            showModalMsg: false,
            markDisabledBtn: false,
            showCancelTripModal: false,
            showCopyTripModal: false,
            hasValidCvv: false,
            cvvNumbers: [],
            showFusion3dsModal: false,
            showBasicCancelQuote: false,
            isCancellationInProcess: false,
            quoteByIdxLoaders: [],
            displayedCollapseTabs: ['preTripItems', 'requestAssistance'],
            activeTab: 'overview',
            quotesMap: {},
            showWarningModal: false,
            crossSellSuggestions: [],
            // Approvals Flow Data
            isApprovalsDialogOpen: false,
            isModifyApprovalMode: false,
            tempApprovalsFlowStepClone: [],
            isCurrentTripApprover: false,
            isStepApproved: false,
            isStepDeclined: false,
            isDeclineDialogOpen: false,
            declineReason: null,
            preBookingRes: {},
            notifications: {},
            showNotifications: false,
            expiredQuotes: [],
            errorQuote: null,
            quoteMapError: {},
            showHighlightCheckoutButton: false,
            showCheckPriceModal: false,
            isCheckPriceLoading: false,
            checkedPriceQuote: {},
            bookingResponses: [],
        };
    },
    async created() {
        this.setPageTitle();
        await this.loadData();
        if (!this.quotesToShow.length && (this.preTripItems.length || this.requestAssistanceQuotes.length)) {
            this.activeTab = 'requests';
        }
        //when stay safe is in active mode we will trigger an auto select stay safe product
        if (this?.staySafeDetails?.showAutomatedActiveStaySafe && !this.isStaySafeChosen) {
            this.$refs?.staySafeQuoteCardCmp?.addToTrip();
        }
        //when compensation is in active mode we will trigger an auto select compensation product
        if (this?.compensationDetails?.IsMandatory && !this.isCompensationChosen) {
            this.$refs?.compensationQuoteCardCmp?.addToTrip();
        }
        eventBus.$on('showCreditCardDetails', this.showCreditCardDetails);
        eventBus.$on('showSelectCreditCard', this.showSelectCreditCard);
        eventBus.$on('validateRequiredInfo', this.validateRequiredInfo);
        eventBus.$on('addCvvNumbers', this.addCvvNumbers);
        eventBus.$on('reloadQuotes', this.loadQuotes);
        eventBus.$on('loadQuotesAndValidations', this.loadQuotesAndValidations);
        eventBus.$on('timedQuoteExpired', this.onTimedQuoteExpiry);
        eventBus.$on('highlightCheckoutButton', this.highlightCheckoutButton);
        eventBus.$on('scrollToErrorField', this.scrollToErrorField);
        eventBus.$on('cancelExpiredQuote', this.cancelExpiredQuote);
        eventBus.$on('openQuoteErrorModal', this.openQuoteErrorModal);
        eventBus.$on('closeCheckPriceModal', this.closeCheckPriceModal);
    },
    methods: {
        toggleNotificationsVisible() {
            this.$refs.cartInfoBar.toggleIconColor();
            this.showNotifications = !this.showNotifications;
        },
        setPageTitle() {
            utilService.setPageTitle(`Trip ${this.trip.id}`);
        },
        /* Load */
        async loadData() {
            this.isLoading = true;
            await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.trip.id });
            try {
                //Load reference fields
                //we are loading them if they are still weren't loaded
                const tripId = this.trip.id;
                //load ref fields if they still not loaded
                const stateToCheck = [BookingStage.NewTrip, BookingStage.Checkout];
                const stateToLoad = [];
                stateToCheck.forEach((state) => {
                    const questions = this.getRefQuestions(state);
                    if (questions === null || questions === undefined || questions.length < 1) {
                        stateToLoad.push(state);
                    }
                });
                this.loadTripValidations();
                //load in parallel
                await Promise.all([
                    this.loadQuotes(),
                    stateToLoad.map(async (stage) => {
                        this.$store.dispatch({ type: 'tripStore/loadRefFields', tripId, stage });
                    }),
                    this.validateExtras(),
                    this.loadApprovalFlow(), //TODO: consider to load  in async
                ]);
                //Async - Must be after load quotes -
                this.loadNotifications();
                if (this.isPreTripApproval || this.isPreTripRequest) {
                    await this.loadPreTripQuotes();
                }
                if (this.isAssistanceRequested) {
                    await this.loadAssistanceQuotes();
                }
                this.markDisabledBtn = !this.hasRelevantQuotesToChecks;
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                if (this.isTripCancelled) {
                    this.isCancelledList = true; //show Cancelled quotes
                }
                this.isLoading = false;
            }
        },
        async createNewTrip() {
            const isBooker = this.$store.getters['authStore/isBooker'];
            try {
                await tripService.createNewTrip(this.$router, isBooker);
            }
            catch (err) {
                this.$alert.error('alert.general.error', err);
            }
        },
        async loadNotifications() {
            const offSetHours = new Date().getTimezoneOffset() / -60;
            this.notifications = await tripService.getNotifications(this.trip.id, offSetHours);
        },
        getRefQuestions(stage) {
            return this.$store.getters['tripStore/refFieldsMap'][stage];
        },
        sortQuotes(quotesArray) {
            const sorted = utilService.deepClone(quotesArray).sort((a, b) => {
                const hasManualSourceA = a?.sourceSystem?.includes('manual') || a?.isManual;
                const hasManualSourceB = b?.sourceSystem?.includes('manual') || b?.isManual;
                const isExpiredA = a.status === QuoteStatus.Expired;
                const isExpiredB = b.status === QuoteStatus.Expired;
                return (
                // prioritize items with product === Products.Flight
                (b.product === Products.Flight ? 1 : 0) - (a.product === Products.Flight ? 1 : 0) ||
                    // then show items that arent manual
                    hasManualSourceB - hasManualSourceA ||
                    // if 'manual' presence is the same (exists or doesnt exist), move staySafe and compensation to the bottom
                    (a.sourceSystem === Products.StaySafe ? 1 : 0) - (b.sourceSystem === Products.StaySafe ? 1 : 0) ||
                    (a.product === Products.Compensation ? 1 : 0) - (b.product === Products.Compensation ? 1 : 0) ||
                    // if 'StaySafe' is the same (exists or doesnt exist), manual quotes of type other (has quote.productType) go before staySafe
                    (a?.productType ? 1 : 0) - (b?.productType ? 1 : 0) ||
                    // move expired quotes to the end
                    isExpiredA - isExpiredB);
            });
            return sorted;
        },
        async loadApprovalFlow() {
            this.preBookingRes = await tripService.getIsPreBookingNeeded(this.trip.id);
            // load approval steps only if no pre booking needed & we are after
            // Review & Select or Booking details phase (not in trip state of "search request").
            if (!this.preBookingRes.isPreBookingNeeded &&
                !this.preBookingRes.isPreBookingSent &&
                !this.isSearchRequest &&
                !this.isAssistanceRequested) {
                await this.loadApprovalFlowSteps();
                if (this.isTripInApprovals || this.approvalFlowSteps) {
                    await this.loadIsCurrentTripApprover();
                    if (!this.approvalModifyUsers?.length) {
                        await this.$store.dispatch('tripStore/getApprovalModifyUsers', { tripId: this.trip.id });
                    }
                    this.tempApprovalsFlowStepClone = utilService.deepClone(this.approvalFlowSteps);
                }
            }
        },
        setTravelersCount(count) {
            eventBus.$emit('changeTravelersCount', count);
        },
        async loadQuotes() {
            try {
                await Promise.all([
                    this.$store.dispatch({ type: 'tripStore/loadQuotes' }),
                    this.$store.dispatch({ type: 'authStore/getLoggedInUser', ignoreCaching: true }),
                ]);
                this.expiredQuotes = this.quotes.filter((quote) => quote.status === QuoteStatus.Expired).map((q) => q.quoteId);
                // Have to get suggestion only after quotes are loaded - to know what products we need
                // we dont need  whait form cross sell
                this.getCrossSellSuggestions();
                this.validateRequiredInfo();
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.showCheckPriceModal = false;
            }
        },
        loadPreTripQuotes() {
            return this.$store.dispatch({ type: 'tripStore/loadPreTripQuotes' });
        },
        loadAssistanceQuotes() {
            return this.$store.dispatch({ type: 'tripStore/loadAssistanceQuotes' });
        },
        async loadTripValidations() {
            await this.$store.dispatch({
                type: 'tripStore/loadTripValidations',
                tripId: this.trip.id,
            });
        },
        /* Share Modal */
        setShareModal(type) {
            this.shareType = type;
            // add required data collection here
        },
        closeFusion3dsModal() {
            this.showFusion3dsModal = false;
        },
        openFusion3dsModal() {
            this.showFusion3dsModal = true;
        },
        /* cancel Quote */
        openCancelQuoteDialog(quote) {
            this.quoteToCancel = quote;
            if (!quote.isCorporateUser) {
                this.showBasicCancelQuote = true;
            }
            else if (this.isQuoteCancelable(quote) && !quote.requestAssistanceGuid) {
                this.showDetailedCancelQuote = true;
            }
            else {
                this.showBasicCancelQuote = true;
            }
        },
        cancelExpiredQuote(quote) {
            this.quoteToCancel = quote;
            this.onCancelQuoteConfirm(false);
        },
        openQuoteErrorModal(quote) {
            this.errorQuote = quote;
            this.quoteMapError = {};
            const bookingRes = { errorMsg: quote.errorMessage, quoteId: quote.quoteId };
            this.quoteMapError.failedItems = [{ quote: this.errorQuote, res: bookingRes }];
            this.quoteMapError.successItems = [];
        },
        /* eslint-disable complexity */
        isQuoteCancelable(quote) {
            // map the situations where quote cancelling is possible one by one
            let isQuoteCancelable = false;
            if (this.isQuotePending(quote)) {
                isQuoteCancelable = true;
            }
            else if (this.isPreTripApproval) {
                isQuoteCancelable = true;
            }
            else if (this.isAssistanceRequested) {
                isQuoteCancelable = true;
            }
            else if (!quote.isCorporateUser && !this.isTripFinalized) {
                isQuoteCancelable = true;
            }
            else if (
            // allow confirmed and ticketed products - except Heathrow and flight
            this.isQuoteTicketed(quote) &&
                !(quote.product === Products.HeathrowExpress ||
                    (quote.product === Products.Flight &&
                        quote.productStatusName === ProductStatusName.TicketingComplete &&
                        (quote.sourceSystem === SuppliersId[SuppliersId.YPSILON] ||
                            quote.sourceSystem === SuppliersId[SuppliersId.FUSION] ||
                            (quote.sourceSystem === SuppliersId[SuppliersId.UAPIGALILEO] && quote.isLowCost) ||
                            (quote.sourceSystem === SuppliersId[SuppliersId.AMADEUS] && quote.isLowCost) ||
                            (quote.sourceSystem === SuppliersId[SuppliersId.SABRE] && quote.isLowCost))) ||
                    (quote.product === Products.Flight &&
                        quote.productStatusName === ProductStatusName.TicketingComplete &&
                        !this.$store.getters['authStore/loggedinUser'].permissions?.includes('CanCancelTicketedFlightQuotes')))) {
                isQuoteCancelable = true;
            }
            else if (quote.product === Products.Flight && !this.isQuoteInProgress(quote) && !this.isQuoteTicketed(quote)) {
                // allow flight delete if not in progress or ticketed
                isQuoteCancelable = true;
            }
            return isQuoteCancelable;
        },
        async loadQuotesAndValidations() {
            try {
                await this.loadQuotes();
                await this.loadTripValidations();
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async onCancelQuoteConfirm(isInteractWithUser = true) {
            if (!this.quoteToCancel.isCorporateUser &&
                !this.isQuotePending(this.quoteToCancel) &&
                !this.quoteToCancel.requestAssistanceGuid) {
                this.showBasicCancelQuote = false;
                this.showDetailedCancelQuote = true;
                return;
            }
            try {
                this.isCancellationInProcess = true;
                if (this.isPreTripApproval) {
                    await this.cancelPreTripQuote(this.quoteToCancel);
                }
                else if (this.quoteToCancel.requestAssistanceGuid) {
                    await this.cancelAssistanceQuote(this.quoteToCancel);
                }
                else {
                    await this.cancelQuote(this.quoteToCancel);
                }
                if (isInteractWithUser) {
                    alertService.success(this.$t('trip.deleteConfirmMsgBox.SuccessMsg') + '');
                }
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.closeCancelQuoteModal();
                this.isCancellationInProcess = false;
            }
        },
        closeCancelQuoteModal() {
            this.showDetailedCancelQuote = false;
            this.quoteToCancel = {};
            this.isCancellationSuccess = false;
            this.loadingCancellation = false;
            this.showModalMsg = false;
            this.showBasicCancelQuote = false;
        },
        async cancelQuote(quote, selectedRefund) {
            if (quote.quoteId) {
                this.loadingCancellation = true;
                try {
                    const res = await this.$store.dispatch({
                        type: 'tripStore/cancelQuote',
                        quoteId: quote.quoteId,
                        selectedRefund,
                        product: quote.product,
                    });
                    this.isCancellationSuccess = !res?.isSuccess ? false : true;
                    await this.loadQuotes();
                    await this.loadTripValidations();
                }
                catch (error) {
                    this.isCancellationSuccess = false;
                }
                finally {
                    this.loadingCancellation = false;
                    this.showModalMsg = true;
                }
            }
        },
        async cancelPreTripQuote(quote) {
            if (quote.preTripId) {
                try {
                    if (this.showBasicCancelQuote) {
                        this.loadingCancellation = true;
                    }
                    await this.$store.dispatch({
                        type: 'tripStore/removePreTripItem',
                        tripId: this.trip.id,
                        preTripItemId: quote.preTripId,
                    });
                    await this.loadPreTripQuotes();
                    if (this.showDetailedCancelQuote) {
                        this.isCancellationSuccess = true;
                    }
                }
                catch (error) {
                    this.isCancellationSuccess = false;
                }
                finally {
                    this.loadingCancellation = false;
                    this.showModalMsg = true;
                }
            }
        },
        async cancelAssistanceQuote(quote) {
            try {
                if (this.showBasicCancelQuote) {
                    this.loadingCancellation = true;
                }
                await this.$store.dispatch({
                    type: 'tripStore/removeAssistanceItem',
                    tripId: this.trip.id,
                    productType: quote.productName,
                    requestAssistanceGuid: quote.requestAssistanceGuid,
                });
                await this.loadAssistanceQuotes();
                if (this.showDetailedCancelQuote) {
                    this.isCancellationSuccess = true;
                }
            }
            catch (error) {
                this.isCancellationSuccess = false;
            }
            finally {
                this.loadingCancellation = false;
                this.showModalMsg = true;
            }
        },
        goToRequestAssistance() {
            this.$router.push({ name: RoutesNames.requestAssistance, query: { goBackToPage: 'tripCart' } });
        },
        onModifyAssistance() {
            this.$router.push({ name: RoutesNames.requestAssistance, query: { isModifyMode: true } });
        },
        closeCancelTripModal() {
            this.showCancelTripModal = false;
        },
        closeCopyTripModal() {
            this.showCopyTripModal = false;
        },
        /* Approvals flow steps methods */
        loadApprovalFlowSteps() {
            return this.$store.dispatch({ type: 'tripStore/loadApprovalFlowSteps' });
        },
        async loadIsCurrentTripApprover() {
            const res = await tripService.getIsCurrentTripApprover(this.trip.id);
            this.isCurrentTripApprover = res.isCurrentApprover;
        },
        openApprovalsDialog() {
            this.isApprovalsDialogOpen = true;
        },
        /* Approvals flow steps methods - End */
        /* Validations */
        async validationChanged(event) {
            await this.loadTripValidations();
            await this.validateExtras();
            this.validateRequiredInfo();
        },
        termsChecked(para) {
            const copyTermsCheckedMap = { ...this.termsCheckedMap };
            copyTermsCheckedMap[para.quote.quoteId] = para.isChecked;
            this.termsCheckedMap = copyTermsCheckedMap;
            this.validateRequiredInfo();
        },
        async validateRequiredInfo(markValidations = false, loadValidations = false, fromCheckoutButton = false) {
            // called also from eventBus
            // markValidations will determine whether all the quote validations will be marked as well as the checkout btn disable.
            // currently happens only when clicking the checkout btn
            let isValid = true;
            // load validations after saving data related to trip validations
            if (loadValidations) {
                await this.loadTripValidations();
            }
            if (!this.hasRelevantQuotesToChecks) {
                isValid = false;
            }
            if (!(await this.isFormOfPaymentValid())) {
                if (markValidations) {
                    eventBus.$emit('markFormOfPaymentNotValid');
                }
                isValid = false;
            }
            if (this.hasEmptyTravelers) {
                if (markValidations) {
                    eventBus.$emit('markEmptyTravelers');
                }
                isValid = false;
            }
            if (markValidations) {
                eventBus.$emit('checkoutActivated'); //in addition it will trigger the extras validations
            }
            await this.validateExtras(fromCheckoutButton); // set: this.isExtrasValid
            if (!this.isAllTermsChecked) {
                isValid = false;
            }
            if (!this.isExtrasValid) {
                isValid = false;
            }
            if (this.tripHasOopViolations && !this.isOopFilled) {
                if (markValidations) {
                    eventBus.$emit('markOOPInvalid', true);
                }
                isValid = false;
            }
            if (!this.validateCvvInputs) {
                isValid = false;
            }
            if (this.hasUnreadMandatoryNotifications) {
                isValid = false;
            }
            if (markValidations) {
                this.markDisabledBtn = !isValid;
            }
            // if the cart is invalid - find the first element with error class and scroll to it
            if (!isValid && markValidations) {
                this.scrollToErrorField();
            }
            // return the btn to clickable when the validations are valid
            if (isValid) {
                this.markDisabledBtn = false;
            }
            return isValid;
        },
        scrollToErrorField() {
            this.$nextTick(() => {
                const errorEl = document.querySelector('.error-border') || document.querySelector('.is-danger');
                if (errorEl) {
                    errorEl.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }
            });
        },
        async validateExtras(fromCheckoutButton) {
            // validation names to check. avoid others...
            const isAnyPendingsWithFOPErrors = this.relevantQuotesToCheck.some((q) => {
                const quoteValidation = this.tripValidations.find((v) => v.QuoteId === q.quoteId);
                if (quoteValidation) {
                    //if found any FormOfPayment that is error
                    const extrasValidationsError = quoteValidation.Validations.some((x) => x.status === TripValidationStatusType.Error);
                    if (extrasValidationsError && fromCheckoutButton) {
                        eventBus.$emit('extrasValidationsError');
                    }
                    return extrasValidationsError;
                }
                return false;
            });
            this.isExtrasValid = !isAnyPendingsWithFOPErrors;
        },
        onTimedQuoteExpiry(quote) {
            const relevantQuote = utilService.deepClone(this.quotesToShow.find((q) => q.quoteId === quote.quoteId));
            const releventQuoteIndex = this.quotesToShow.findIndex((q) => q.quoteId === quote.quoteId);
            relevantQuote.status = QuoteStatus.Expired;
            relevantQuote.productStatusName = ProductStatusName.Expired;
            this.quotesToShow.splice(releventQuoteIndex, 1, relevantQuote);
            this.expiredQuotes.push(quote.quoteId);
        },
        async isFormOfPaymentValid() {
            // check all pendingQuotes if they have FOP with Error
            const isAnyPendingsWithFOPErrors = this.relevantQuotesToCheck.some((q) => {
                const quoteValidation = this.tripValidations.find((v) => v.QuoteId === q.quoteId);
                if (quoteValidation) {
                    //if found any FormOfPayment that is error
                    return quoteValidation.Validations.some((x) => x.name &&
                        x.name.includes(TripValidationsNames.FormOfPayment) &&
                        x.status === TripValidationStatusType.Error);
                }
                return false;
            });
            return !isAnyPendingsWithFOPErrors;
        },
        /*  ------------------------ */
        showCreditCardDetails(selectedQuote) {
            this.selectedQuote = selectedQuote;
            this.closeDrawer();
            this.isCreditCardDetailsDrawerOpen = true;
        },
        showSelectCreditCard(selectedQuote) {
            this.selectedQuote = selectedQuote;
            this.closeDrawer();
            this.isSelectCreditCardDrawerOpen = true;
        },
        closeNoFormOfPaymentModal() {
            this.showNoFormOfPaymentModal = false;
        },
        showMoreDetails(quote) {
            if (quote.quoteId) {
                this.selectedQuote = quote;
                this.isMoreDetailsDrawerOpen = true;
            }
        },
        closeDrawer() {
            this.isMoreDetailsDrawerOpen = false;
            this.isCreditCardDetailsDrawerOpen = false;
            this.isSelectCreditCardDrawerOpen = false;
        },
        verifyJustificationsHaveBeenSaved() {
            if (this.tripOOPJustifications && this.savedTripOOPJustifications) {
                return this.tripOOPJustifications.every((justification) => {
                    const matchFromSaved = this.savedTripOOPJustifications.find((savedItem) => savedItem.QuoteId === justification.QuoteId);
                    if (matchFromSaved) {
                        return (matchFromSaved.JustificationType === justification.JustificationType &&
                            (matchFromSaved.Code === justification.Code || matchFromSaved.Text === justification.Text));
                    }
                });
            }
        },
        async beforeProceedToPayment() {
            this.isBtnLoading = true;
            const canProceed = (await this.validateRequiredInfo(true, false, true)) && this.validateCvvInputs;
            if (!canProceed) {
                this.isBtnLoading = false;
                return;
            }
            if (!this.verifyJustificationsHaveBeenSaved()) {
                this.$store.dispatch({ type: 'tripStore/saveTripJustifications' });
            }
            eventBus.$emit('showRefFields', {
                refStages: [BookingStage.NewTrip, BookingStage.ProfileSelect],
                isShowEditBtn: false,
                isShowSaveBtn: true,
                disableAllFields: false,
                showOnlyIfNotAnswered: true,
                title: this.$t('trip.referenceForm') + ' / ' + this.$t('cart.financialReferenceModal.modalTitle'),
                isShowFinancialRef: this.trip.financialReferenceCodes.length > 0 && this.trip.financialReferenceId === 0,
                callBackfunc: {
                    onsuccess: () => {
                        try {
                            this.proceedToPayment();
                        }
                        catch (err) {
                            loggerService.error(err);
                            this.isBtnLoading = false; //in case of error in proceedToPayment (else - it should redirect to Checkout)
                        }
                    },
                    onfinally: () => {
                        //this.isBtnLoading = false;
                    },
                },
            });
        },
        async proceedToPayment() {
            // --- Pre Booking ---
            if (this.preBookingRes.isPreBookingNeeded) {
                const preBookingSendReq = {
                    TripId: this.trip.id,
                    PreBookingItems: this.pendingQuotes.map((quote) => ({
                        ProductType: quote.product,
                        QuoteId: quote.quoteId,
                        SourceSystem: quote.sourceSystem,
                    })),
                };
                try {
                    await tripService.sendPreBookingApproval(preBookingSendReq);
                    this.$router.push({ name: RoutesNames.bookingSuccess, params: { successType: 'preBooking' } });
                    return;
                }
                catch (err) {
                    loggerService.error(err);
                }
            }
            //  --- Flight quotes handling ---
            const isFlightQuotesExists = this.relevantQuotesToCheck.some((quote) => quote.product === Products.Flight && quote.productStatusName === ProductStatusName.PendingBooking);
            const isHotelQuotesExists = this.relevantQuotesToCheck.some((quote) => quote.product === Products.Hotel && quote.productStatusName === ProductStatusName.PendingBooking);
            if (isFlightQuotesExists || isHotelQuotesExists) {
                const canProceed = await this.handleQuotes();
                if (!canProceed) {
                    return;
                }
            }
            this.handleCheckout();
        },
        async getCompensationPricing() {
            const compensationQuote = this.relevantQuotesToCheck.find((q) => q.product === Products.Compensation);
            const currency = this.currentUser.preferences.currency;
            const tripId = this.trip.id;
            const quotes = this.relevantQuotesToCheck;
            const sourceSystem = this.$t('compensation.squake').toUpperCase();
            await this.$store.dispatch({
                type: 'tripStore/loadCompensationPricing',
                tripId,
                quotes,
                currency,
                sourceSystem,
                compensationQuote,
            });
        },
        async handleQuotes() {
            // this func should return a boolean to determine if can proceed to checkout page or not
            //reset any past items may be in flight map
            this.quotesMap = {};
            const flightQuotes = this.relevantQuotesToCheck.filter((quote) => quote.product === Products.Flight && quote.productStatusName === ProductStatusName.PendingBooking);
            // bug 43228 - treat amadeus lowcost as legacy flight
            const lowCostQuotes = flightQuotes.filter((quote) => quote.isLowCost && quote.sourceSystem !== SuppliersId.AMADEUS);
            const legacyQuotes = flightQuotes.filter((quote) => !quote.isLowCost || quote.sourceSystem === SuppliersId.AMADEUS);
            this.quotesMap.lowCostQuotes = lowCostQuotes;
            this.quotesMap.legacyQuotes = legacyQuotes;
            if (!lowCostQuotes.length) {
                return this.handleAllOtherQuotes();
            }
            this.quotesMap.isLowCost = true;
            // lowCost terms
            const lowCostOpts = {
                tripId: this.trip.id,
                userDetails: {
                    userIp: this.$store.getters['authStore/getUserIp'],
                    userBrowser: this.$store.getters['authStore/getUserBrowser'],
                },
                quotes: lowCostQuotes.map((quote) => {
                    // get cvv number
                    const cvv = this.cvvNumbers?.find((cvv) => cvv.quoteId === quote.quoteId)?.cvvNumber;
                    return {
                        quoteId: quote.quoteId,
                        creditCardSecurityNumber: cvv,
                    };
                }),
            };
            try {
                const lowCostRes = await flightService.saveLowCostTerms(lowCostOpts);
                const isSuccess = lowCostRes.every((flight) => flight.isSuccess);
                if (!isSuccess) {
                    const failedLowCosts = lowCostRes.filter((flight) => !flight.isSuccess);
                    this.quotesMap.failedItems = this.getItems(failedLowCosts, lowCostQuotes);
                }
                const isPriceChange = lowCostRes.some((flight) => flight.isPriceChanged);
                if (isPriceChange) {
                    const changedLowCosts = lowCostRes.filter((flight) => flight.isPriceChanged);
                    this.quotesMap.changedItems = this.getItems(changedLowCosts, lowCostQuotes);
                }
                const successQuotes = lowCostRes?.filter((res) => {
                    if (!res.isPriceChanged && res.isSuccess) {
                        if (isPriceChange) {
                            const isInChangedItems = this.quotesMap.changedItems?.some((item) => item.res.quoteId === res.quoteId);
                            if (isInChangedItems) {
                                return false;
                            }
                        }
                        if (isSuccess) {
                            const isInFailedItems = this.quotesMap.failedItems?.some((item) => item.res.quoteId === res.quoteId);
                            if (isInFailedItems) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                });
                this.quotesMap.successItems = this.getItems(successQuotes, lowCostQuotes);
                // if an item failed / price changed - stop the checkout and let the user decide what to do
                if ((!isSuccess && this.quotesMap.failedItems?.length) ||
                    (isPriceChange && this.quotesMap.changedItems?.length)) {
                    this.showWarningModal = true;
                    this.isBtnLoading = false;
                    return false;
                }
                // if all went well and have legacy items too - hold legacy items
                // if there is nothing else to do, just return true and move to next page
                if (isSuccess && !isPriceChange) {
                    return legacyQuotes.length ? this.handleAllOtherQuotes() : true;
                }
            }
            catch (err) {
                this.$alert.error(err);
                return false;
            }
        },
        openWarningModal(quotesMap) {
            this.quotesMap = quotesMap;
            this.showWarningModal = true;
            this.isBtnLoading = false;
        },
        handleCheckout() {
            //  --- Compensation quotes handling ---
            if (this.isCompensationChosen) {
                this.getCompensationPricing();
            }
            this.routeToCheckout();
        },
        async handleAllOtherQuotes() {
            // getting to this func possibly after going for low cost API, need to reset some stuff
            // this func will check if there's a price change(flights and hotels for now) or hold fail(for flights only)
            // and return a boolean to determine if can proceed to checkout page or not
            try {
                const opts = {
                    tripId: this.trip.id,
                    creditCard: null,
                    bookingItems: [],
                    priceCheckItems: [],
                };
                const hotelQuotes = this.relevantQuotesToCheck.filter((quote) => quote.product === Products.Hotel && quote.productStatusName === ProductStatusName.PendingBooking);
                if (hotelQuotes?.length) {
                    opts.priceCheckItems = hotelQuotes.map((quote) => {
                        return {
                            productType: Products.Hotel,
                            quoteId: quote.quoteId,
                            sourceSystem: quote.sourceSystem,
                            officeId: quote.officeId,
                            addFOP: false,
                            addApis: false,
                            originalPrice: quote.displayTotalPrice,
                        };
                    });
                }
                this.quotesMap.isLowCost = false;
                this.showWarningModal = false;
                const flightQuotes = this.relevantQuotesToCheck.filter((quote) => quote.product === Products.Flight && quote.productStatusName === ProductStatusName.PendingBooking);
                // bug 43228 - treat amadeus lowcost as legacy flight
                const legacyQuotes = flightQuotes.filter((quote) => !quote.isLowCost || quote.sourceSystem === SuppliersId.AMADEUS);
                const allRelevantQuotes = legacyQuotes.concat(hotelQuotes);
                this.quotesMap.legacyQuotes = legacyQuotes;
                // legacy hold
                opts.bookingItems = legacyQuotes.map((quote) => {
                    const cvv = this.cvvNumbers?.find((cvv) => cvv.quoteId === quote.quoteId)?.cvvNumber;
                    return {
                        productType: quote.product,
                        quoteId: quote.quoteId,
                        sourceSystem: quote.sourceSystem,
                        officeId: quote.officeId,
                        addFOP: false,
                        addApis: false,
                        originalPrice: quote.displayTotalPrice,
                        creditCardSecurityNumber: cvv,
                    };
                });
                const holdAndPriceCheckRes = await flightService.holdFlight(opts);
                const isSuccess = holdAndPriceCheckRes?.bookingResponses?.every((res) => res.success);
                const isPriceChange = holdAndPriceCheckRes?.bookingResponses?.some((res) => res.isPriceChanged);
                if (!isSuccess) {
                    // if hotel- means hotel room is booked/unavailable
                    const failedQuotes = holdAndPriceCheckRes?.bookingResponses?.filter((res) => !res.success);
                    this.quotesMap.failedItems = this.getItems(failedQuotes, allRelevantQuotes);
                }
                if (isPriceChange) {
                    const changedQuotes = holdAndPriceCheckRes?.bookingResponses?.filter((res) => res.isPriceChanged && allRelevantQuotes.some((q) => q.quoteId === res.quoteId));
                    this.quotesMap.changedItems = this.getItems(changedQuotes, allRelevantQuotes);
                }
                const successQuotes = holdAndPriceCheckRes?.bookingResponses?.filter((res) => {
                    if (!res.errorMsg && res.success) {
                        if (isPriceChange) {
                            const isInChangedItems = this.quotesMap.changedItems.some((item) => item.res.quoteId === res.quoteId);
                            if (isInChangedItems) {
                                return false;
                            }
                        }
                        if (!isSuccess) {
                            const isInFailedItems = this.quotesMap.failedItems.some((item) => item.res.quoteId === res.quoteId);
                            if (isInFailedItems) {
                                return false;
                            }
                        }
                        return true;
                    }
                    return false;
                });
                this.quotesMap.successItems = this.getItems(successQuotes, allRelevantQuotes);
                if ((!isSuccess && this.quotesMap.failedItems?.length) ||
                    (isPriceChange && this.quotesMap.changedItems?.length)) {
                    this.showWarningModal = true;
                    this.isBtnLoading = false;
                    return false;
                }
                else if (isSuccess && !isPriceChange) {
                    return true;
                }
            }
            catch (err) {
                this.$alert.error(err);
                return false;
            }
            finally {
                this.isBtnLoading = false;
            }
        },
        closeWarningModal() {
            // this func may be after hold / price change - need to update quotes
            this.showWarningModal = false;
            this.loadData();
            // if we don't need to route to checkout - remove the field to allow correct checkout flow
            if (this.quotesMap.isHoldOnly) {
                delete this.quotesMap.isHoldOnly;
            }
            this.isBtnLoading = false;
        },
        openCheckPriceModal(originalQuote) {
            this.showCheckPriceModal = true;
            this.isCheckPriceLoading = true;
            this.checkedPriceQuote = originalQuote;
        },
        disableCheckPriceModalLoading() {
            this.isCheckPriceLoading = false;
        },
        closeCheckPriceModal() {
            this.showCheckPriceModal = false;
        },
        getItems(flightResponses, quotes) {
            // get error / price change flight items
            return flightResponses.reduce((acc, res) => {
                acc.push({ quote: quotes.find((q) => q.quoteId === res.quoteId), res });
                return acc;
            }, []);
        },
        routeToCheckout() {
            const tempDataKey = utilService.makeId();
            this.$tempStorage.save(tempDataKey, { cvvNumbers: this.cvvNumbers });
            this.$router.push({
                name: RoutesNames.tripReferenceForm,
                params: () => ({ tripId: this.trip.id + '' }),
                query: { tempDataKey: tempDataKey },
            });
        },
        isQuotePending(quote) {
            return quote.productStatusName === ProductStatusName.PendingBooking;
        },
        isQuoteTicketed(quote) {
            return (quote.productStatusName === ProductStatusName.Confirmed ||
                quote.productStatusName === ProductStatusName.TicketingComplete);
        },
        isQuoteInProgress(quote) {
            return quote.status === QuoteStatus.InProgress;
        },
        isQuoteOnHold(quote) {
            return quote.productStatusName === ProductStatusName.OnHold;
        },
        handleOptionsDropdown(actionType) {
            switch (actionType) {
                case 'showCancelledItems':
                    this.showCancelledList();
                    this.scrollToCAncelledItems();
                    break;
                case 'showCancelTrip':
                    this.showCancelTripModal = true;
                    break;
                case 'showApprovalsView':
                    this.isApprovalsDialogOpen = true;
                    break;
                case 'ShowTripReferenceFormActions': {
                    // this should always show ref fields disabled, unless the user has the permission abd clicks 'edit'
                    const refOptions = {
                        refStages: [BookingStage.NewTrip, BookingStage.Checkout, BookingStage.ProfileSelect],
                        isShowEditBtn: this.currentUser.permissions.includes('UserCanEditCheckoutFields'),
                        title: this.$t('trip.referenceForm'),
                        isShowFinancialRef: this.hasFinancialReferenceCodes,
                        isShowTripReferenceForm: true,
                        showOnlyIfNotAnswered: false,
                        disableAllFields: true,
                        callBackfunc: {},
                    };
                    eventBus.$emit('showRefFields', refOptions);
                    break;
                }
                case 'showCopyTrip':
                    this.showCopyTripModal = true;
                    break;
                default:
                    break;
            }
        },
        showCancelledList() {
            this.isCancelledList = true;
        },
        scrollToCAncelledItems() {
            const el = this.$refs['cancelled-items'];
            if (!el) {
                return;
            }
            //Needs timeout for the rigth offsetTop
            setTimeout(() => {
                window.scrollTo({ top: el.offsetTop - 20, behavior: 'smooth' });
            }, 0);
        },
        addCvvNumbers(cvvNumber) {
            // insert into an Array the cvv numbers sent from FOP cvv to compare with the number of required CVV from tripValidations
            const idx = this.cvvNumbers.findIndex((c) => c.quoteId === cvvNumber.quoteId);
            if (idx === -1) {
                if (cvvNumber.cvvNumber.length >= 3) {
                    this.cvvNumbers.push(cvvNumber);
                }
            }
            else {
                if (cvvNumber.cvvNumber.length < 3) {
                    this.cvvNumbers.splice(idx, 1);
                }
                else {
                    this.cvvNumbers.splice(idx, 1, cvvNumber);
                }
            }
            this.validateRequiredInfo();
        },
        async onSetSuggestion(quote, isChosen = true, idx = null) {
            if (idx !== null) {
                // if idx === null - we are in "keep as suggested" process so idx is not needed
                this.quoteByIdxLoaders = Array(this.suggestedQuotes.length).fill(false);
            }
            if (!quote) {
                quote = this.quoteToCancel;
            }
            const { quoteId, tripId } = quote;
            try {
                if (idx === null) {
                    this.isCancellationInProcess = true;
                }
                else {
                    this.quoteByIdxLoaders[idx] = true;
                }
                await tripService.setIsChosen(tripId, quoteId, isChosen);
                await this.loadQuotes();
                this.$notify({
                    title: this.$t('notifications.success'),
                    message: this.$t('notifications.changesSavedSuccessfully'),
                    type: 'success',
                    position: 'bottom-right',
                });
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.closeCancelQuoteModal();
                if (idx === null) {
                    this.isCancellationInProcess = false;
                }
                else {
                    this.quoteByIdxLoaders[idx] = false;
                }
            }
        },
        async searchNewProduct(product) {
            this.$router.push({
                name: RoutesNames.tripIndex,
                tripId: this.$route.params.tripId,
                params: { product: product },
            });
            if (product === Products.Flight) {
                for (let i = 0; i < this.quotesToShow.length; i++) {
                    await this.cancelQuote(this.quotesToShow[i]);
                }
            }
        },
        async getCrossSellSuggestions() {
            // get all the products that are not included in the trip
            const products = this.currentUser.products.filter((product) => {
                const isProductExists = this.quotesToShow.some((quote) => quote.product === product);
                return !isProductExists;
            });
            // if no products to get - dont fetch anything and stop
            if (!products?.length) {
                this.crossSellSuggestions = [];
                return;
            }
            const suggestionsOpts = {
                tripId: this.trip.id,
                quoteId: 0,
                products,
            };
            try {
                let suggestions = await tripService.getCrossSellSuggestions(suggestionsOpts);
                //Filter products by if they are Accessible
                suggestions = suggestions.filter((suggestion) => utilService.isProductAccessible(suggestion.product, this.currentUser));
                if (suggestions?.length) {
                    // If we have an hotel suggestion - need to get the full suggestion info
                    const hotelSuggestionIdx = suggestions.findIndex((suggestion) => suggestion.product === Products.Hotel);
                    if (hotelSuggestionIdx !== -1) {
                        // add an extra suggestion for the hotels information suggestion
                        const hotelInfo = await hotelService.getHotelCrossSellSuggestions(this.trip.id);
                        if (hotelInfo?.hotels?.length && hotelInfo?.suggestion) {
                            // If we have TMC recommended AND corporate preferred - need to divide them into 2 separate suggestions
                            const corpHotels = hotelInfo.hotels.filter((hotel) => hotel.isCorporatePreffered);
                            const tmcHotels = hotelInfo.hotels.filter((hotel) => !hotel.isCorporatePreffered);
                            if (corpHotels.length && tmcHotels.length) {
                                const tmcSuggestions = utilService.deepClone(hotelInfo.suggestion);
                                tmcSuggestions.hotels = tmcHotels;
                                const corpSuggestions = utilService.deepClone(hotelInfo.suggestion);
                                corpSuggestions.hotels = corpHotels;
                                suggestions.splice(hotelSuggestionIdx + 1, 0, corpSuggestions, tmcSuggestions);
                            }
                            else {
                                hotelInfo.suggestion.hotels = hotelInfo.hotels;
                                suggestions.splice(hotelSuggestionIdx + 1, 0, hotelInfo.suggestion);
                            }
                        }
                    }
                }
                // remove non relevant suggestions
                this.crossSellSuggestions = suggestions.filter((suggestion) => {
                    const destination = suggestion.destination;
                    return destination.code || destination.name;
                });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        highlightCheckoutButton() {
            this.showHighlightCheckoutButton = true;
            setTimeout(() => (this.showHighlightCheckoutButton = false), 2000);
        },
    },
    computed: {
        canOnlyUseTravelRequest() {
            return this.$store.getters['authStore/loggedinUser'].permissions?.includes('CanOnlyUseTravelRequest');
        },
        isShowTripSummary() {
            return (!this.isLoading &&
                !this.showNotifications &&
                !(this.isMobile && !this.relevantQuotesToCheck.length) &&
                !(this.relevantQuotesToCheck.length === 1 && this.relevantQuotesToCheck[0].product === Products.Compensation));
        },
        isCheckoutDisabled() {
            return (!this.hasRelevantQuotesToChecks ||
                this.canOnlyUseTravelRequest ||
                this.markDisabledBtn ||
                this.isCurrentUserCanLookButNotBook ||
                this.relevantQuotesToCheck.some((q) => q.isRestricted) ||
                this.quotes.some((q) => q.quoteTravelPolicyViolation &&
                    q.quoteTravelPolicyViolation.some((v) => v.actionType === ActionTypes.RestrictSelection)));
        },
        isShowTripReferenceFormActions() {
            return (this.isTripCancelled ||
                ((this.trip.state === TripState.Finalized || this.trip.state === TripState.InApprovals) &&
                    (this.isAnyRefFields || this.hasFinancialReferenceCodes)));
        },
        isAnyRefFields() {
            const newTripList = this.getRefQuestions(BookingStage.NewTrip);
            const checkoutList = this.getRefQuestions(BookingStage.Checkout);
            const profileSelectList = this.getRefQuestions(BookingStage.ProfileSelect);
            return ((newTripList !== null && newTripList !== undefined && newTripList.length > 0) ||
                (checkoutList !== null && checkoutList !== undefined && checkoutList.length > 0) ||
                (profileSelectList !== null && profileSelectList !== undefined && profileSelectList.length > 0));
        },
        validateFinancialReference() {
            return this.trip.financialReferenceCodes.length === 0 || this.trip.financialReferenceId > 0;
        },
        hasFinancialReferenceCodes() {
            return this.trip.financialReferenceCodes.length > 0;
        },
        relevantValidation() {
            const quotes = this.$store.getters['tripStore/quotes'];
            const quotesForValidation = quotes.filter((q) => (q.productStatusName === ProductStatusName.PendingBooking ||
                q.productStatusName === ProductStatusName.Finalizing ||
                q.productStatusName === ProductStatusName.OnHold ||
                q.productStatusName === ProductStatusName.TicketingComplete ||
                q.productStatusName === ProductStatusName.Confirmed ||
                q.status === QuoteStatus.PendingReissue) &&
                !this.expiredQuotes.find((expiredQuoteId) => expiredQuoteId === q.quoteId));
            return this.tripValidations.filter((v) => quotesForValidation.some((q) => q.quoteId === v.QuoteId));
        },
        relevantQuotesToCheck() {
            const totalQuotes = this.$store.getters['tripStore/quotes'];
            const quotesToCheck = totalQuotes.filter((quote) => quote.status !== QuoteStatus.InProgress &&
                !(quote.productStatusName === ProductStatusName.OnHold && this.trip.state === TripState.InApprovals) &&
                !((quote.status === QuoteStatus.Ticketed ||
                    quote.status === QuoteStatus.Debited ||
                    quote.productStatusName === ProductStatusName.Finalizing ||
                    quote.productStatusName === ProductStatusName.Confirmed ||
                    quote.productStatusName === ProductStatusName.TicketingComplete) &&
                    (this.trip.state === TripState.InApprovals || this.trip.state === TripState.Finalized)) &&
                quote.isChosen &&
                !this.expiredQuotes.find((expiredQuoteId) => expiredQuoteId === quote.quoteId) &&
                quote.quoteType !== ManualQuoteType.PerDiem);
            return this.sortQuotes(quotesToCheck);
        },
        tripValidations() {
            return this.$store.getters['tripStore/tripValidations'];
        },
        validateCvvInputs() {
            const cvvCount = this.relevantValidation
                .filter((tv) => tv.Validations.some((v) => v.isCvvRequired && v.errMsg.toLowerCase() === 'cc' && v.status === TripValidationStatusType.OK))
                .filter((tv) => this.pendingQuotes.find((quote) => quote.quoteId === tv.QuoteId)?.isChosen).length;
            return cvvCount <= this.cvvNumbers.length;
        },
        isDisplayStaySafeBanner() {
            return !this.isStaySafeChosen && this.staySafeDetails && !this.staySafeDetails.showAutomatedActiveStaySafe;
        },
        isTripCancelled() {
            // return false;
            return this.trip.state === TripState.Cancelled;
        },
        isTripLocked() {
            return this.trip.isTripLocked;
        },
        isTripCancelDisabled() {
            return this.quotesToShow.some((quote) => (quote.product === Products.Flight &&
                quote.productStatusName === ProductStatusName.TicketingComplete &&
                (quote.sourceSystem === SuppliersId[SuppliersId.YPSILON] ||
                    quote.sourceSystem === SuppliersId[SuppliersId.FUSION] ||
                    (quote.sourceSystem === SuppliersId[SuppliersId.UAPIGALILEO] && quote.isLowCost) ||
                    (quote.sourceSystem === SuppliersId[SuppliersId.AMADEUS] && quote.isLowCost) ||
                    (quote.sourceSystem === SuppliersId[SuppliersId.SABRE] && quote.isLowCost))) ||
                (quote.product === Products.Flight &&
                    quote.productStatusName === ProductStatusName.TicketingComplete &&
                    !this.$store.getters['authStore/loggedinUser'].permissions?.includes('CanCancelTicketedFlightQuotes')) ||
                this.isOldDBRailAPIQuoteExists);
        },
        isOldDBRailAPIQuoteExists() {
            return this.quotesToShow?.some((q) => q.sourceSystem === SuppliersId.DEUTSCHEBAHN && q.segments?.some((s) => !s.isNewDBRailAPI));
        },
        isTripDeclined() {
            // return false;
            return this.trip.state === TripState.Declined;
        },
        isCurrentUserCanLookButNotBook() {
            return this.currentUser?.permissions?.includes('CanLookButNotBook');
        },
        isTripFinalized() {
            return this.trip.state === TripState.Finalized;
        },
        isAllTermsChecked() {
            return this.relevantQuotesToCheck
                .filter((quote) => (quote.status === QuoteStatus.Selected && quote.product !== Products.StaySafe) ||
                quote.status === QuoteStatus.PendingReissue) //stay safe doesn't need accept terms
                .every((quote) => this.termsCheckedMap[quote.quoteId] === true);
        },
        isOopFilled() {
            if (!this.savedTripOOPJustifications.length) {
                return false;
            }
            for (const justification of this.savedTripOOPJustifications) {
                if (!justification.Code && !justification.Text) {
                    return false;
                }
            }
            return true;
        },
        tripHasOopViolations() {
            return this.quotesToShow.some((quote) => quote.quoteTravelPolicyJustifications?.length &&
                !this.expiredQuotes.find((expiredQuoteId) => expiredQuoteId === quote.quoteId) &&
                (quote.status === QuoteStatus.Selected || quote.status === QuoteStatus.Booked));
        },
        isPreTripApproval() {
            return this.trip.state === TripState.PreTripApproval;
        },
        isPreTripRequest() {
            return this.trip.state === TripState.PreTripRequest;
        },
        isSearchRequest() {
            return this.trip.state === TripState.SearchRequest;
        },
        isAssistanceRequested() {
            return this.trip.state === TripState.AssistanceRequest;
        },
        tripOOPJustifications() {
            return this.$store.getters['tripStore/tripOOPJustifications'];
        },
        savedTripOOPJustifications() {
            return this.$store.getters['tripStore/savedTripOOPJustifications'];
        },
        formPaymentNotSupportedBtn() {
            return utilService.curlyBracketsReplace(this.$t('trip.formOfPaymentNotSupportedToGtp').toString(), [
                '' + this.trip.id,
            ]);
        },
        missingRequiredInfo() {
            return this.$t('validation.missingRequiredInfo').toString();
        },
        formPaymentNotSupportedModal() {
            return (this.$t('trip.formOfPaymentNotSupported') +
                ' ' +
                utilService.curlyBracketsReplace(this.$t('trip.formOfPaymentNotSupportedToGtp').toString(), ['' + this.trip.id]));
        },
        passengers() {
            return this.trip.passengers;
        },
        quoteToBook() {
            return this.pendingQuotes;
        },
        quotes() {
            // TODO: return back when quote issue is solved
            const quotes = utilService.deepClone(this.$store.getters['tripStore/quotes']);
            return quotes.sort((q1, q2) => compareAsc(new Date(q1.startDate), new Date(q2.startDate)));
        },
        quotesToShow() {
            const quotesArray = this.quotes.filter((quote) => quote.isCorporateUser || quote.isChosen);
            //sort : put non-manual quotes first, then manual, and put the StaySafe in the end
            return this.sortQuotes(quotesArray);
        },
        fusion3dsQuotes() {
            return this.quotes.filter((quote) => quote.product === Products.Flight &&
                quote.sourceSystem === hotelsConsts.SuppliersId.FUSION &&
                quote.authenticationLink);
        },
        cancelledQuotes() {
            return this.$store.getters['tripStore/cancelledQuotes'];
        },
        pendingQuotes() {
            // TODO: return back when quote issue is solved
            const quotes = this.$store.getters['tripStore/quotes'];
            return quotes.filter((q) => (q.productStatusName === ProductStatusName.PendingBooking ||
                q.productStatusName === ProductStatusName.OnHold) &&
                !this.expiredQuotes.find((expiredQuoteId) => expiredQuoteId === q.quoteId));
        },
        suggestedQuotes() {
            return this.quotes.filter((quote) => !quote.isCorporateUser && AgentSuggestedProducts.includes(quote.product));
        },
        preTripItems() {
            const preTripItems = this.$store.getters['tripStore/preTripItems'];
            return preTripItems;
        },
        requestAssistanceQuotes() {
            return this.$store.getters['tripStore/requestAssistanceQuotes'];
        },
        hasRelevantQuotesToChecks() {
            return this.relevantQuotesToCheck && this.relevantQuotesToCheck.length > 0;
        },
        //permissions
        canSendPriceOffer() {
            return (this.currentUser.permissions.includes('CanViewPriceOffer') &&
                this.quotes.some((quote) => quote.status !== QuoteStatus.Cancelled &&
                    quote.status !== QuoteStatus.Expired &&
                    quote.status !== QuoteStatus.Deleted &&
                    quote.isChosen));
        },
        currentUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        canUseRequestAssistance() {
            return this.currentUser.permissions.includes('CanUseRequestAssistance');
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        shareModalTitle() {
            return this.shareType === 'quotes' ? this.$t('common.shareQuotes') : this.$t('common.shareItinerary');
        },
        hasEmptyTravelers() {
            return this.trip.passengers.length !== this.trip.numberOfPassengers;
        },
        isAllQuotesManual() {
            return this.quotes.every((quote) => quote.product === Products.Manual);
        },
        shareTypes() {
            return ShareTypes;
        },
        quoteToCancelName() {
            if (!Object.keys(this.quoteToCancel).length) {
                return '';
            }
            switch (this.quoteToCancel.product || this.quoteToCancel.productName) {
                case Products.Hotel:
                    return this.quoteToCancel.hotelName || this.quoteToCancel.productName;
                case Products.HeathrowExpress:
                    return this.quoteToCancel.ticketType;
                case Products.Parking:
                    return this.quoteToCancel.segment.name;
                case Products.GroundService:
                    return this.quoteToCancel.dropoffAddress.street;
                case Products.Car:
                case Products.Flight:
                case Products.Rail:
                case Products.Evolvi:
                    return utilService.getProductTitle(this.quoteToCancel);
                case Products.StaySafe:
                    return this.$t('staySafe.staySafe') + '';
                case Products.Compensation:
                    return this.$t('compensation.compensation') + '';
                case Products.Amtrak:
                    return this.quoteToCancel.route;
                default:
                    return '';
            }
        },
        /* APPROVAL FLOW */
        approvalFlowSteps() {
            return this.$store.getters['tripStore/approvalFlowSteps'];
        },
        isTripInApprovals() {
            return this.trip.state === TripState.InApprovals;
        },
        approvalModifyUsers() {
            return this.$store.getters['tripStore/approvalModifyUsers'];
        },
        /* APPROVAL FLOW - END */
        hasUnreadMandatoryNotifications() {
            if (!this.notifications || !this.notifications.Notifications) {
                return;
            }
            return this.notifications.Notifications.some((n) => !n.IsRead && n.Type === NotificationTypes.Mandatory);
        },
        newNotificationMessages() {
            if (!this.notifications || !this.notifications.Notifications) {
                return -1;
            }
            return this.notifications.Notifications.filter((n) => !n.IsRead).length;
        },
        routesNames() {
            return RoutesNames;
        },
        products() {
            return Products;
        },
        staySafeDetails() {
            return this.$store.getters['authStore/loggedinUser']?.staySafeDetails;
        },
        compensationDetails() {
            const compensationQuote = this.relevantQuotesToCheck?.find((q) => q.product === Products.Compensation);
            return (this.$store.getters['authStore/loggedinUser']?.compensationDetails || compensationQuote?.compensationDetails);
        },
        isStaySafeChosen() {
            return this.quotes.some((quote) => quote.isChosen && quote.product === this.products.StaySafe);
        },
        isCompensationChosen() {
            return (this.relevantQuotesToCheck.some((quote) => quote.isChosen && quote.product === this.products.Compensation) ||
                (this.isTripCancelled && this.cancelledQuotes?.some((quote) => quote.product === this.products.Compensation)));
        },
        isShowCheckoutButton() {
            return (!this.quotesMap.failedItems?.length &&
                this.quotesMap.changedItems?.every((item) => (item.res.success || item.res.isSuccess) && !item.res.travelPolicyViolations?.length));
        },
        isShowSearchProductFlightButton() {
            // check if all items are of the same product. Otherwise we'll have a "Back to trip" button.
            return this.quotesMap.changedItems?.every((item) => item.quote.product === this.quotesMap.changedItems[0].quote.product);
        },
        isShowOnlyBackToTripButton() {
            return (this.quotesMap.failedItems ||
                this.quotesMap.changedItems?.some((item) => item.res.travelPolicyViolations?.length));
        },
    },
    unmounted() {
        eventBus.$off('showCreditCardDetails', this.showCreditCardDetails);
        eventBus.$off('showSelectCreditCard', this.showSelectCreditCard);
        eventBus.$off('validateRequiredInfo', this.validateRequiredInfo);
        eventBus.$off('addCvvNumbers', this.addCvvNumbers);
        eventBus.$off('reloadQuotes', this.loadQuotes);
        eventBus.$off('timedQuoteExpired', this.onTimedQuoteExpiry);
        eventBus.$off('highlightCheckoutButton', this.highlightCheckoutButton);
        eventBus.$off('scrollToErrorField', this.scrollToErrorField);
        eventBus.$off('openQuoteErrorModal', this.openQuoteErrorModal);
        eventBus.$off('closeCheckPriceModal', this.closeCheckPriceModal);
    },
});
