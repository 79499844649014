import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { ProductStatusName, Products, TripValidationsNames, QuoteStatus } from '@/types/consts';
import { useVuelidate } from '@vuelidate/core';
import formOfPayment from '../trip/quote-cards/form-of-payment/form-of-payment.vue';
import quoteTermsCheckbox from '../trip/quote-cards/quote-terms-checkbox/quote-terms-checkbox.vue';
import oopJustification from '@/components/trip/quote-cards/oop-justification/oop-justification.vue';
import appOopBadge from '@/components/app-oop-badge.vue';
import extrasCollapse from '@/components/trip/quote-extras/extras-collapse/extras-collapse.vue';
import bookingConfirm from '@/components/trip/quote-cards/booking-confirm/booking-confirm-layout.vue';
import baseQuoteHeader from '../base-quote-header/base-quote-header.vue';
import { utilService } from '@/services/util.service';
export default defineComponent({
    name: 'BaseQuoteCard',
    components: {
        quoteTermsCheckbox,
        oopJustification,
        appOopBadge,
        extrasCollapse,
        bookingConfirm,
        formOfPayment,
        baseQuoteHeader,
    },
    props: {
        isExtraInfoDefaultWidth: {
            type: Boolean,
            default: true,
        },
        hasPadding: {
            type: Boolean,
            default: true,
        },
        isExpiredQuote: {
            type: Boolean,
            default: false,
        },
        isSearchAgainButton: {
            type: Boolean,
            default: false,
        },
        // for specific cases where a quote state requires to hide FOP cmp
        hideFOP: {
            type: Boolean,
            default: false,
        },
        quote: {
            type: Object,
            required: true,
        },
        isAgentQuote: {
            type: Boolean,
            default: false,
        },
        hideDate: {
            type: Boolean,
            default: false,
        },
        tripValidations: {
            type: Array,
        },
        showTermsAndConditions: {
            type: Boolean,
            default: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            collapseVisible: [],
            quoteJustifications: [],
            Products: Products,
        };
    },
    created() {
        eventBus.$on('closeTicketCollapse', this.setCollapse);
    },
    computed: {
        avgPerDayPrice() {
            return this.quote.avgTotalPerDayPrice?.toFixed(2);
        },
        displayPrice() {
            if (!this.quote.displayTotalCost && this.quote.displayTotalCost !== 0) {
                return this.quote.displayPrice;
            }
            else {
                return this.quote.displayTotalCost;
            }
        },
        displayCurrencySymbol() {
            return utilService.getCurrencyCode(this.quote.displayCurrency);
        },
        hasExtraInfo() {
            return !!this.$slots['ticket-extra-info'];
        },
        hasImgSlot() {
            return !!this.$slots['ticket-img'];
        },
        hasBottomSection() {
            return !!this.$slots['ticket-bottom-section'];
        },
        hasTermsConditions() {
            return !!this.$slots['ticket-terms-conditions'];
        },
        hasRelatedQuotes() {
            return !!this.$slots['ticket-related-quotes'] && this.quote?.relatedQuotes?.length;
        },
        isCancelled() {
            return this.quote.productStatusName === ProductStatusName.Cancelled;
        },
        isConfirmed() {
            return (this.quote.productStatusName === ProductStatusName.Confirmed ||
                this.quote.productStatusName === ProductStatusName.TicketingComplete);
        },
        isError() {
            return this.quote.errorMessage?.length && this.quote.status === QuoteStatus.Selected;
        },
        isPending() {
            return this.quote.productStatusName === ProductStatusName.PendingBooking;
        },
        isReissue() {
            return this.quote.status === QuoteStatus.PendingReissue;
        },
        isOnHold() {
            return this.quote.productStatusName === ProductStatusName.OnHold || this.quote.status === QuoteStatus.Booked;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        quoteValidations() {
            return this.tripValidations?.find((v) => v.QuoteId === this.quote.quoteId) || null;
        },
        hasFOPValidation() {
            // check if have FOP validation on the quote
            return this.quoteValidations?.Validations?.some((validation) => validation.name.includes(TripValidationsNames.FormOfPayment));
        },
        hasBillbackValidation() {
            // check if have CarBillback validation on the quote
            return this.quoteValidations?.Validations?.some((validation) => validation.name === TripValidationsNames.CarBillback);
        },
        productHasOOP() {
            // list of products that wont have oop justifications - currently only evolvi
            return this.quote.product !== Products.Evolvi;
        },
        isManual() {
            return this.quote.isManual || false;
        },
        isPrepaid() {
            return this.quote.isPrePaid &&
                (this.quote.product !== Products.Car || this.quote.carSegment?.useBillBackForBooking || !this.hasBillbackValidation);
        },
    },
    methods: {
        setCollapse(status = false) {
            if (status) {
                return (this.collapseVisible = ['ticket-collapse']);
            }
            this.collapseVisible = [];
        },
        onAddJustification(justification, idx, emitValidationCheck = true) {
            if (this.isPending || this.isOnHold) {
                this.quoteJustifications[idx] = justification;
                this.$emit('justificationsChanged', this.quoteJustifications);
                if (emitValidationCheck) {
                    this.$emit('validationChanged');
                }
            }
        },
    },
    unmounted() {
        eventBus.$off('closeTicketCollapse', this.setCollapse);
    },
});
