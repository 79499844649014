import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import { format as formatDate } from 'date-fns';
import bookingResponsePreview from '../booking-response-preview/booking-response-preview.vue';
export default defineComponent({
    name: 'TripBookingResponseErrors',
    components: { bookingResponsePreview },
    props: {
        quotesMap: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getQuoteProductName(quoteId, type) {
            switch (type) {
                case Products.Hotel: {
                    return this.quotes.find((el) => el.quoteId === quoteId)?.hotelName;
                }
            }
        },
        getTripDateRange(quoteId, type) {
            switch (type) {
                case Products.Hotel: {
                    //TODO: why to find the quote twise ??
                    const checkin = this.quotes.find((el) => el.quoteId === quoteId)?.checkinDate;
                    const checkout = this.quotes.find((el) => el.quoteId === quoteId)?.checkoutDate;
                    return `(${formatDate(new Date(checkin), 'EEE, d MMM yy')} - ${formatDate(new Date(checkout), 'EEE, d MMM yy')})`;
                }
            }
        },
    },
});
