import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import TicketLayout from '@/components/ticket-layout.vue';
import generalQuoteCard from '@/components/trip/quote-cards/general-quote-card/general-quote-card.vue';
export default defineComponent({
    name: 'SuggestedQuoteCard',
    components: { TicketLayout, generalQuoteCard },
    data() {
        return {};
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
        },
    },
    methods: {},
    computed: {
        productIconMap() {
            // Need to take it to const file
            const productIconMap = {
                hotel: 'domain',
                car: 'directions_car',
                flight: 'flight',
                rail: 'directions_railway',
                evolvi: 'directions_railway',
            };
            return productIconMap;
        },
        isFlight() {
            return this.quote.product === Products.Flight;
        },
        isRail() {
            return this.quote.product === Products.Rail;
        },
        location() {
            let locationByProduct = '';
            switch (this.quote.product) {
                case 'hotel':
                    locationByProduct = this.quote.hotelName;
                    break;
                case 'car':
                    locationByProduct =
                        this.quote.pickupLocation ||
                            this.quote.pickupLocationParams?.name ||
                            this.quote.carSegment?.pickUpLocationName;
                    break;
                case 'flight':
                    locationByProduct = this.quote.segments?.at(0).flights[0].originName;
                    break;
                case 'rail':
                    locationByProduct = this.quote.segments?.at(0).originStationFullName;
                    break;
                case 'evolvi':
                    locationByProduct = this.quote.segments?.at(0).destination;
                    break;
            }
            return locationByProduct;
        },
        extraInfo() {
            let extraInfoByProduct = '';
            switch (this.quote.product) {
                case 'hotel':
                    extraInfoByProduct = this.quote.roomName;
                    break;
                case 'car':
                    extraInfoByProduct = this.quote.carSegment?.pickUpAddress.toLowerCase();
                    break;
                case 'flight':
                    extraInfoByProduct = this.quote.segments?.at(1)?.flights[0].originName;
                    break;
                case 'rail':
                    extraInfoByProduct = this.quote.route;
                    break;
            }
            return extraInfoByProduct;
        },
        tripValidations() {
            return this.$store.getters['tripStore/tripValidations'];
        },
    },
});
