<template>
  <button :type="btnType" class="btn" :class="btnClass" :style="[styles]" :disabled="isDisabled">
    <slot>
      <span class="pln" />
    </slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppBtn',
  props: {
    type: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'default',
    },
    isRound: {
      type: Boolean,
      default: true,
    },
    isFitWidth: {
      type: Boolean,
      default: false,
    },
    isShadow: {
      type: Boolean,
      default: false,
    },
    isFloating: {
      type: Boolean,
      default: false,
    },
    // TODO: Change prop name
    isPadding: {
      type: Number,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    btnType: {
      type: String,
      default: '',
    },
    isLinkStyle: {
      type: Boolean,
      default: false,
    },
    hasPadding: {
      type: Boolean,
      default: true,
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    isWrapContent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styles() {
      if (!this.isPadding) {
        return '';
      }
      const inlinePadding = this.isPadding;
      return {
        'padding-inline': `rem(${inlinePadding}px)`,
      };
    },
    btnClass() {
      return [
        'btn--' + this.type,
        'btn--size-' + this.size,
        this.isRound ? 'btn--round' : '',
        this.isFitWidth ? 'btn--width-100' : '',
        this.isFloating ? 'elevation-1' : '',
        this.isDisabled ? 'disabled' : '',
        this.isLoading ? 'button is-loading' : '',
        this.isShadow ? 'elevation-2' : '',
        this.isLinkStyle ? 'link-style' : '',
        this.hasPadding ? '' : 'no-padding',
        this.hasBorder ? '' : 'no-border',
        this.isWrapContent ? 'btn--content-wrap' : '',
      ];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

button.btn {
  height: unset;
  border-radius: rem(100px) !important;

  line-height: rem(24px);

  &.btn--size-default {
    padding: rem(8px) rem(24px);
    font-size: rem(16px);
  }

  &.btn--primary {
    border: none;
    background-color: $app-buttons-background;

    &:hover {
      background-color: $app-buttons-background-hover;
    }
  }

  &.btn--size-large {
    padding: rem(10px) rem(32px) rem(12px);
    font-size: 1rem;
  }

  &.btn--content-wrap {
    white-space: normal;
  }

  &.link-style {
    //TODO: need to use app-link-style
    background-color: unset;
    border-radius: none !important;
    border: unset;
    color: $primary;
    padding: 1rem;
    font-weight: 700;
  }

  &.no-padding {
    padding: unset;
  }
}

.btn--size-default {
  font-size: rem(14px);
  padding: 8px 24px 10px;
}

.btn--size-small {
  font-size: rem(14px);
  padding: 3.5px 16px 4.5px;
}

.shadow {
  -webkit-box-shadow: 5px 5px 24px 1px rgba(0, 0, 0, 0.53);
  box-shadow: 5px 5px 24px 1px rgba(0, 0, 0, 0.53);
}

.disabled {
  color: $gray-2900 !important;
  background-color: $gray-2800 !important;
  border: 0 !important;

  &.btn--text {
    background-color: unset !important;
  }
}

.disabled:hover {
  color: $gray-2900;
  background-color: $gray-2800;
  border-color: $gray-2900;
  cursor: default;
}

.no-border {
  border: unset !important;
}

.is-bold {
  font-weight: 700;
}
</style>
