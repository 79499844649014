import { defineComponent } from 'vue';
import { FlightSearchTypes, SuppliersId } from '@/modules/products/flight/models/consts';
import { Products, ProductStatusName, QuoteStatus } from '@/types/consts';
import { utilService } from '@/services/util.service';
import shareBtns from '@/components/app-share-quotes/app-share-products-btns.vue';
import appTooltipOfficeHoursRestricted from '@/components/app-tooltip-office-hours-restricted/app-tooltip-office-hours-restricted.vue';
import appTooltipFlightCapacityRestricted from '@/components/app-tooltip-flight-capacity-restricted/app-tooltip-flight-capacity-restricted.vue';
import appOopBadge from '@/components/app-oop-badge.vue';
import isPast from 'date-fns/isPast';
import isBefore from 'date-fns/isBefore';
export default defineComponent({
    name: 'FlightExtraInfo',
    components: {
        shareBtns,
        appOopBadge,
        appTooltipOfficeHoursRestricted,
        appTooltipFlightCapacityRestricted,
    },
    props: {
        flightResult: {
            type: Object,
            default: null,
        },
        isCart: {
            type: Boolean,
            default: false,
        },
        isHoldBtnLoading: {
            type: Boolean,
            default: false,
        },
        isCartBtnLoading: {
            type: Boolean,
            default: false,
        },
        isShareBtnLoading: {
            type: Boolean,
            default: false,
        },
        isQuoteValid: {
            type: Boolean,
            default: false,
        },
        isProductInShare: {
            type: Boolean,
            default: false,
        },
        isAgentQuote: {
            type: Boolean,
            default: false,
        },
        isReissueQuoteStatus: {
            type: Boolean,
            default: false,
        },
        isHoldBtnClicked: {
            type: Boolean,
            default: false,
        },
        isExpiredQuote: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showDisabledTooltip: false,
        };
    },
    methods: {
        handleSelectFlight() {
            //additional protection, in case user load the page before the expiration date but
            //pressed the button after the expiration date
            if (!this.isAddToCardDisabled) {
                if (this.searchOptions.searchFor === FlightSearchTypes.bySchedule) {
                    this.$emit('selectFlightBySchedule');
                }
                else {
                    this.$emit('addToCart');
                }
            }
        },
        isCapacityRestricted() {
            return this.flightResult?.capacityRestrictionViolation;
        },
        getDateObject(dateUTC) {
            return utilService.convertUnformattedUTCToLocalDate(dateUTC);
        },
    },
    computed: {
        isAllowCancel() {
            return (this.isCheckPrice &&
                this.isExpiredQuote &&
                !(this.isInProgress || !this.canBeCanceled || this.isReissue || this.isReissueFailed));
        },
        canBeCanceled() {
            return !((this.flightResult.product === Products.Flight &&
                this.flightResult.productStatusName === ProductStatusName.TicketingComplete &&
                (this.flightResult.sourceSystem === SuppliersId[SuppliersId.YPSILON] ||
                    this.flightResult.sourceSystem === SuppliersId[SuppliersId.FUSION] ||
                    (this.flightResult.sourceSystem === SuppliersId[SuppliersId.UAPIGALILEO] && this.flightResult.isLowCost) ||
                    (this.flightResult.sourceSystem === SuppliersId[SuppliersId.AMADEUS] && this.flightResult.isLowCost) ||
                    (this.flightResult.sourceSystem === SuppliersId[SuppliersId.SABRE] && this.flightResult.isLowCost))) ||
                (this.flightResult.product === Products.Flight &&
                    this.flightResult.productStatusName === ProductStatusName.TicketingComplete &&
                    !this.$store.getters['authStore/loggedinUser']?.permissions?.includes('CanCancelTicketedFlightQuotes')));
        },
        isInProgress() {
            return this.flightResult.status === QuoteStatus.InProgress;
        },
        isReissue() {
            return this.flightResult.status === QuoteStatus.PendingReissue;
        },
        isReissueFailed() {
            return this.flightResult.status === QuoteStatus.ReissueFailed;
        },
        isCheckPrice() {
            return (this.flightResult?.segments?.[0]?.flights?.[0]?.cityPair &&
                !this.flightResult.isManual &&
                (this.flightResult.status === QuoteStatus.Cancelled ||
                    this.flightResult.status === QuoteStatus.Expired ||
                    this.isExpiredQuote));
        },
        isTripLocked() {
            return this.trip.isTripLocked;
        },
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isExpired() {
            if (!this.flightResult?.lastTktDate) {
                return false;
            }
            // Parse the UTS date and time string to a Date object in UTC timezone
            const dateTime = this.getDateObject(this.flightResult.lastTktDate);
            // Check if the parsed date and time is in the past
            return isPast(dateTime); // Returns true if the date and time is in the past, false otherwise
        },
        isExpiredOfficeHours() {
            if (!this.flightResult?.nextOfficeHoursExpiration) {
                return false;
            }
            // Parse the UTS date and time string to a Date object in UTC timezone
            const dateTime = this.getDateObject(this.flightResult.nextOfficeHoursExpiration);
            // Check if the parsed date and time is in the past
            return isPast(dateTime); // Returns true if the date and time is in the past, false otherwise
        },
        isOfficeHoursExpiration() {
            return !isBefore(new Date(this.flightResult.lastTktDate), new Date(this.flightResult.nextOfficeHoursExpiration));
        },
        isAddToCardDisabled() {
            return (this.flightResult.isRestricTravelPolicyViolation ||
                this.isExpired ||
                this.flightResult.isHideBrandRule ||
                this.isExpiredOfficeHours);
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        isShareMode() {
            return this.$store.getters['tripStore/isShareMode'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        searchOptions() {
            return this.$store.getters['flightStore/searchOptions'];
        },
        flightSearchTypes() {
            return FlightSearchTypes;
        },
        isFinalizing() {
            return this.flightResult?.productStatusName === ProductStatusName.TicketingComplete;
        },
        isTicketed() {
            return this.flightResult?.productStatusName === ProductStatusName.Finalizing;
        },
        oopViolations() {
            return this.flightResult?.travelPolicyViolations?.map((violation) => {
                return violation.travelPolicyViolationExplanation ? violation.travelPolicyViolationExplanation : violation;
            });
        },
        numberOFTravelers() {
            return this.searchOptions.numberOFTravelers;
        },
    },
});
