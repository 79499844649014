import { ukRailService } from '@/modules/products/rails/uk-rail/services/uk-rail.service';
import { loggerService } from '@/services/logger.service';
import { reminderService } from '@/services/reminder.service';
import { router, RoutesNames } from '@/router';
import { utilService } from '@/services/util.service';
const initialState = () => ({
    searchOptions: null,
    trainsInfo: {},
    ukRailCards: [],
    selectedDeliveryMethod: {},
    deliveryMethodRes: {},
    seatsRes: null,
    splitMax: null,
});
export const ukRailStore = {
    namespaced: true,
    state: initialState(),
    getters: {
        searchOptions(state) {
            return state.searchOptions;
        },
        trainsInfo(state) {
            return state.trainsInfo;
        },
        ukRailCards(state) {
            return state.ukRailCards;
        },
        selectedDeliveryMethod(state) {
            return state.selectedDeliveryMethod;
        },
        deliveryMethodRes(state) {
            return state.deliveryMethodRes;
        },
        seatsRes(state) {
            return state.seatsRes;
        },
        splitMax(state) {
            return state.splitMax;
        }
    },
    mutations: {
        setUkRailSearchOptions(state, { searchOptions }) {
            state.searchOptions = searchOptions;
        },
        clearSearchOptions(state) {
            state.searchOptions = null;
        },
        setUkRailCards(state, { ukRailCards }) {
            state.ukRailCards = ukRailCards;
        },
        setTrainsInfo(state, { trainsInfo }) {
            state.trainsInfo = utilService.deepClone(trainsInfo);
        },
        setInitialState(state) {
            Object.assign(state, initialState());
        },
        setDeliveryMethod(state, { deliveryMethod }) {
            state.selectedDeliveryMethod = deliveryMethod;
        },
        setDeliveryMethodRes(state, { res }) {
            res.quoteId = state.deliveryMethodRes.quoteId;
            state.deliveryMethodRes = res;
        },
        setSplitMax(state, { res }) {
            state.splitMax = res;
        },
        setSeatsRes(state, { res }) {
            state.seatsRes = res;
        },
    },
    actions: {
        async loadResults({ commit, state, rootState }) {
            if (!state.searchOptions) {
                return null;
            }
            try {
                const searchParams = {
                    NumberOfPassengers: state.searchOptions.numOfTravellers,
                    Origin: state.searchOptions.fromStation.stationName,
                    Destination: state.searchOptions.toStation.stationName,
                    OriginType: state.searchOptions.departTimeBy,
                    DestinationType: state.searchOptions.returnTimeBy,
                    DepartDate: `${state.searchOptions.departDate}T00:00:00`,
                    DepartHour: state.searchOptions.departTime.split(':')[0],
                    DepartMinutes: state.searchOptions.departTime.split(':')[1],
                    ReturnDate: `${state.searchOptions.returnDate}T00:00:00`,
                    ReturnHour: state.searchOptions.returnTime.split(':')[0],
                    ReturnMinutes: state.searchOptions.returnTime.split(':')[1],
                    Via: state.searchOptions.isVia,
                    Avoid: state.searchOptions.isAvoid,
                    OneWay: state.searchOptions.tripType === 'oneWay',
                    OpenReturn: state.searchOptions.tripType === 'openReturn',
                    TravelTogether: false,
                    RailCardCode: Object.keys(state.searchOptions.cardType).length ? state.searchOptions.cardType : null,
                    ViaStation: state.searchOptions.isVia && state.searchOptions.viaStation.stationName
                        ? state.searchOptions.viaStation.stationName
                        : null,
                    AvoidStation: state.searchOptions.isAvoid && state.searchOptions.avoidStation.stationName
                        ? state.searchOptions.avoidStation.stationName
                        : null,
                    IsBooker: true,
                    UseBookersEvolviCredentials: true,
                    IsShowAdvancedSearchMode: state.searchOptions.isAllowExtraTime ||
                        state.searchOptions.isDirectService ||
                        state.searchOptions.isShowComplex ||
                        state.searchOptions.isSplitTicket,
                    AllowExtraTimeToChange: state.searchOptions.isAllowExtraTime,
                    AvoidLondonUnderground: false,
                    DirectServices: state.searchOptions.isDirectService,
                    ShowComplexJourneys: state.searchOptions.isShowComplex,
                    RequestSplitTix: state.searchOptions.isSplitTicket,
                    SplitTixType: state.searchOptions.splitTixType,
                    SplitMax: state.searchOptions.splitTixMaxSplits,
                    SplitAt: state.searchOptions.isSplitTicket && state.searchOptions.splitAtStation.stationName
                        ? state.searchOptions.splitAtStation.stationName
                        : null,
                    ClassAdvancedSearchMode: state.searchOptions.class,
                    PriorityAdvancedSearchMode: state.searchOptions.priority,
                    TripId: rootState.tripStore.trip.id,
                };
                reminderService.getNotificationsEvolviSearch(searchParams, rootState.tripStore.trip.id, rootState.authStore.user.corporationId);
                const trainsInfo = await ukRailService.getTrainsInfo(searchParams);
                commit({ type: 'setTrainsInfo', trainsInfo });
            }
            catch (err) {
                loggerService.error(err);
                commit({ type: 'setTrainsInfo', trainsInfo: null });
            }
        },
        async setTrainsInfo({ commit }, { trainsInfo }) {
            commit({ type: 'setTrainsInfo', trainsInfo });
        },
        async selectTickets({ dispatch, rootGetters, state }, { ticketsCacheKey }) {
            const tripId = rootGetters['tripStore/trip'].id;
            const req = {
                TripId: +tripId,
                TransactionId: state.trainsInfo.transactionId,
                ItemsCacheKey: ticketsCacheKey,
            };
            try {
                // add tickets to trip
                await ukRailService.selectTickets(req);
                // redirect to cart
                router.push({ name: RoutesNames.tripCart, params: tripId });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadUkRailCards({ commit }) {
            try {
                const ukRailCards = await ukRailService.getRailCards();
                commit({ type: 'setUkRailCards', ukRailCards });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadDeliveryMethods({ commit }, { quoteId }) {
            try {
                const res = await ukRailService.getDeliveryMethod(quoteId);
                res.quoteId = quoteId;
                commit({ type: 'setDeliveryMethodRes', res });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async saveDeliveryMethod({ commit }, { deliveryMethodRequest, selectedMethod }) {
            try {
                await ukRailService.saveDeliveryMethod(deliveryMethodRequest);
                commit({ type: 'setDeliveryMethod', deliveryMethod: selectedMethod });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadSplitMax({ commit }, { tripId }) {
            try {
                const res = await ukRailService.getSplitMax(tripId);
                commit({ type: 'setSplitMax', res });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async saveSplitMax({ commit }, { tripId, splitMax }) {
            try {
                await ukRailService.setSplitMax(tripId, splitMax);
                commit({ type: 'setSplitMax', res: splitMax });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async saveSeats({ commit }, { selectedSeats }) {
            try {
                const res = await ukRailService.saveSeats(selectedSeats);
                if (res.success) {
                    commit({ type: 'setSeatsRes', res: selectedSeats });
                }
                return res;
            }
            catch (err) {
                loggerService.error(err);
            }
        },
        async loadSeats({ commit }, { quoteId }) {
            try {
                const res = await ukRailService.getSeats(quoteId);
                commit({ type: 'setSeatsRes', res });
            }
            catch (err) {
                loggerService.error(err);
            }
        },
    },
};
