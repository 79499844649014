import { CreditCardsTypes, TripValidationsNames } from '@/types/consts';
import { paymentService } from '@/services/payment.service';
import { defineComponent } from 'vue';
import { loggerService } from '@/services/logger.service';
import { eventBus } from '@/services/event-bus.service';
import { isFuture, parse } from 'date-fns';
import { utilService } from '@/services/util.service';
import { tripService } from '@/services/trip.service';
import { requiredIf, numeric } from '@vuelidate/validators';
import { hotelService } from '@/modules/products/hotels/services/hotel.service';
import { useVuelidate } from '@vuelidate/core';
import * as hotelsConsts from '@/modules/products/hotels/models/consts';
export default defineComponent({
    components: {},
    name: 'SelectCreditCard',
    data() {
        return {
            isLoading: false,
            markFields: false,
            allCreditCards: [],
            availableCreditCards: [],
            supportedCreditCardsByProvider: {},
            corporateBillbackRestrictions: [],
            tripValidations: [],
            hotelQuoteDetails: null,
            selectedCreditCard: 0,
            markedCard: [],
            userCreditCardDetails: {
                CreditCardDetails: {},
            },
            isDetailsSaved: false,
            userInfo: {
                cardHolderName: '',
                expirationMonth: '',
                expirationYear: '',
                countryCode: '',
                stateCode: '',
                zipCode: '',
                city: '',
                street: '',
            },
            billbacks: [],
            billBackRestriction: '',
            billBackRestrictions: [],
            billbackHotelFax: '',
            billbackPaymentType: '',
            userCard: [],
            selectedCard: {},
        };
    },
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    async created() {
        await this.loadData();
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            try {
                await this.loadAvailableCreditCards();
                if (this.hasBillBack) {
                    await this.loadBillBackRestrictions();
                    await this.loadHotelQuoteDetails();
                }
            }
            catch (error) {
                loggerService.error(error);
            }
            finally {
                this.isLoading = false;
            }
        },
        async loadHotelQuoteDetails() {
            if (this.billbackHotelFax) {
                return;
            }
            this.hotelQuoteDetails =
                (await hotelService.getHotelQuoteDetails(this.quote.quoteId, this.quote.tripId, true)) || null;
            if (this.hotelQuoteDetails?.fax) {
                this.billbackHotelFax = this.hotelQuoteDetails.fax.replace(/[^0-9]/g, ''); // only numbers
            }
            if (this.hotelQuoteDetails?.billBack?.billbackPaymentRestriction) {
                this.billBackRestriction = this.hotelQuoteDetails.billBack.billbackPaymentRestriction;
                if (this.billBackRestriction) {
                    const selectedCardIdx = this.availableCreditCards.findIndex((availableCc) => availableCc.name === this.selectedCard.cardName);
                    if (selectedCardIdx !== -1) {
                        this.availableCreditCards[selectedCardIdx].isShowingBillback = true;
                    }
                }
            }
        },
        async loadBillBackRestrictions() {
            this.billBackRestrictions =
                (await tripService.getBillBackRestrictions(this.quote.tripId, this.quote.quoteId)) || [];
        },
        async loadAvailableCreditCards() {
            const quoteId = this.quote.quoteId;
            const tripId = this.quote.tripId;
            //load Credit Cards and the selected for this quote
            const res = (await paymentService.availableCreditCards(quoteId, tripId));
            this.selectedCard = await paymentService.selectedCreditCard(this.quote.quoteId);
            if (!res.corporateCreditCards && !this.selectedCard) {
                return;
            }
            this.allCreditCards = res.corporateCreditCards || [];
            //set available Credit Cards
            if (!this.canSelectFop && this.hasBillBack) {
                this.availableCreditCards = this.billBackCards;
            }
            else if (!this.canSelectFop && this.selectedCardNumber) {
                this.availableCreditCards = this.selectedOrDefaultCards;
            }
            else {
                this.availableCreditCards = this.allCreditCards;
            }
            let isAvailableSelectedExists = false;
            // mark & sort cards:
            if (this.availableCreditCards) {
                this.availableCreditCards.forEach((cc, idx) => {
                    if (cc.isUseBillback) {
                        this.billbacks[idx] = true;
                    }
                    if (cc.expirationDate) {
                        cc.isExpired = !isFuture(new Date(cc.expirationDate));
                    }
                    cc.isSupportedByProvider = !cc.name.includes('not supported');
                    cc.uniqueId = utilService.makeGUID();
                    let isDefaultOrChosen = false;
                    if (!cc.isExpired && cc.isSupportedByProvider) {
                        const isChosenByUser = this.selectedCardNumber
                            ? cc.token === this.selectedCardNumber
                            : cc.name === this.selectedCard.cardName; //  selected card number from tripValidations
                        const isDefault = cc.token === this.defaultCard && !isChosenByUser; // if has chosen card - don't mark default card
                        isDefaultOrChosen = isChosenByUser || isDefault;
                        cc.isDefaultOrChosen = isDefaultOrChosen; // mark that card that was selected before change- to avoid saving same card again
                    }
                    cc.listOrder = 0;
                    if (cc.isExpired) {
                        cc.listOrder = -1;
                    }
                    if (cc.isSupportedByProvider) {
                        cc.listOrder = -2;
                    }
                    cc.isShowingBillback = this.billBackRestriction || true;
                    if (isDefaultOrChosen && this.markedCard.length === 0) {
                        // don't allow more than one marked card (if they used 4580458045804580 for more than one card)
                        this.markedCard.push(cc.uniqueId);
                    }
                });
                // check is the selected card in the availableCreditCards
                isAvailableSelectedExists = this.availableCreditCards.some((s) => s.token && s.token === this.selectedCard.cardNumber);
            }
            else {
                this.availableCreditCards = [];
            }
            if (!isAvailableSelectedExists && this.selectedCard.cardNumber) {
                // the selected card is not in the availableCreditCards - add it.
                const uniqueId = utilService.makeGUID();
                const card = {
                    cardHolderName: this.selectedCard.cardOwnerName,
                    name: this.selectedCard.cardName || this.selectedCard.cardOwnerName,
                    type: {
                        name: this.selectedCard.cardCode,
                        code: this.selectedCard.cardCode,
                    },
                    displayNumber: this.selectedCard.cardNumber,
                    isSupportedByProvider: true,
                    uniqueId: uniqueId,
                    billingAddress: {
                        address: this.selectedCard.address,
                        city: this.selectedCard.city,
                        state: this.selectedCard.state,
                        zipCode: this.selectedCard.zip,
                        country: this.selectedCard.country,
                    },
                    cardOwner: this.selectedCard.ownerType,
                    isDefaultOrChosen: true,
                    isUseBillback: this.selectedCard.isUseBillback,
                    virtualCardId: this.selectedCard.virtualCardId,
                    expDate: parse(`${this.selectedCard.expYear}-${String(Number(this.selectedCard.expMonth) + 1).padStart(2, '0')}-01`, 'yyyy-MM-dd', new Date()),
                };
                this.availableCreditCards.push(card);
                this.markedCard = [uniqueId]; // make this card the selected card
            }
            if (this.availableCreditCards.length > 1) {
                this.availableCreditCards.sort((a, b) => a.listOrder - b.listOrder);
            }
        },
        async loadSelectedCreditCard() {
            const selectedCards = (await paymentService.selectedCreditCard(this.quote.quoteId));
            this.supportedCreditCardsByProvider = selectedCards.supportedCreditCardsByProvider || {};
            this.corporateBillbackRestrictions = selectedCards.corporateBillbackRestrictions || [];
            this.billbackPaymentType = selectedCards.paymentType;
            if (selectedCards.hotelFaxNumber) {
                this.billbackHotelFax = selectedCards.hotelFaxNumber;
            }
            this.billBackRestriction = selectedCards.paymentRestriction;
        },
        async selectCard(idx) {
            //selecting a new card by the user
            this.markedCard = []; // reset default marked
            if (this.availableCreditCards[idx].isExpired || !this.availableCreditCards[idx].isSupportedByProvider) {
                // don't mark expired card.
                return false;
            }
            this.markedCard = [this.availableCreditCards[idx].uniqueId];
            this.selectedCreditCard = idx; // selectedCreditCard will be sent once saved
            this.availableCreditCards[idx].isShowingBillback = true;
            if (this.billBackRestrictions && this.billBackRestrictions.length === 1) {
                this.billBackRestriction = this.billBackRestrictions[0];
            }
        },
        async saveCreditCardDetails() {
            this.markFields = true;
            if (this.v$.$invalid) {
                return;
            }
            this.fillCreditCardDetails(this.markedCard[0]);
            const res = await paymentService.saveCreditCardDetails(this.userCreditCardDetails);
            if (res.success) {
                eventBus.$emit('reloadFormOfPayment'); // form-of-payments.ts
            }
            this.$emit('closeDrawer');
        },
        /*eslint complexity: ["error", 26]*/
        fillCreditCardDetails(uniqueId) {
            const cc = this.availableCreditCards.find((c) => c.uniqueId === uniqueId);
            const ccIdx = this.availableCreditCards.findIndex((c) => c.uniqueId === uniqueId);
            if (!cc) {
                return;
            }
            const isVirtualCard = cc.virtualCreditCardId ? true : false;
            const FOPType = cc.type && cc.type.code
                ? cc.type.code in CreditCardsTypes
                    ? 'cc'
                    : cc.type.code === CreditCardsTypes.virtualCard
                        ? CreditCardsTypes.virtualCard
                        : 'iata'
                : 'iata';
            this.userCreditCardDetails.TripId = this.quote.tripId;
            this.userCreditCardDetails.ProfileId = this.loggedinUser.id;
            this.userCreditCardDetails.QuoteId = this.quote.quoteId;
            this.userCreditCardDetails.UserId = this.loggedinUser.id ? this.loggedinUser.id : 0;
            this.userCreditCardDetails.Type = this.quote.product;
            this.userCreditCardDetails.DeploymentId = 0;
            this.userCreditCardDetails.IsShowRecievedFrom = false;
            this.userCreditCardDetails.IsCorporateUser = true;
            this.userCreditCardDetails.IsUseBillback = cc.isUseBillback;
            this.userCreditCardDetails.BillbackFailureEmail = cc.billbackFailureEmail;
            this.userCreditCardDetails.VirtualCreditCardId = isVirtualCard ? cc.virtualCreditCardId || 0 : 0;
            // billback
            this.userCreditCardDetails.PaymentRestriction =
                cc.isShowingBillback && this.billbacks[ccIdx] ? this.billBackRestriction : '';
            this.userCreditCardDetails.SelectedPaymentRestriction = this.billbacks[ccIdx] ? this.billBackRestriction : '';
            if (isVirtualCard) {
                this.userCreditCardDetails.HotelFaxNumber = this.billbackHotelFax;
            }
            this.userCreditCardDetails.IsRandomCVV = cc.isRandomCVV;
            // CreditCardDetails
            this.userCreditCardDetails.CreditCardDetails.Code = cc.displayNumber;
            this.userCreditCardDetails.CreditCardDetails.ExpDate = cc.expirationDate?.toString() || cc.expDate;
            this.userCreditCardDetails.CreditCardDetails.Code = cc.type.code;
            this.userCreditCardDetails.CreditCardDetails.CardHolder = cc.cardHolderName;
            this.userCreditCardDetails.CreditCardDetails.CompanyName = isVirtualCard ? null : cc.type.name;
            // this.userCreditCardDetails.CreditCardDetails.SaveCC = false;
            this.userCreditCardDetails.CreditCardDetails.Number = cc.token || cc.displayNumber;
            this.userCreditCardDetails.CreditCardDetails.Address = cc.billingAddress?.address || 'address';
            this.userCreditCardDetails.CreditCardDetails.City = cc.billingAddress?.city || 'city';
            this.userCreditCardDetails.CreditCardDetails.Country = cc.billingAddress?.country;
            this.userCreditCardDetails.CreditCardDetails.State = cc.billingAddress?.state || '';
            this.userCreditCardDetails.CreditCardDetails.Zip = cc.billingAddress?.zipCode;
            this.userCreditCardDetails.CreditCardDetails.FopType = isVirtualCard
                ? 'virtualCreditCardId'
                : this.quote.isLowCost
                    ? 'cc'
                    : FOPType;
            if (cc.cardOwner === 'lodge' || cc.cardOwner === 'TMC') {
                this.userCreditCardDetails.CreditCardDetails.OwnerType = cc.cardOwner;
            }
            else if (cc.cardOwner === 'Traveller') {
                this.userCreditCardDetails.CreditCardDetails.OwnerType = 'personal';
            }
            else {
                this.userCreditCardDetails.CreditCardDetails.OwnerType = isVirtualCard ? 'tmc' : 'personal';
            }
            this.userCreditCardDetails.CreditCardDetails.IsRandomCVV = cc.isRandomCVV;
            this.userCreditCardDetails.CreditCardDetails.Name = cc.name;
        },
        getLast4CardDigits(idx) {
            if (!this.availableCreditCards ||
                !this.availableCreditCards.length ||
                !this.availableCreditCards[idx].displayNumber) {
                return '****';
            }
            const cardNumber = this.availableCreditCards[idx].displayNumber;
            return cardNumber.slice(cardNumber.length - 4);
        },
        getCreditCardType() {
            const validations = this.quoteValidations || null;
            const card = validations?.Validations.find((v) => v.errMsg === 'CC' && v.parameteValue);
            if (!card) {
                return '';
            }
            return card?.parameteValue[0];
        },
    },
    computed: {
        quoteValidations() {
            const validations = this.$store.getters['tripStore/tripValidations'];
            const quoteValidations = validations.find((r) => r.QuoteId === this.quote.quoteId);
            return quoteValidations;
        },
        fopValidations() {
            return this.quoteValidations?.Validations.find((v) => v.name === TripValidationsNames.FormOfPayment);
        },
        canSelectFop() {
            if (this.isRatehawk || (this.isExpedia && this.isTmc)) {
                return false;
            }
            return this.loggedinUser.permissions?.includes('СanSelectFopAtTheCart') || !this.allCreditCards?.length;
        },
        selectedOrDefaultCards() {
            return this.allCreditCards.filter((cc) => !cc.isExpired && cc.isSupportedByProvider && cc.token === this.selectedCardNumber);
        },
        billBackCards() {
            return this.allCreditCards.filter((cc) => cc.isUseBillback);
        },
        hasBillBack() {
            return this.allCreditCards.some((cc) => cc.isUseBillback);
        },
        selectedCardNumber() {
            // the selected by the user
            if (this.fopValidations && this.fopValidations.parameteValue) {
                return this.fopValidations.creditCardToken;
            }
            else if (this.selectedCard && this.selectedCard.cardNumber) {
                return this.selectedCard.cardNumber;
            }
            return '';
        },
        defaultCard() {
            // the selected by the user
            const defaultCard = this.availableCreditCards.find((cc) => cc.isDefaultFOP && !cc.isExpired && cc.isSupportedByProvider);
            if (defaultCard) {
                return defaultCard.token;
            }
            return '';
        },
        ownerType() {
            return this.fopValidations?.ownerType;
        },
        isRatehawk() {
            if (!this.hotelQuoteDetails) {
                return false;
            }
            return this.hotelQuoteDetails.sourceSystem === hotelsConsts.SuppliersId.RATEHAWK;
        },
        isTmc() {
            return this.fopValidations?.ownerType?.toLocaleLowerCase() === 'tmc';
        },
        isExpedia() {
            if (!this.hotelQuoteDetails || !this.hotelQuoteDetails.sourceSystem) {
                return false;
            }
            return this.hotelQuoteDetails.sourceSystem === hotelsConsts.SuppliersId.EXPEDIARAPID;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
    },
    validations() {
        return {
            billBackRestriction: {
                required: requiredIf(() => {
                    return (this.availableCreditCards[this.selectedCreditCard].isShowingBillback &&
                        this.billbacks[this.selectedCreditCard]);
                }),
            },
            billbackHotelFax: {
                numeric,
                required: requiredIf(() => {
                    return this.billbacks?.length && this.billbackHotelFax; // allow empty or numbers
                }),
            },
        };
    },
});
