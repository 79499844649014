import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-d60dc24e"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
    key: 0,
    class: "ticket-img"
};
const _hoisted_2 = {
    key: 0,
    class: "product-extra-info price-wrapper is-flex is-flex-direction-column is-align-items-center is-justify-content-center"
};
const _hoisted_3 = { class: "product-extra-info-price is-flex is-flex-direction-column is-align-items-center" };
const _hoisted_4 = { key: 0 };
const _hoisted_5 = { class: "has-text-centered is-hidden-mobile muliSemiBold mb-1" };
const _hoisted_6 = {
    key: 1,
    class: "payment-method is-flex is-align-items-center has-text-weight-bold is-justify-content-center mt-2"
};
const _hoisted_7 = { key: 0 };
const _hoisted_8 = { key: 1 };
const _hoisted_9 = {
    key: 0,
    class: "mb-4"
};
const _hoisted_10 = {
    key: 0,
    class: "mb-3 ml-1"
};
const _hoisted_11 = { class: "is-flex" };
const _hoisted_12 = { class: "oop-title" };
const _hoisted_13 = {
    key: 0,
    class: "has-text-danger ml-1"
};
const _hoisted_14 = {
    key: 0,
    class: "product-extra-info price-wrapper is-flex is-flex-direction-column is-align-items-center is-justify-content-center m-auto"
};
const _hoisted_15 = { key: 0 };
const _hoisted_16 = {
    key: 0,
    class: "mb-4"
};
const _hoisted_17 = {
    key: 2,
    class: "payment-method is-flex is-align-items-center has-text-weight-bold is-justify-content-center mt-2"
};
const _hoisted_18 = { key: 0 };
const _hoisted_19 = { key: 1 };
const _hoisted_20 = {
    key: 3,
    class: "ml-2"
};
const _hoisted_21 = { class: "p-4" };
const _hoisted_22 = {
    key: 0,
    class: "p-4 pt-0"
};
const _hoisted_23 = {
    key: 0,
    class: "terms-checkbox-wrapper"
};
const _hoisted_24 = {
    key: 1,
    class: "p-4 pt-0"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_base_quote_header = _resolveComponent("base-quote-header");
    const _component_app_oop_badge = _resolveComponent("app-oop-badge");
    const _component_app_price = _resolveComponent("app-price");
    const _component_app_img = _resolveComponent("app-img");
    const _component_form_of_payment = _resolveComponent("form-of-payment");
    const _component_app_btn = _resolveComponent("app-btn");
    const _component_oop_justification = _resolveComponent("oop-justification");
    const _component_extras_collapse = _resolveComponent("extras-collapse");
    const _component_booking_confirm = _resolveComponent("booking-confirm");
    const _component_quote_terms_checkbox = _resolveComponent("quote-terms-checkbox");
    return (_openBlock(), _createElementBlock("section", null, [
        _createVNode(_component_base_quote_header, {
            hideDate: _ctx.hideDate,
            quote: _ctx.quote,
            onCancelQuote: _cache[0] || (_cache[0] = ($event) => (_ctx.$emit('cancelQuote', _ctx.quote))),
            onCancelReissue: _cache[1] || (_cache[1] = ($event) => (_ctx.$emit('cancelReissue', _ctx.quote.quoteId)))
        }, _createSlots({
            "timed-quote": _withCtx(() => [
                _renderSlot(_ctx.$slots, "timed-quote", {}, undefined, true)
            ]),
            "dropdown-extra-options": _withCtx(() => [
                _renderSlot(_ctx.$slots, "dropdown-extra-options", {}, undefined, true)
            ]),
            _: 2 /* DYNAMIC */
        }, [
            (_ctx.$slots['quote-title'])
                ? {
                    name: "quote-title",
                    fn: _withCtx(() => [
                        _renderSlot(_ctx.$slots, "quote-title", {}, undefined, true)
                    ]),
                    key: "0"
                }
                : undefined
        ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["hideDate", "quote"]),
        _createElementVNode("section", {
            class: _normalizeClass(["ticket-layout-container is-flex is-flex-direction-column", { cancelled: _ctx.isCancelled, confirmed: _ctx.isConfirmed }])
        }, [
            _createElementVNode("section", {
                class: _normalizeClass(["ticket-layout elevation-1 is-flex is-flex-direction-column", { 'no-padding': !_ctx.hasPadding, 'quote-has-error': _ctx.isError }])
            }, [
                _createElementVNode("div", {
                    class: _normalizeClass(["ticket-top-section is-flex", { 'is-flex-direction-column': _ctx.isMobile }])
                }, [
                    (_ctx.hasImgSlot)
                        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
                            _renderSlot(_ctx.$slots, "ticket-img", {}, undefined, true)
                        ]))
                        : _createCommentVNode("v-if", true),
                    _createElementVNode("section", {
                        class: _normalizeClass(["ticket-details", {
                                'has-extra-info': _ctx.hasExtraInfo,
                                'has-img-slot': _ctx.hasImgSlot,
                                hotel: _ctx.quote.product === _ctx.Products.Hotel,
                                'p-4': _ctx.quote.product !== _ctx.Products.StaySafe && _ctx.quote.product !== _ctx.Products.Compensation,
                            }])
                    }, [
                        _renderSlot(_ctx.$slots, "ticket-details", {}, undefined, true)
                    ], 2 /* CLASS */),
                    _createCommentVNode(" Quote Extra Info  - Desktop "),
                    (!_ctx.isMobile)
                        ? (_openBlock(), _createElementBlock("section", {
                            key: 1,
                            class: _normalizeClass(["extra-info is-flex is-justify-content-space-evenly", { 'is-default-width': _ctx.isExtraInfoDefaultWidth, cancelled: _ctx.isCancelled, confirmed: _ctx.isConfirmed, 'search-again-button': _ctx.isSearchAgainButton }])
                        }, [
                            _renderSlot(_ctx.$slots, "ticket-extra-info", {}, () => [
                                (!_ctx.$slots['ticket-extra-info'])
                                    ? (_openBlock(), _createElementBlock("section", _hoisted_2, [
                                        (_ctx.quote.travelPolicyViolations?.length)
                                            ? (_openBlock(), _createBlock(_component_app_oop_badge, {
                                                key: 0,
                                                policyViolations: _ctx.quote.travelPolicyViolations
                                            }, null, 8 /* PROPS */, ["policyViolations"]))
                                            : _createCommentVNode("v-if", true),
                                        _createElementVNode("div", _hoisted_3, [
                                            (_ctx.isPending && !_ctx.isAgentQuote)
                                                ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.$t('trip.toPay')), 1 /* TEXT */))
                                                : _createCommentVNode("v-if", true),
                                            _createVNode(_component_app_price, {
                                                class: "price is-flex-grow-1",
                                                displayCurrencyCode: _ctx.quote.displayCurrency,
                                                value: _ctx.displayPrice
                                            }, _createSlots({ _: 2 /* DYNAMIC */ }, [
                                                (_ctx.quote.numberOfDays > 1)
                                                    ? {
                                                        name: "subtitle",
                                                        fn: _withCtx(() => [
                                                            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.displayCurrencySymbol) + _toDisplayString(_ctx.avgPerDayPrice) + " / " + _toDisplayString(_ctx.$t('common.perday')), 1 /* TEXT */)
                                                        ]),
                                                        key: "0"
                                                    }
                                                    : undefined
                                            ]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["displayCurrencyCode", "value"])
                                        ]),
                                        (_ctx.quote.isManual || _ctx.quote.product === _ctx.Products.Car)
                                            ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                                                _createVNode(_component_app_img, {
                                                    isIcon: "",
                                                    iconName: "creditCard.svg"
                                                }),
                                                (_ctx.isPrepaid)
                                                    ? (_openBlock(), _createElementBlock("p", _hoisted_7, _toDisplayString(_ctx.$t('paymentMethods.prePaid')), 1 /* TEXT */))
                                                    : (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.$t('cart.premierInn.services.payOnDeparture')), 1 /* TEXT */))
                                            ]))
                                            : _createCommentVNode("v-if", true),
                                        (_ctx.isPending && _ctx.hasFOPValidation && !_ctx.hideFOP)
                                            ? (_openBlock(), _createBlock(_component_form_of_payment, {
                                                key: 2,
                                                class: "mt-5 form-of-payment",
                                                quote: _ctx.quote,
                                                tripValidations: _ctx.tripValidations,
                                                isConfirmed: _ctx.isConfirmed
                                            }, null, 8 /* PROPS */, ["quote", "tripValidations", "isConfirmed"]))
                                            : _createCommentVNode("v-if", true),
                                        (_ctx.isAgentQuote)
                                            ? (_openBlock(), _createBlock(_component_app_btn, {
                                                key: 3,
                                                onClick: _cache[2] || (_cache[2] = ($event) => (_ctx.$emit('onSetSuggestion', _ctx.quote))),
                                                class: "add-btn add-to-trip-btn",
                                                type: "primary",
                                                isFitWidth: ""
                                            }, {
                                                default: _withCtx(() => [
                                                    _createTextVNode(_toDisplayString(_ctx.$t('btn.addToTrip')), 1 /* TEXT */)
                                                ]),
                                                _: 1 /* STABLE */
                                            }))
                                            : _createCommentVNode("v-if", true),
                                        _createElementVNode("div", null, [
                                            (_ctx.isSearchAgainButton)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                                                    _createVNode(_component_app_btn, {
                                                        class: "desktop-btn add-btn",
                                                        type: "primary",
                                                        onClick: _cache[3] || (_cache[3] = ($event) => (_ctx.$emit('onSearchAgain')))
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('btn.searchAgain')), 1 /* TEXT */)
                                                        ]),
                                                        _: 1 /* STABLE */
                                                    })
                                                ]))
                                                : _createCommentVNode("v-if", true),
                                            (_ctx.isSearchAgainButton)
                                                ? (_openBlock(), _createBlock(_component_app_btn, {
                                                    key: 1,
                                                    onClick: _cache[4] || (_cache[4] = ($event) => (_ctx.$emit('cancelQuote'))),
                                                    class: "delete-btn is-bold ml-0 p-0 mb-4",
                                                    type: "text",
                                                    isFitWidth: ""
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('btn.delete')), 1 /* TEXT */)
                                                    ]),
                                                    _: 1 /* STABLE */
                                                }))
                                                : _createCommentVNode("v-if", true)
                                        ])
                                    ]))
                                    : _createCommentVNode("v-if", true)
                            ], true)
                        ], 2 /* CLASS */))
                        : _createCommentVNode("v-if", true),
                    _createCommentVNode(" Quote Extra Info END")
                ], 2 /* CLASS */),
                (!_ctx.isAgentQuote)
                    ? (_openBlock(), _createElementBlock("section", {
                        key: 0,
                        class: _normalizeClass(["px-4", {
                                'ticket-bottom py-4': _ctx.$slots['extras-content'],
                                ' pb-0': _ctx.$slots['booking-confirm-info'],
                            }])
                    }, [
                        _createCommentVNode(" Out of Policy "),
                        (_ctx.quote.quoteTravelPolicyJustifications?.length && (_ctx.isPending || _ctx.isOnHold) && !_ctx.isAgentQuote && _ctx.productHasOOP)
                            ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
                                _createElementVNode("div", _hoisted_11, [
                                    _createElementVNode("h4", _hoisted_12, _toDisplayString(_ctx.$t('common.outOfPolicy')), 1 /* TEXT */),
                                    (_ctx.quote.quoteTravelPolicyJustifications?.length)
                                        ? (_openBlock(), _createElementBlock("span", _hoisted_13, "*"))
                                        : _createCommentVNode("v-if", true)
                                ]),
                                _renderSlot(_ctx.$slots, "ticket-oop-justifications", {}, undefined, true),
                                (!_ctx.$slots['ticket-oop-justifications'])
                                    ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.quote.quoteTravelPolicyJustifications, (travelPolicyJustification, idx) => {
                                        return (_openBlock(), _createBlock(_component_oop_justification, {
                                            key: `oop-justification-${idx}`,
                                            justification: travelPolicyJustification,
                                            onAddedJustification: _ctx.onAddJustification,
                                            onSavedJustification: _cache[5] || (_cache[5] = ($event) => (_ctx.$emit('savedJustification'))),
                                            idx: idx
                                        }, null, 8 /* PROPS */, ["justification", "onAddedJustification", "idx"]));
                                    }), 128 /* KEYED_FRAGMENT */))
                                    : _createCommentVNode("v-if", true)
                            ]))
                            : _createCommentVNode("v-if", true),
                        _createCommentVNode(" Out of Policy  END"),
                        _createCommentVNode(" Extras "),
                        (_ctx.$slots['extras-content'] && !_ctx.isAgentQuote)
                            ? (_openBlock(), _createBlock(_component_extras_collapse, {
                                key: 1,
                                quote: _ctx.quote
                            }, {
                                "extras-content": _withCtx(() => [
                                    _renderSlot(_ctx.$slots, "extras-content", {}, undefined, true)
                                ]),
                                _: 3 /* FORWARDED */
                            }, 8 /* PROPS */, ["quote"]))
                            : _createCommentVNode("v-if", true),
                        _createCommentVNode(" Extras END "),
                        _renderSlot(_ctx.$slots, "ticket-bottom-section", {}, undefined, true),
                        _createCommentVNode(" Quote Extra Info  - Mobile "),
                        (_ctx.isMobile)
                            ? (_openBlock(), _createElementBlock("section", {
                                key: 2,
                                class: _normalizeClass(["extra-info is-flex pb-4 is-align-items-center is-flex-direction-column", { 'is-default-width': _ctx.isExtraInfoDefaultWidth }])
                            }, [
                                _renderSlot(_ctx.$slots, "ticket-extra-info", {}, () => [
                                    (!_ctx.$slots['ticket-extra-info'])
                                        ? (_openBlock(), _createElementBlock("section", _hoisted_14, [
                                            (_ctx.quote.travelPolicyViolations?.length)
                                                ? (_openBlock(), _createBlock(_component_app_oop_badge, {
                                                    key: 0,
                                                    policyViolations: _ctx.quote.travelPolicyViolations
                                                }, null, 8 /* PROPS */, ["policyViolations"]))
                                                : _createCommentVNode("v-if", true),
                                            _createElementVNode("div", null, [
                                                (_ctx.isPending && !_ctx.isAgentQuote)
                                                    ? (_openBlock(), _createElementBlock("span", _hoisted_15, _toDisplayString(_ctx.$t('trip.toPay')), 1 /* TEXT */))
                                                    : _createCommentVNode("v-if", true),
                                                _createVNode(_component_app_price, {
                                                    class: "price is-flex-grow-1",
                                                    displayCurrencyCode: _ctx.quote.displayCurrency,
                                                    value: _ctx.displayPrice
                                                }, null, 8 /* PROPS */, ["displayCurrencyCode", "value"])
                                            ]),
                                            (_ctx.isAgentQuote)
                                                ? (_openBlock(), _createBlock(_component_app_btn, {
                                                    key: 1,
                                                    onClick: _cache[6] || (_cache[6] = ($event) => (_ctx.$emit('onSetSuggestion', _ctx.quote))),
                                                    class: "add-btn add-to-trip-btn",
                                                    type: "primary",
                                                    isFitWidth: ""
                                                }, {
                                                    default: _withCtx(() => [
                                                        _createTextVNode(_toDisplayString(_ctx.$t('btn.addToTrip')), 1 /* TEXT */)
                                                    ]),
                                                    _: 1 /* STABLE */
                                                }))
                                                : _createCommentVNode("v-if", true),
                                            _createElementVNode("div", null, [
                                                (_ctx.isSearchAgainButton)
                                                    ? (_openBlock(), _createElementBlock("div", _hoisted_16, [
                                                        _createVNode(_component_app_btn, {
                                                            class: "desktop-btn add-btn",
                                                            type: "primary",
                                                            onClick: _cache[7] || (_cache[7] = ($event) => (_ctx.$emit('onSearchAgain')))
                                                        }, {
                                                            default: _withCtx(() => [
                                                                _createTextVNode(_toDisplayString(_ctx.$t('btn.searchAgain')), 1 /* TEXT */)
                                                            ]),
                                                            _: 1 /* STABLE */
                                                        })
                                                    ]))
                                                    : _createCommentVNode("v-if", true),
                                                (_ctx.isSearchAgainButton)
                                                    ? (_openBlock(), _createBlock(_component_app_btn, {
                                                        key: 1,
                                                        onClick: _cache[8] || (_cache[8] = ($event) => (_ctx.$emit('cancelQuote'))),
                                                        class: "delete-btn is-bold ml-0 p-0 mb-4",
                                                        type: "text",
                                                        isFitWidth: ""
                                                    }, {
                                                        default: _withCtx(() => [
                                                            _createTextVNode(_toDisplayString(_ctx.$t('btn.delete')), 1 /* TEXT */)
                                                        ]),
                                                        _: 1 /* STABLE */
                                                    }))
                                                    : _createCommentVNode("v-if", true)
                                            ]),
                                            (_ctx.quote.isManual || _ctx.quote.product === _ctx.Products.Car)
                                                ? (_openBlock(), _createElementBlock("div", _hoisted_17, [
                                                    _createVNode(_component_app_img, {
                                                        isIcon: "",
                                                        iconName: "creditCard.svg"
                                                    }),
                                                    (_ctx.isPrepaid)
                                                        ? (_openBlock(), _createElementBlock("p", _hoisted_18, _toDisplayString(_ctx.$t('paymentMethods.prePaid')), 1 /* TEXT */))
                                                        : (_openBlock(), _createElementBlock("p", _hoisted_19, _toDisplayString(_ctx.$t('cart.premierInn.services.payOnDeparture')), 1 /* TEXT */))
                                                ]))
                                                : _createCommentVNode("v-if", true),
                                            (_ctx.isPending && _ctx.hasFOPValidation && !_ctx.hideFOP)
                                                ? (_openBlock(), _createBlock(_component_form_of_payment, {
                                                    key: 3,
                                                    quote: _ctx.quote,
                                                    tripValidations: _ctx.tripValidations,
                                                    isConfirmed: _ctx.isConfirmed
                                                }, null, 8 /* PROPS */, ["quote", "tripValidations", "isConfirmed"]))
                                                : _createCommentVNode("v-if", true)
                                        ]))
                                        : _createCommentVNode("v-if", true)
                                ], true)
                            ], 2 /* CLASS */))
                            : _createCommentVNode("v-if", true),
                        _createCommentVNode(" Terms and Conditions "),
                        _createCommentVNode(" Booking Confirmation "),
                        (!(_ctx.quote.product === _ctx.Products.Flight || _ctx.quote.product === _ctx.Products.StaySafe))
                            ? (_openBlock(), _createElementBlock("section", _hoisted_20, [
                                (_ctx.$slots['booking-confirm-info'] || _ctx.$slots['booking-confirm-voucher'])
                                    ? (_openBlock(), _createBlock(_component_booking_confirm, {
                                        key: 0,
                                        class: "booking-confirm",
                                        quote: _ctx.quote
                                    }, {
                                        confirmationInfo: _withCtx(() => [
                                            _renderSlot(_ctx.$slots, "booking-confirm-info", {}, undefined, true)
                                        ]),
                                        confirmationInfoRight: _withCtx(() => [
                                            _renderSlot(_ctx.$slots, "booking-confirm-voucher", {}, undefined, true)
                                        ]),
                                        _: 3 /* FORWARDED */
                                    }, 8 /* PROPS */, ["quote"]))
                                    : _createCommentVNode("v-if", true)
                            ]))
                            : _createCommentVNode("v-if", true),
                        _createCommentVNode(" Booking Confirmation ")
                    ], 2 /* CLASS */))
                    : (_ctx.isAgentQuote && _ctx.isMobile)
                        ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            _createCommentVNode(" Add to trip button for suggested item in mobile "),
                            _createElementVNode("section", _hoisted_21, [
                                _createVNode(_component_app_btn, {
                                    onClick: _cache[9] || (_cache[9] = ($event) => (_ctx.$emit('onSetSuggestion', _ctx.quote))),
                                    class: "add-btn add-to-trip-btn",
                                    type: "primary",
                                    isFitWidth: ""
                                }, {
                                    default: _withCtx(() => [
                                        _createTextVNode(_toDisplayString(_ctx.$t('btn.addToTrip')), 1 /* TEXT */)
                                    ]),
                                    _: 1 /* STABLE */
                                })
                            ])
                        ], 2112 /* STABLE_FRAGMENT, DEV_ROOT_FRAGMENT */))
                        : _createCommentVNode("v-if", true),
                _createElementVNode("section", {
                    class: _normalizeClass(["is-flex is-align-items-center", { expired: _ctx.isExpiredQuote || _ctx.isSearchAgainButton }])
                }, [
                    (_ctx.hasRelatedQuotes)
                        ? _renderSlot(_ctx.$slots, "ticket-related-quotes", { key: 0 }, undefined, true)
                        : _createCommentVNode("v-if", true),
                    (!_ctx.isAgentQuote)
                        ? _renderSlot(_ctx.$slots, "ticket-terms-conditions", { key: 1 }, () => [
                            (_ctx.isPending || _ctx.isReissue)
                                ? (_openBlock(), _createElementBlock("section", _hoisted_22, [
                                    (!_ctx.$slots['ticket-terms-conditions'])
                                        ? (_openBlock(), _createElementBlock("div", _hoisted_23, [
                                            _createVNode(_component_quote_terms_checkbox, {
                                                quote: _ctx.quote,
                                                onTermsChecked: _cache[10] || (_cache[10] = ($event) => (_ctx.$emit('termsChecked', $event))),
                                                onOpenTermsAndConditions: _cache[11] || (_cache[11] = ($event) => (_ctx.$emit('openTermsAndConditions', true)))
                                            }, null, 8 /* PROPS */, ["quote"])
                                        ]))
                                        : _createCommentVNode("v-if", true)
                                ]))
                                : (_ctx.showTermsAndConditions)
                                    ? (_openBlock(), _createElementBlock("section", _hoisted_24, [
                                        _createVNode(_component_app_btn, {
                                            type: "text",
                                            class: "py-0 px-3",
                                            onClick: _cache[12] || (_cache[12] = ($event) => (_ctx.$emit('openTermsAndConditions', true)))
                                        }, {
                                            default: _withCtx(() => [
                                                _createTextVNode(_toDisplayString(_ctx.$t('common.termsAndConditions')), 1 /* TEXT */)
                                            ]),
                                            _: 1 /* STABLE */
                                        })
                                    ]))
                                    : _createCommentVNode("v-if", true)
                        ], true)
                        : _createCommentVNode("v-if", true)
                ], 2 /* CLASS */),
                _createCommentVNode(" Terms and Conditions END")
            ], 2 /* CLASS */)
        ], 2 /* CLASS */)
    ]));
}
