import { createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-39d9e022"), n = n(), _popScopeId(), n);
const _hoisted_1 = { class: "app-product-loader" };
const _hoisted_2 = { class: "loader-content is-flex is-flex-direction-column" };
const _hoisted_3 = { key: 0 };
const _hoisted_4 = { class: "slideshow is-flex is-justify-content-center is-align-items-center" };
const _hoisted_5 = { class: "loader-icon is-flex is-justify-content-center" };
const _hoisted_6 = {
    key: 1,
    class: "m-auto mt-3"
};
const _hoisted_7 = {
    key: 0,
    class: "m-auto"
};
const _hoisted_8 = {
    key: 1,
    class: "loading-message-text custom-gap m-auto"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_app_img = _resolveComponent("app-img");
    return (_openBlock(), _createElementBlock("section", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createCommentVNode(" Progress Bar "),
            (!_ctx.hideProgressBar)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                    _createElementVNode("div", {
                        class: "progress-bar-fill",
                        style: _normalizeStyle({ width: _ctx.completionPercentage + '%' })
                    }, null, 4 /* STYLE */)
                ]))
                : _createCommentVNode("v-if", true),
            _createCommentVNode(" Icons Slideshow "),
            _createElementVNode("div", {
                class: _normalizeClass(["slideshow-container", { 'append-to-top': _ctx.isShowingSkeleton || _ctx.loadingMessage?.image, 'custom-gap': !_ctx.isShowingSkeleton }])
            }, [
                _createElementVNode("div", _hoisted_4, [
                    _createElementVNode("div", _hoisted_5, [
                        (_openBlock(), _createElementBlock("span", {
                            class: "material-symbols-outlined current",
                            key: _ctx.currIconIdx
                        }, _toDisplayString(_ctx.iconArray[_ctx.currIconIdx]), 1 /* TEXT */)),
                        (_openBlock(), _createElementBlock("span", {
                            class: "material-symbols-outlined next",
                            key: _ctx.nextIconIdx
                        }, _toDisplayString(_ctx.iconArray[_ctx.nextIconIdx]), 1 /* TEXT */))
                    ]),
                    _createElementVNode("div", null, _toDisplayString(_ctx.sentences[_ctx.currSentenceIdx] || _ctx.sentences[_ctx.sentences.length - 1]), 1 /* TEXT */)
                ])
            ], 2 /* CLASS */),
            (_ctx.isChristmasMode)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _createVNode(_component_app_img, {
                        isIcon: false,
                        iconName: "Animation - 1734604683021.gif",
                        iconPath: "merry-christmas/"
                    })
                ]))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
                    (_ctx.isShowingLoadingMessage && (_ctx.loadingMessage?.image || _ctx.loadingMessage?.mobileImage))
                        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                            _createVNode(_component_app_img, {
                                class: "loading-message-image elevation-2 custom-gap",
                                externalUrl: _ctx.isMobile ? _ctx.loadingMessage?.mobileImage : _ctx.loadingMessage?.image
                            }, null, 8 /* PROPS */, ["externalUrl"])
                        ]))
                        : _createCommentVNode("v-if", true),
                    (_ctx.isShowingLoadingMessage && _ctx.loadingMessage?.text)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                            _createElementVNode("p", null, _toDisplayString(_ctx.loadingMessage?.text), 1 /* TEXT */)
                        ]))
                        : _createCommentVNode("v-if", true)
                ], 64 /* STABLE_FRAGMENT */))
        ])
    ]));
}
