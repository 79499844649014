import { createI18n } from 'vue-i18n';
import * as dateLocale from 'date-fns/locale';
import { formatInTimeZone } from 'date-fns-tz';
import en from '@/assets/locale/en.json';
import de from '@/assets/locale/de.json';
import nl from '@/assets/locale/nl.json';
import fr from '@/assets/locale/fr.json';
import it from '@/assets/locale/it.json';
import pt from '@/assets/locale/pt-PT.json';
import es from '@/assets/locale/es.json';
const messages = {
    en,
    de,
    nl,
    fr,
    it,
    pt,
    es,
};
const i18n = createI18n({
    locale: 'en',
    messages,
});
const defaultLocale = dateLocale.enUS;
const localeMap = {
    // Map of ISO codes to date-fns locales
    'en-US': dateLocale.enUS,
    en: dateLocale.enUS,
    'es-ES': dateLocale.es,
    es: dateLocale.es,
    'fr-FR': dateLocale.fr,
    fr: dateLocale.fr,
    'de-DE': dateLocale.de,
    de: dateLocale.de,
    'nl-NL': dateLocale.nl,
    nl: dateLocale.nl,
    'it-IT': dateLocale.it,
    it: dateLocale.it,
    'pt-PT': dateLocale.pt,
    pt: dateLocale.pt,
};
function _getUserLanguage() {
    const localeCode = i18n.global.locale;
    const locale = localeMap[localeCode];
    return locale ?? defaultLocale;
}
export default i18n;
export const $t = (str) => i18n.global.t(str);
export const $te = (str) => i18n.global.te(str);
export const $tDate = (date, timeZone, formatStr, isLocalization = true) => {
    return formatInTimeZone(date, timeZone, formatStr, {
        locale: !isLocalization ? defaultLocale : _getUserLanguage(),
    });
};
