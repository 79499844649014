import { defineComponent } from 'vue';
import { Products } from '@/types/consts';
import appOopBadge from '@/components/app-oop-badge.vue';
import { utilService } from '../../../services/util.service';
export default defineComponent({
    name: 'BookingResponsePreview',
    components: { appOopBadge },
    props: {
        response: {
            type: Object,
        },
        quote: {
            type: Object,
        },
    },
    methods: {
        checkInOutDate(date) {
            return this.$filters.date(date, 'eee, dd MMM yy');
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        quotes() {
            return this.$store.getters['tripStore/quotes'];
        },
        quoteSummary() {
            return this.trip.quotes.find((q) => q.id === this.response.quoteId) || {};
        },
        isCompensationQuote() {
            return this.quote.type === Products.Compensation;
        },
        quoteTitle() {
            return utilService.getProductTitle(this.quote);
        },
        isPriceChangeError() {
            return this.response.errorMsg?.toLowerCase() === 'price changed' || this.response?.isPriceChanged === true;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        statusClass() {
            return utilService.getQuoteStatusClass(this.quote);
        },
        displayCurrencySymbol() {
            return utilService.getCurrencyCode(this.quote.displayCurrency);
        },
    },
});
