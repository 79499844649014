export var CarClass;
(function (CarClass) {
    CarClass[CarClass["Mini"] = 0] = "Mini";
    CarClass[CarClass["MiniElite"] = 1] = "MiniElite";
    CarClass[CarClass["Economy"] = 2] = "Economy";
    CarClass[CarClass["EconomyElite"] = 3] = "EconomyElite";
    CarClass[CarClass["Compact"] = 4] = "Compact";
    CarClass[CarClass["CompactElite"] = 5] = "CompactElite";
    CarClass[CarClass["Intermediate"] = 6] = "Intermediate";
    CarClass[CarClass["IntermediateElite"] = 7] = "IntermediateElite";
    CarClass[CarClass["Standard"] = 8] = "Standard";
    CarClass[CarClass["StandardElite"] = 9] = "StandardElite";
    CarClass[CarClass["Fullsize"] = 10] = "Fullsize";
    CarClass[CarClass["FullsizeElite"] = 11] = "FullsizeElite";
    CarClass[CarClass["Premium"] = 12] = "Premium";
    CarClass[CarClass["PremiumElite"] = 13] = "PremiumElite";
    CarClass[CarClass["Luxury"] = 14] = "Luxury";
    CarClass[CarClass["LuxuryElite"] = 15] = "LuxuryElite";
    CarClass[CarClass["Oversize"] = 16] = "Oversize";
    CarClass[CarClass["Special"] = 17] = "Special";
    CarClass[CarClass["Kleinstwagen"] = 18] = "Kleinstwagen";
    CarClass[CarClass["UntereMittelklasse"] = 19] = "UntereMittelklasse";
    CarClass[CarClass["Kleinwagen"] = 20] = "Kleinwagen";
})(CarClass || (CarClass = {}));
export var CarSpec;
(function (CarSpec) {
    CarSpec[CarSpec["AirCondition"] = 0] = "AirCondition";
    CarSpec[CarSpec["Automatic"] = 1] = "Automatic";
    CarSpec[CarSpec["Manual"] = 2] = "Manual";
    CarSpec[CarSpec["UnlimitedMileage"] = 3] = "UnlimitedMileage";
})(CarSpec || (CarSpec = {}));
export var VehicleAvailability;
(function (VehicleAvailability) {
    VehicleAvailability[VehicleAvailability["None"] = 0] = "None";
    VehicleAvailability[VehicleAvailability["Confirmed"] = 1] = "Confirmed";
    VehicleAvailability[VehicleAvailability["Requested"] = 2] = "Requested";
})(VehicleAvailability || (VehicleAvailability = {}));
export var TransmitionType;
(function (TransmitionType) {
    TransmitionType[TransmitionType["Automatic"] = 0] = "Automatic";
    TransmitionType[TransmitionType["Automatic4WD"] = 1] = "Automatic4WD";
    TransmitionType[TransmitionType["AutomaticAWD"] = 2] = "AutomaticAWD";
    TransmitionType[TransmitionType["Manual"] = 3] = "Manual";
    TransmitionType[TransmitionType["Manual4WD"] = 4] = "Manual4WD";
    TransmitionType[TransmitionType["ManualAWD"] = 5] = "ManualAWD";
    TransmitionType[TransmitionType["ManualUnspecifiedDrive"] = 6] = "ManualUnspecifiedDrive";
    TransmitionType[TransmitionType["AutomaticUnspecifiedDrive"] = 7] = "AutomaticUnspecifiedDrive";
})(TransmitionType || (TransmitionType = {}));
export var NumberOfDoors;
(function (NumberOfDoors) {
    NumberOfDoors[NumberOfDoors["TwoToThreeDoors"] = 0] = "TwoToThreeDoors";
    NumberOfDoors[NumberOfDoors["TwoToFourDoors"] = 1] = "TwoToFourDoors";
    NumberOfDoors[NumberOfDoors["FourToFiveDoors"] = 2] = "FourToFiveDoors";
    NumberOfDoors[NumberOfDoors["None"] = 3] = "None";
    NumberOfDoors[NumberOfDoors["TwoDoors"] = 4] = "TwoDoors";
    NumberOfDoors[NumberOfDoors["ThreeDoors"] = 5] = "ThreeDoors";
    NumberOfDoors[NumberOfDoors["FourDoors"] = 6] = "FourDoors";
    NumberOfDoors[NumberOfDoors["FiveDoors"] = 7] = "FiveDoors";
    NumberOfDoors[NumberOfDoors["Coupe"] = 8] = "Coupe";
    NumberOfDoors[NumberOfDoors["StationWagon"] = 9] = "StationWagon";
    NumberOfDoors[NumberOfDoors["Van"] = 10] = "Van";
    NumberOfDoors[NumberOfDoors["Limousine"] = 11] = "Limousine";
    NumberOfDoors[NumberOfDoors["Recreational"] = 12] = "Recreational";
    NumberOfDoors[NumberOfDoors["SportsCar"] = 13] = "SportsCar";
    NumberOfDoors[NumberOfDoors["SUV"] = 14] = "SUV";
    NumberOfDoors[NumberOfDoors["Crossover"] = 15] = "Crossover";
    NumberOfDoors[NumberOfDoors["Convertible"] = 16] = "Convertible";
    NumberOfDoors[NumberOfDoors["Roadster"] = 17] = "Roadster";
    NumberOfDoors[NumberOfDoors["Special"] = 18] = "Special";
    NumberOfDoors[NumberOfDoors["AllTerrain"] = 19] = "AllTerrain";
    NumberOfDoors[NumberOfDoors["MotorHome"] = 20] = "MotorHome";
    NumberOfDoors[NumberOfDoors["Monospace"] = 21] = "Monospace";
    NumberOfDoors[NumberOfDoors["PickUpRegular"] = 22] = "PickUpRegular";
    NumberOfDoors[NumberOfDoors["PickupExtended"] = 23] = "PickupExtended";
    NumberOfDoors[NumberOfDoors["Truck"] = 24] = "Truck";
    NumberOfDoors[NumberOfDoors["TwoWheelsVehicle"] = 25] = "TwoWheelsVehicle";
    NumberOfDoors[NumberOfDoors["SpecialOfferCar"] = 26] = "SpecialOfferCar";
})(NumberOfDoors || (NumberOfDoors = {}));
export const CAR_CLASSES_IMGS = [
    'Mini',
    'Van',
    'SUV',
    'Standard',
    'Premium',
    'Oversize',
    'Luxury',
    'Intermediate',
    'ExtendedCabPickup',
    'Economy',
    'EconomyElite',
    'Compact',
];
export const CAR_TYPES = [
    'Mini',
    'Mini Elite',
    'Economy',
    'Economy Elite',
    'Compact',
    'Compact Elite',
    'Intermediate',
    'Intermediate Elite',
    'Standard',
    'Standard Elite',
    'Fullsize',
    'Fullsize Elite',
    'Premium',
    'Premium Elite',
    'Luxury',
    'Luxury Elite',
    'Oversize',
    'Special',
    'Kleinstwagen',
    'Kleinwagen',
    'UntereMittelklasse',
];
export var AgencyIdNames;
(function (AgencyIdNames) {
    AgencyIdNames["AC"] = "Ace";
    AgencyIdNames["AD"] = "Advantage";
    AgencyIdNames["AF"] = "Americar";
    AgencyIdNames["AI"] = "Affinity";
    AgencyIdNames["AL"] = "Alamo";
    AgencyIdNames["CC"] = "County Car";
    AgencyIdNames["CP"] = "Capital";
    AgencyIdNames["CY"] = "City Rent";
    AgencyIdNames["DS"] = "Discount";
    AgencyIdNames["EC"] = "ECR";
    AgencyIdNames["EP"] = "Europcar";
    AgencyIdNames["EZ"] = "EZ Rent";
    AgencyIdNames["FX"] = "Fox Rent";
    AgencyIdNames["HO"] = "Holiday";
    AgencyIdNames["IA"] = "Interamerican";
    AgencyIdNames["LC"] = "Luxury";
    AgencyIdNames["MU"] = "Mudan Auto";
    AgencyIdNames["MW"] = "Midway";
    AgencyIdNames["NF"] = "New Frontier";
    AgencyIdNames["Pi"] = "Preferred";
    AgencyIdNames["RB"] = "Red/Blue";
    AgencyIdNames["RR"] = "RR Affil";
    AgencyIdNames["RS"] = "Resort";
    AgencyIdNames["RY"] = "Royal RAC";
    AgencyIdNames["SV"] = "U-Save";
    AgencyIdNames["SW"] = "Southwest";
    AgencyIdNames["SX"] = "Sixt GMBH";
    AgencyIdNames["TS"] = "ITS";
    AgencyIdNames["VR"] = "Speciality";
    AgencyIdNames["XP"] = "Onerez";
    AgencyIdNames["ZA"] = "Payless";
    AgencyIdNames["ZD"] = "Budget";
    AgencyIdNames["ZE"] = "Hertz";
    AgencyIdNames["ZI"] = "Avis";
    AgencyIdNames["ZL"] = "National";
    AgencyIdNames["OF"] = "Ofran";
    AgencyIdNames["ZS"] = "Sears";
    AgencyIdNames["ZT"] = "Thrifty";
    AgencyIdNames["ZU"] = "Auto Europe";
    AgencyIdNames["ZW"] = "Wards";
    AgencyIdNames["ZR"] = "Dollar";
    AgencyIdNames["RO"] = "Routes";
    AgencyIdNames["ET"] = "Enterprise";
    AgencyIdNames["ES"] = "EASIRENT";
    AgencyIdNames["FF"] = "FireFly";
    AgencyIdNames["BV"] = "Bidvest";
    AgencyIdNames["FR"] = "First Car";
    AgencyIdNames["MG"] = "Maggiore";
    AgencyIdNames["MX"] = "Mex Car";
    AgencyIdNames["NC"] = "New Car";
    AgencyIdNames["PR"] = "Priceless";
    AgencyIdNames["RW"] = "Rent a Wreck";
    AgencyIdNames["RM"] = "Rent Max";
    AgencyIdNames["DE"] = "Tempest";
    AgencyIdNames["WF"] = "Woodford";
    AgencyIdNames["IM"] = "Imperial";
    AgencyIdNames["SC"] = "Silver Car";
    AgencyIdNames["SUNNYCARS"] = "Sunny Cars";
    AgencyIdNames["NU"] = "NU Car Rentals - Various";
    AgencyIdNames["EM"] = "EUROMOBIL";
    AgencyIdNames["GM"] = "Green Motion";
    AgencyIdNames["GI"] = "Green Motion";
})(AgencyIdNames || (AgencyIdNames = {}));
