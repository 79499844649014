<template>
  <div v-if="isMobile" class="app-header">
    <div class="is-flex is-justify-content-space-between is-align-items-center">
      <p v-if="product">{{ $t('trip.searchFor') }} {{ $t(`products.${product}`) }}</p>
      <p v-else>{{ $t('trip.editSearch') }}</p>
      <button class="close-btn">
        <span class="material-symbols-outlined close-btn" @click="$emit('close')">close</span>
      </button>
    </div>
  </div>

  <div v-else class="trip-edit-search flex space-between align-center">
    <h3 v-if="product">{{ $t('trip.searchFor') }} {{ $t(`products.${product}`) }}</h3>
    <h3 v-else>{{ $t('trip.editSearch') }}</h3>
    <button>
      <span class="material-symbols-outlined close-btn" @click="$emit('close')">close</span>
    </button>
  </div>
  <section class="trip-edit-search is-flex is-flex-direction-column">
    <div class="search-container">
      <div class="search-container__input-container">
        <component
          :is="`${product}-search-inputs`"
          :disableMultiPaxSelection="!canBookMultiPax"
          :externalSearchOptions="externalSearchOptions"
          @moveSearchControlToSecondStage="isDbRailSecondStage = true"
          @close="$emit('close')"
          @toggleGoToCartBtnLoading="toggleGoToCartBtnLoading"
          :ref="`${product}SearchInputs`"
          class="mb-5" />
      </div>

      <trip-search-control
        class="search-container__control"
        @search="handleSearch"
        :isCartBtnLoading="isDbRailAddToCartBtnLoading"
        :isAddToCartBtn="isDbRail && isDbRailSecondStage"
        @addToCart="handleAddToCart"
        v-bind="isDbRail && dbRailSearchButtonProps" />
    </div>
  </section>
</template>

<script>
// Search components must be named by the product name and end with "SearchInputs" as this is a dynamic component.
import { defineComponent, defineAsyncComponent } from 'vue';
import { Products } from '@/types/consts';
import { eventBus } from '@/services/event-bus.service';
import appLoader from '@/components/app-loader.vue';
import tripSearchControl from '@/components/trip/trip-search/trip-search-control/trip-search-control.vue';

// lazy loading of the search inputs components.
// having a loader for the time it takes to load them asynchronously

const heathrowExpressSearchInputs = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'heathrow-express' */ '@/modules/products/heathrow-express/components/heathrow-express-search-inputs/heathrow-express-search-inputs.vue'
    ),
  loadingComponent: appLoader,
  delay: 0,
});

const hotelSearchInputs = defineAsyncComponent({
  loader: () => import(/* webpackChunkName: 'hotels' */ '@/modules/products/hotels/components/hotel-search-inputs.vue'),
  loadingComponent: appLoader,
  delay: 0,
});

const groundServiceSearchInputs = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'ground-transport' */ '@/modules/products/ground-transport/components/groundService-search-inputs/groundService-search-inputs.vue'
    ),

  loadingComponent: appLoader,
  delay: 0,
});

const euRailSearchInputs = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'eu-rail' */ '@/modules/products/rails/eu-rail/components/eu-rail-search-inputs/eu-rail-search-inputs.vue'
    ),
  loadingComponent: appLoader,
  delay: 0,
});
const parkingSearchInputs = defineAsyncComponent({
  loader: () =>
    import(/* webpackChunkName: 'parking' */ '@/modules/products/parking/components/parking-search-inputs.vue'),
  loadingComponent: appLoader,
  delay: 0,
});
const flightSearchInputs = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'flight' */ '@/modules/products/flight/components/flight-search-inputs/flight-search-inputs.vue'
    ),
  loadingComponent: appLoader,
  delay: 0,
});
const carSearchInputs = defineAsyncComponent({
  loader: () =>
    import(/* webpackChunkName: 'car' */ '@/modules/products/car/components/car-search-inputs/car-search-inputs.vue'),
  loadingComponent: appLoader,
  delay: 0,
});
const evolviSearchInputs = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'heathrow-express' */ '@/modules/products/rails/uk-rail/components/search/evolvi-search-inputs/evolvi-search-inputs.vue'
    ),
  loadingComponent: appLoader,
  delay: 0,
});
const amtrakSearchInputs = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'amtrak' */ '@/modules/products/rails/amtrak/components/amtrak-search/amtrak-search-inputs/amtrak-search-inputs.vue'
    ),
  loadingComponent: appLoader,
  delay: 0,
});
const dbRailSearchInputs = defineAsyncComponent({
  loader: () =>
    import(
      /* webpackChunkName: 'dbRail' */ '@/modules/products/rails/db-rail/components/db-rail-search-inputs/db-rail-search-inputs.vue'
    ),
  loadingComponent: appLoader,
  delay: 0,
});

export default defineComponent({
  name: 'TripEditSearch',
  components: {
    hotelSearchInputs,
    groundServiceSearchInputs,
    parkingSearchInputs,
    flightSearchInputs,
    carSearchInputs,
    heathrowExpressSearchInputs,
    evolviSearchInputs,
    tripSearchControl,
    euRailSearchInputs,
    amtrakSearchInputs,
    'DBrail-search-inputs': dbRailSearchInputs, // this component is registered under a different name because db-rail-search-inputs does not correspond with the name 'dbrail-search-inputs' this component requires.
  },

  props: {
    product: {
      type: String,
    },
    externalSearchOptions: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      isDbRailSecondStage: false,
      isDbRailAddToCartBtnLoading: false,
    };
  },
  methods: {
    handleSearch() {
      const product = this.product;
      const isValid = this.$refs[`${product}SearchInputs`].isValid();
      if (!isValid) {
        return;
      }
      // in case in share - cancel the share - mb add a warning in the future
      this.$store.commit({ type: 'tripStore/setShareMode', isShareMode: false });
      const cbFunc = this.$refs[`${product}SearchInputs`].search;
      cbFunc();
    },
    handleAddToCart() {
      if (this.isDbRail) {
        const cbFunc = this.$refs[`${this.product}SearchInputs`].continueWithBooking;
        cbFunc();
      }
    },
    toggleGoToCartBtnLoading(isButtonLoading) {
      this.isDbRailAddToCartBtnLoading = isButtonLoading;
      if (!isButtonLoading) {
        this.$emit('close');
        eventBus.$emit('reloadQuotes');
      }
    },
  },

  computed: {
    isMobile() {
      return this.$store.getters.isMobile;
    },
    loggedinUser() {
      return this.$store.getters['authStore/loggedinUser'];
    },
    canBookMultiPax() {
      return !this.loggedinUser.permissions.includes('DisableBookForMultiPax');
    },
    products() {
      return Products;
    },
    isDbRail() {
      //TODO: we should remove the DBrail logic from this general component
      return this.product === Products.DBrail;
    },
    dbRailSearchButtonProps() {
      return this.isDbRailSecondStage
        ? { buttonText: this.$t('DBrail.newBooking') }
        : { buttonText: this.$t('btn.continue') };
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@/assets/style/abstracts';

.trip-edit-search {
  max-width: 1248px;
  max-height: 100dvh;
  margin: auto;
  padding: 1rem rem(40px) rem(40px);

  @include for-mobile-layout {
    padding: 0;
  }

  gap: rem(24px);

  .material-symbols-outlined.close-btn {
    font-size: rem(24px);
  }

  .search-container {
    width: 100%;
  }

  .search-container__input-container {
    @include for-mobile-layout {
      //padding: 0 rem(8px);
    }
  }

  .search-container__control {
    position: sticky;
    bottom: 0;
  }

  :deep .el-date-editor {
    display: flex;

    .el-range__close-icon {
      display: none;
    }

    .el-range-input {
      flex: 1;
      font-size: rem(14px) !important;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/style/abstracts';

.atrs-edit-search-drawer {
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
  height: fit-content !important;

  @include for-mobile-layout {
    height: unset !important;
  }

  .el-drawer__body {
    overflow: visible;
    padding: 0;

    @include for-tablet-layout {
      overflow: scroll;
    }

    @include for-mobile-layout {
      overflow: hidden;
      overflow-y: auto;
    }
  }

  .el-drawer__header {
    display: none !important;
  }
}
</style>
