<template>
  <section>
    <div v-if="hasTextSlot" class="app-msg app-msg-text is-flex is-align-items-center" :class="customClass">
      <span class="material-symbols-outlined mr-2">{{ iconName }}</span>
      <p class="app-msg-text pt-1" :class="{ 'is-bold': isBold }">
        <slot name="text" />
      </p>
    </div>
    <div v-else-if="isWithLink" class="app-msg is-flex is-align-items-center" :class="customClass">
      <span class="material-symbols-outlined mr-2">{{ iconName }}</span>
      <p class="app-msg-text" :class="{ 'is-bold': isBold }">
        <span v-if="prefix">{{ prefix }}</span>
        <a class="app-msg-link mx-1" :class="{ 'is-bold': isBold }" :href="linkURL" target="_blank">{{
          textForLink
        }}</a>
        <span v-if="suffix">{{ suffix }}</span>
      </p>
    </div>
    <div v-else class="app-msg is-flex is-align-items-center" :class="customClass">
      <span class="material-symbols-outlined mr-2">{{ iconName }}</span>
      <p class="app-msg-text" :class="{ 'is-bold': isBold }">{{ text }}</p>
    </div>
  </section>
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppMsg',
  props: {
    text: {
      type: String,
      default: '',
    },
    prefix: {
      type: String,
      default: '',
    },
    textForLink: {
      type: String,
      default: '',
    },
    suffix: {
      type: String,
      default: '',
    },
    linkURL: {
      type: String,
      default: '',
    },
    isWithLink: {
      type: Boolean,
      default: false,
    },
    isBold: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    iconName: {
      type: String,
      default: 'info',
    },
  },
  computed: {
    hasTextSlot() {
      return !!this.$slots['text'];
    },
  },
});
</script>

<style lang="scss" scoped>
@import '/src/assets/style/abstracts';

.app-msg {
  padding: rem(12px) 1rem;
  background: $light-blue;
  border-radius: 1rem;

  .material-symbols-outlined {
    color: $blue-1100;
  }

  .app-msg-text,
  .app-msg-link {
    word-break: break-word;
    font-size: rem(14px);

    &.is-bold {
      font-weight: 600;
    }
  }

  .app-msg-link {
    color: $blue-1100;
  }

  &.info-theme {
    background: $orange-800;

    .material-symbols-outlined {
      color: $orange-1000;
    }
  }
}
</style>
