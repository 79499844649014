import { defineComponent } from 'vue';
import sample from 'lodash/sample';
import { Products } from '@/types/consts';
import { utilService } from '@/services/util.service';
import { config } from '@/config/config';
export default defineComponent({
    name: 'AppProductLoader',
    props: {
        product: {
            type: String,
            required: true,
        },
        isDefaultSentences: {
            type: Boolean,
            default: false,
        },
        icons: {
            type: Array,
        },
        hideProgressBar: {
            type: Boolean,
            default: false,
        },
        isShowingSkeleton: {
            type: Boolean,
            default: false,
        },
        useCustomResults: {
            type: Boolean,
            default: false,
        },
        customSentences: {
            type: Array,
            default: () => [],
        },
        hasResults: { default: false },
    },
    data() {
        return {
            completionPercentage: 0,
            iconArray: ['luggage', 'flight', 'bed', 'directions_car', 'directions_subway', 'beach_access'],
            currSentenceIdx: 0,
            sentences: [],
            progressInterval: null,
            sentencesInterval: null,
            animationInterval: null,
            isLeaving: false,
            isEntering: false,
            currIconIdx: 0,
            nextIconIdx: 1,
            loaderDelay: 10000,
            isChristmasMode: config.miscellaneous.isChristmasMode,
            loadingMessage: {},
        };
    },
    created() {
        setTimeout(() => {
            if (!this.hideProgressBar) {
                this.progressLoaderBar();
            }
        }, 250);
        this.initSentences();
        if (this.icons) {
            this.iconArray = this.icons;
        }
        this.updateSentence(this.loaderDelay);
        this.startAnimationCycle();
        setTimeout(() => {
            this.$emit('showSkeleton');
        }, this.loaderDelay);
        this.setLoadingMessage();
    },
    methods: {
        setLoadingMessage() {
            this.loadingMessage = sample(this.loadingMessages);
        },
        startAnimationCycle() {
            this.animationInterval = setInterval(() => {
                // Update currIconIdx and nextIconIdx
                this.currIconIdx = (this.currIconIdx + 1) % this.iconArray.length;
                this.nextIconIdx = (this.currIconIdx + 1) % this.iconArray.length;
                // Trigger exit and enter animations simultaneously
                this.animate();
            }, 1500);
        },
        animate() {
            this.$nextTick(() => {
                const currentIcon = this.$el.querySelector('.current');
                const nextIcon = this.$el.querySelector('.next');
                currentIcon.classList.add('exit');
                nextIcon.classList.add('enter');
                setTimeout(() => {
                    currentIcon.classList.remove('exit');
                    nextIcon.classList.remove('enter');
                }, 500);
            });
        },
        initSentences() {
            if (this.customSentences && this.customSentences.length > 0) {
                this.sentences = this.customSentences;
            }
            else {
                //Get the current dictionary
                const dictionary = this.$i18n.getLocaleMessage(this.$i18n.locale);
                const sentencesPath = `loader.sentences.${this.isDefaultSentences ? this.product : 'default'}`;
                //Get part of the dictionary by the 'sentencesPath'
                const sentencesObject = utilService.getObjectByPath(dictionary, sentencesPath);
                //return only array of values
                this.sentences = Object.values(sentencesObject);
            }
        },
        updateSentence(duration) {
            this.sentencesInterval = setInterval(() => {
                if (this.currSentenceIdx < this.sentences.length) {
                    this.isLeaving = true;
                    setTimeout(() => {
                        this.currSentenceIdx = this.currSentenceIdx + 1;
                        this.isLeaving = false;
                        this.isEntering = true;
                        setTimeout(() => {
                            this.isEntering = false;
                        }, 500);
                    }, 500);
                }
                else {
                    clearInterval(this.sentencesInterval);
                }
            }, duration);
        },
        progressLoaderBar() {
            const randomSeconds = Math.random() * 6 + 2;
            const randomAddition = Math.random() * 2 + 5;
            if (this.completionPercentage >= 85) {
                return;
            }
            const updateProgress = () => {
                this.completionPercentage = Math.min(85, this.completionPercentage + randomAddition);
                if (this.completionPercentage >= 85) {
                    clearInterval(this.progressInterval);
                    this.progressInterval = null;
                }
            };
            updateProgress();
            this.progressInterval = setInterval(updateProgress, randomSeconds * 1000);
        },
        initLoader() {
            this.completionPercentage = 0;
            this.currSentenceIdx = 0;
            this.sentences = [];
            this.currIconIdx = 0;
            this.nextIconIdx = 1;
            this.progressInterval = null;
            this.sentencesInterval = null;
            this.animationInterval = null;
        },
    },
    computed: {
        loadingMessages() {
            return this.$store.getters['authStore/loadingMessages']?.filter((message) => message.active && (this.isMobile ? message.mobileImage : message.image));
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        isShowingLoadingMessage() {
            return this.completionPercentage < 100 && !this.isShowingSkeleton;
        },
        resultsProperty() {
            let res = null;
            if (this.useCustomResults) {
                return this.hasResults;
            }
            switch (this.product) {
                case Products.Flight:
                    res = this.$store.getters['flightStore/flightsRes'];
                    break;
                case Products.Hotel:
                    res = this.$store.getters['hotelStore/hotels'];
                    break;
                case Products.Car:
                    res = this.$store.getters['carStore/carRes'];
                    break;
                case Products.GroundService:
                    res = this.$store.getters['groundTransportStore/transports'];
                    break;
                case Products.Parking:
                    res = this.$store.getters['parkingStore/parkingRes'];
                    break;
                case Products.Amtrak:
                    res = this.$store.getters['amtrakStore/amtrakAnswer'];
                    break;
                case Products.Evolvi:
                    res = this.$store.getters['ukRailStore/trainsInfo'];
                    break;
                case Products.Benerail:
                    res = this.$store.getters['euRailStore/railsAnswer'];
                    break;
            }
            return res;
        },
    },
    watch: {
        resultsProperty: {
            handler() {
                clearInterval(this.progressInterval);
                clearInterval(this.sentencesInterval);
                clearInterval(this.animationInterval);
                this.completionPercentage = 100;
                setTimeout(() => {
                    this.$emit('stopLoading');
                    this.initLoader();
                }, 500);
            },
            deep: true,
        },
    },
    unmounted() {
        clearInterval(this.progressInterval);
        clearInterval(this.sentencesInterval);
        clearInterval(this.animationInterval);
    },
});
