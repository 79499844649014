import { defineComponent } from 'vue';
import { eventBus } from '@/services/event-bus.service';
import { loggerService } from '@/services/logger.service';
import { RoutesNames } from '@/router';
// Components
import personalDetailsForm from '@/components/user-profile/personal-details-form/personal-details-form.vue';
import contactInfoForm from '@/components/user-profile/contact-info-form/contact-info-form.vue';
import passportVisaForm from '@/components/user-profile/passport-visa-form/passport-visa-form.vue';
import travelPreferencesForm from '@/components/user-profile/travel-preferences-form/travel-preferences-form.vue';
import frequentProgramsForm from '@/components/user-profile/frequent-programs-form/frequent-programs-form.vue';
import profileFormOfPayment from '@/components/user-profile/profile-form-of-payment/profile-form-of-payment.vue';
import appPageLayout from '../layout/app-page-layout/app-page-layout.vue';
export default defineComponent({
    name: 'UserProfile',
    components: {
        personalDetailsForm,
        contactInfoForm,
        passportVisaForm,
        travelPreferencesForm,
        frequentProgramsForm,
        profileFormOfPayment,
        appPageLayout,
    },
    props: {
        isUserProfileOpen: {
            type: Boolean,
            default: true,
        },
        isReloadProfile: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            isLoading: false,
            activeTab: 'personalDetails',
            tripId: null,
        };
    },
    async created() {
        this.tripId = +this.$route.query.tripId || +this.$route.params.tripId;
        await this.loadData();
        if (this.userProfile?.mandateFields.length) {
            // If the user has mandate fields (from administration) send him to the first tab these fields are missing.
            this.setActiveTabWithFirstMandateField();
        }
    },
    methods: {
        async loadData() {
            this.isLoading = true;
            if (!this.trip && this.tripId) {
                await this.$store.dispatch({ type: 'tripStore/loadTrip', tripId: this.tripId });
            }
            if (this.isReloadProfile) {
                const { userId } = this.$route.params;
                await this.$store.dispatch('userStore/loadUserProfile', { userId, tripId: this.tripId });
            }
            if (!this.nationalities) {
                await this.$store.dispatch('getNationalities');
            }
            if (!this.airplaneCarriers) {
                await this.$store.dispatch('getCarriers');
            }
            if (!this.flightMealTypes) {
                await this.$store.dispatch('getFlightMealTypes');
            }
            if (!this.hotelChains) {
                await this.$store.dispatch('hotelStore/loadHotelChains');
            }
            if (!this.hotelLoyaltyCodes) {
                await this.$store.dispatch('hotelStore/loadHotelLoyaltyCodes');
            }
            this.isLoading = false;
        },
        setActiveTab(tabName) {
            if (!tabName) {
                return;
            }
            this.activeTab = tabName;
        },
        setActiveTabWithFirstMandateField() {
            const activeMandateFieldsTabs = this.getActiveMandateFieldsTabs();
            // sort the activeMandateFieldsTabs by the order of the tabs as they are displayed in the UI(same order as in the dataTabMap Object)
            const sortedActiveMandateFieldsTabs = activeMandateFieldsTabs.sort((a, b) => {
                return Object.keys(this.dataTabMap).indexOf(a) - Object.keys(this.dataTabMap).indexOf(b);
            });
            const firstMandateTabName = this.dataTabMap[sortedActiveMandateFieldsTabs[0]];
            this.setActiveTab(firstMandateTabName);
        },
        async checkMandateFieldsStatus() {
            await this.$store.dispatch('userStore/loadUserProfile', {
                userId: this.userProfile.userPersonalDetails.id,
                tripId: this.tripId,
            });
            // If there are more mandate fields to fill - send the user to the relevant tab
            if (this.mandateFieldNames.length) {
                this.setActiveTabWithFirstMandateField();
                return;
            }
            if (!this.isUserProfilePage) {
                this.isLoading = true;
                try {
                    await this.$store.dispatch({ type: 'tripStore/addTraveler', passengerInfo: this.tempPassengerInfo });
                    this.$emit('closeUserProfileDialog');
                    eventBus.$emit('reloadFormOfPayment');
                    eventBus.$emit('validateRequiredInfo');
                    this.$store.commit({ type: 'userStore/setUserProfile', userProfileData: null });
                }
                catch (err) {
                    loggerService.error(err);
                }
                finally {
                    this.isLoading = false;
                }
            }
        },
        onGoBack() {
            this.$router.go(-1);
        },
        getActiveMandateFieldsTabs() {
            // Making an array of the tabs that has mandate fields to fill. In order to send the user directly to the relevant tab.
            const mandateFieldTabs = [
                ...new Set(this.mandateFieldNames.reduce((acc, mandateFieldName) => {
                    for (const formMandateFields in this.mandateFieldsOptionsMap) {
                        if (this.mandateFieldsOptionsMap[formMandateFields].includes(mandateFieldName)) {
                            acc.push(formMandateFields);
                        }
                    }
                    return acc;
                }, [])),
            ];
            const tabsByOrder = Object.keys(this.mandateFieldsOptionsMap);
            const orderedTabs = mandateFieldTabs.sort((a, b) => tabsByOrder.indexOf(a) - tabsByOrder.indexOf(b));
            return orderedTabs;
        },
    },
    computed: {
        trip() {
            return this.$store.getters['tripStore/trip'];
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        loggedinUser() {
            return this.$store.getters['authStore/loggedinUser'];
        },
        nationalities() {
            return this.$store.getters.nationalities;
        },
        airplaneCarriers() {
            return this.$store.getters.carriers;
        },
        flightMealTypes() {
            return this.$store.getters.flightMealTypes;
        },
        hotelLoyaltyCodes() {
            return this.$store.getters['hotelStore/hotelLoyaltyCodes'];
        },
        hotelChains() {
            return this.$store.getters['hotelStore/hotelChains'];
        },
        userProfile() {
            return this.$store.getters['userStore/userProfile'];
        },
        isUserCantEditProfile() {
            const { userId } = this.$route.params;
            const isPreventedFromEdit = this.loggedinUser.permissions.includes('PreventUpdatingOthersAndOwnProfile');
            // always allow user to edit OWN profile unless they are prevented
            if (userId === this.loggedinUser.id + '' && !isPreventedFromEdit) {
                return false;
            }
            return isPreventedFromEdit || (userId && !this.loggedinUser.permissions.includes('CanEditAndViewProfile'));
        },
        isUserHasEncryptedFields() {
            // By the Definition if user have the 'CanViewProfile' permission - he can't see the BirthDate and the Passport Number of other users.
            // But if he has the 'CanEditAndViewProfile' permission - he can see it and edit.
            const { userId } = this.$route.params;
            const hasEncryptedFields = this.loggedinUser.permissions.includes('CanViewProfile') && !this.loggedinUser.permissions.includes('CanEditAndViewProfile');
            if (userId === this.loggedinUser.id + '' ||
                this.tempPassengerInfo?.passenger?.CorporateUserId === this.loggedinUser.id) {
                return false;
            }
            return hasEncryptedFields;
        },
        mandateFieldNames() {
            return this.userProfile?.mandateFields.map((field) => field.fieldName);
        },
        tempPassengerInfo() {
            return this.$store.getters['userStore/tempPassengerInfo'];
        },
        dataTabMap() {
            return {
                userPersonalDetails: 'personalDetails',
                userContactInfo: 'contactInformation',
                frequentProgramInfo: 'frequentPrograms',
                userPassportInfo: 'passportsAndVisas',
                travelPreferences: 'travelPreferences',
                formOfPayment: 'formOfPayment',
            };
        },
        isUserProfilePage() {
            return this.$route.name === RoutesNames.userApp;
        },
        mandateFieldsOptionsMap() {
            return {
                userPersonalDetails: ['Date Of Birth', 'Gender', 'Title'],
                userContactInfo: [
                    'Mobile',
                    'Business Phone',
                    'Fax',
                    'Home Phone',
                    'Emergency Contact Name',
                    'Emergency Contact E-mail',
                    'Emergency Contact Mobile',
                    'Personal Address Address',
                    'Personal Address City',
                    'Personal Address Zip code',
                    'Personal Address Country',
                ],
                userPassportInfo: ['Passport Details'],
                travelPreferences: [
                    'Preferred Airline Carrier',
                    'Non Preferred Airline Carrier',
                    'Class',
                    'Meal Type',
                    'Home Airport',
                    'Additional Flight Information',
                    'Additional Seat Information',
                    'Preferred Hotel Vendor',
                    'Non Preferred Hotel Vendor',
                    'Additional Hotel Information',
                    'Hotel Bed Type',
                    'Hotel Room Type',
                    'Preferred Car Vendor',
                    'Non Preferred Car Vendor',
                    'Car Type',
                    'Car Transmission',
                ],
                formOfPayment: ['Form Of Payment Details'],
            };
        },
    },
    watch: {
        userProfile(newProfile) {
            if (newProfile?.mandateFields.length) {
                // If the user has mandate fields (from administration) send him to the first tab these fields are missing.
                this.setActiveTabWithFirstMandateField();
            }
        },
    },
});
