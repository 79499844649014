import * as flightConsts from '@/modules/products/flight/models/consts';
import { utilService } from '@/services/util.service';
import { flightService } from '../../services/flight.service';
import { defineComponent } from 'vue';
import flightmixins from '@/modules/products/flight/mixins/flight.mixins';
import appTextSearch from '@/components/form/app-text-search.vue';
import appPriceSlider from '@/components/form/app-price-slider.vue';
import appTimeSlider from '@/components/form/app-time-slider.vue';
import appCheckboxGroup from '@/components/form/app-checkbox-group/app-checkbox-group.vue';
import flightListSort from '@/modules/products/flight/components/flight-list-sort/flight-list-sort.vue';
export default defineComponent({
    mixins: [flightmixins],
    components: {
        appTextSearch,
        appPriceSlider,
        appCheckboxGroup,
        appTimeSlider,
        flightListSort,
    },
    props: {
        initialFilter: {
            type: Object,
        },
        isMultiCarrier: {
            type: Boolean,
            default: false,
        },
        userPreferences: {
            type: Object,
        },
        flightResults: {
            type: Array,
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        isForMobileFilter: {
            type: Boolean,
            default: false,
        },
        resultsIndex: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            filterBy: {},
            sortOptions: [],
            checkboxFields: {},
            currentTabIndex: '0',
            isShowingAllOptions: false,
            isShowingLayoverDuration: false,
            isShowingAllAirports: {},
        };
    },
    created() {
        this.sortOptions = flightService.getSortOptions(this.isMobile);
        this.filterBy = utilService.deepClone(this.initialFilter);
        this.checkboxFields = flightService.getFlightFilterCheckboxes();
        this.isShowingAllOptions =
            Object.keys(this.filterBy.durationRange).length > this.durationRangeDisplayCount ? false : true;
        this.isShowingLayoverDuration = Object.keys(this.filterBy.stopsDurationRange).length > 0 ? false : true;
        this.initializeToggleState();
    },
    methods: {
        initializeToggleState() {
            if (this.filterBy?.availableAirports) {
                const segmentKeys = Object.keys(this.filterBy.availableAirports);
                this.isShowingAllAirports = segmentKeys.reduce((acc, key) => {
                    acc[key] = +key === 0; // Set the first segment toggle to true, others to false
                    return acc;
                }, {});
            }
        },
        async filter() {
            if (!this.isMobile) {
                this.$emit('filterFlights', this.filterBy);
            }
        },
        handleApplyFilter() {
            this.$emit('filterFlights', this.filterBy);
            this.$emit('close');
        },
        setSortBy(sortBy) {
            this.filterBy.sortBy = sortBy;
        },
        getAirportsName(key, val) {
            //remove any 'APT' in the airport name
            const cleanedVal = val.replace(/\bAPT\b/g, '').trim();
            return `${key} - ${cleanedVal}`;
        },
        async handleClear() {
            if (!this.isMobile) {
                this.$emit('clear');
            }
            else {
                this.filterBy = await this.$store.dispatch({ type: 'flightStore/recalculateFilters', applyFilter: false });
            }
        },
        advisoriesField(cityName) {
            const cityAdvisories = this.filterBy.availableAdvisories[cityName];
            const advisoriesField = {
                key: 'advisories',
                transKey: 'flight.Advisories',
                txt: 'advisory',
                items: cityAdvisories.reduce((acc, advisories) => {
                    const advisoryDisplayName = flightConsts.FlightAdvisoriesDisplayNames[advisories];
                    acc[advisoryDisplayName] = advisoryDisplayName;
                    return acc;
                }, {}),
                icons: cityAdvisories.reduce((acc, data) => {
                    acc[flightConsts.FlightAdvisoriesDisplayNames[data]] = flightConsts.FlightAdvisoriesIconNames[data];
                    return acc;
                }, {}),
            };
            return [advisoriesField];
        },
        airportsField(segmentIndex, cityName) {
            const cityAirports = this.filterBy.availableAirports[segmentIndex][cityName];
            const flightsRes = this.$store.getters['flightStore/flightsRes'];
            const airportsField = {
                key: 'airports',
                txt: 'airports',
                items: cityAirports.reduce((acc, airport) => {
                    let airportName = airport;
                    if (flightsRes && flightsRes.length > 0 && flightsRes[0].cityAirportNameDictionary) {
                        airportName = flightsRes[0].cityAirportNameDictionary[airport.toLowerCase()] || airport;
                    }
                    acc[airport] = airportName;
                    return acc;
                }, {}),
            };
            return [airportsField];
        },
    },
    computed: {
        durationRangeDisplayCount() {
            return flightConsts.FLIGHT_DURATION_FILTER_DISPLAY_COUNT;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
        resultMap() {
            return this.$store.getters['flightStore/resultMap'];
        },
        carriersField() {
            const items = this.filterBy.availableCarriers.reduce((acc, data) => {
                acc[data] = data;
                return acc;
            }, this.isMultiCarrier ? { 'Multi carrier': this.$t('flight.multiCarrier') } : {});
            const sortedItems = Object.keys(items)
                .sort()
                .reduce((result, key) => {
                result[key] = items[key];
                return result;
            }, {});
            const carriersField = {
                key: 'carriers',
                txt: 'preferredAirlines',
                items: sortedItems,
            };
            return [carriersField];
        },
        agreementTypeField() {
            const agreementTypeField = {
                key: 'agreementTypes',
                txt: 'agreementType',
                transKey: 'flight.AgreementType',
                items: this.filterBy.availableAgreementTypes.reduce((acc, data) => {
                    acc[data] = data;
                    return acc;
                }, {}),
                icons: this.filterBy.availableAgreementTypes.reduce((acc, data) => {
                    acc[data] = flightConsts.AgreementTypeIconNames[data];
                    return acc;
                }, {}),
            };
            return [agreementTypeField];
        },
    },
    watch: {
        initialFilter: {
            deep: true,
            handler(newVal) {
                this.filterBy = utilService.deepClone(newVal);
            },
        },
    },
});
