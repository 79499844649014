import { authService } from '@/services/auth.service';
import { RoutesNames } from './router';
import { userService } from '@/services/user.service';
import store from '@/store/store';
import { config } from './config/config';
export const authenticationGuard = async (to, from, next) => {
    if (to.meta.isAnonymous) {
        next(); //Anonymous will continue anyway
    }
    else {
        //check if user LoggedIn
        let isLoggedIn = store.getters['authStore/isAuthValid'];
        if (!isLoggedIn) {
            isLoggedIn = await authService.isIDSLoggedIn();
        }
        if (!isLoggedIn) {
            //if LoggedIn=False -> logout
            sessionStorage.setItem('redirect_url', location.pathname + location.search);
            next({ name: RoutesNames.signin });
        }
        else {
            //if LoggedIn=true  -> check if it is corporate or not corporate but isAllowAgent=true
            try {
                const user = await userService.getLoggedinUser(false);
                if (!user.corporationId && to.meta.isAllowAgent) {
                    next();
                }
                else if (user.corporationId && !to.meta.isAllowAgent) {
                    next();
                }
                else {
                    next({ name: RoutesNames.signout });
                }
            }
            catch (error) {
                next({ name: RoutesNames.signout });
            }
        }
    }
};
export const removeProduction = async (to, from, next) => {
    if (config.env.isProd) {
        next({ name: RoutesNames.pageNotFound });
    }
    else {
        next();
    }
};
export const referenceFieldGuard = async (to, from, next) => {
    const tripId = to.params?.tripId;
    if (tripId && from.name === RoutesNames.tripCart) {
        next();
    }
    else if (!tripId) {
        next({ name: RoutesNames.myTrips });
    }
    else {
        //TODO: Probably better to return false
        next({ name: RoutesNames.tripCart, params: { tripId } });
    }
};
