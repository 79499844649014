import { initialSearchOptions } from '@/modules/products/ground-transport/store/ground-transport.store';
import { RoutesNames } from '@/router';
import { eventBus } from '@/services/event-bus.service';
import { googleAddressService } from '@/services/google-address.service';
import { UnitsSystem, Products } from '@/types/consts';
import { loggerService } from '@/services/logger.service';
import { tripService } from '@/services/trip.service';
import { utilService } from '@/services/util.service';
import { defineComponent } from 'vue';
import { required } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import addressAutocomplete from '@/components/address-autocomplete.vue';
import airportAutocomplete from '@/components/airport-autocomplete.vue';
import appSelect from '@/components/app-select.vue';
import appDatepicker from '@/components/form/app-datepicker.vue';
import formValidationMsg from '@/components/form/form-validation-msg.vue';
import tripTravelersList from '@/components/trip/trip-travelers-list/trip-travelers-list.vue';
import groundTransportGoogleMap from '@/modules/products/ground-transport/components/ground-transport-google-map.vue';
import groundTransportSearchSkelaton from '@/modules/products/ground-transport/components/ground-transport-search-skelaton.vue';
const DEFAULT_LOCATION = { lat: 51.504628, lng: -0.108218 };
export default defineComponent({
    name: 'GroundServiceSearchInputs',
    components: {
        groundTransportGoogleMap,
        airportAutocomplete,
        addressAutocomplete,
        appDatepicker,
        appSelect,
        groundTransportSearchSkelaton,
        formValidationMsg,
        tripTravelersList,
    },
    props: {
        showAllInputs: {
            type: Boolean,
            default: false,
        },
        externalSearchOptions: {
            type: Object,
            required: false,
        },
    },
    setup() {
        return { v$: useVuelidate() };
    },
    data() {
        return {
            markFields: false,
            isLoading: true,
            pickUpCountryCode: '',
            transportInfo: initialSearchOptions(),
            mapOptions: {
                center: DEFAULT_LOCATION,
            },
        };
    },
    async created() {
        // manually pass searchOptions
        if (this.externalSearchOptions) {
            this.transportInfo = utilService.deepClone(this.externalSearchOptions);
        }
        await this.loadData();
    },
    methods: {
        setNewPickUpMode() {
            this.transportInfo.pickupLoaction = {};
            this.removeMarker('from');
            if (this.transportInfo.pickupBy === 'airport') {
                this.updateTransportInfoDetails();
            }
        },
        setNewDropOffMode() {
            this.transportInfo.dropoffLoction = {};
            this.removeMarker('to');
        },
        async loadData() {
            this.isLoading = true;
            const { tripId } = this.$route.params;
            this.transportInfo.tripId = tripId;
            if (!this.googleSessionToken) {
                //If there is no global google session token, create one
                this.$store.commit({ type: 'setGoogleSessionToken', token: new google.maps.places.AutocompleteSessionToken() });
            }
            if (this.lastSearchOptions) {
                this.handleLastSearch();
            }
            try {
                await Promise.all([this.getCarriers(), this.getVehicleTypes(tripId)]);
                this.updateTransportInfoDetails();
            }
            catch (err) {
                loggerService.error(err);
            }
            finally {
                this.isLoading = false;
            }
        },
        async updateTransportInfoDetails() {
            if (this.lastSearchOptions) {
                return;
            }
            const suggestion = await tripService.getTripSearchSuggestions(this.transportInfo.tripId, Products.GroundService, 0);
            if (suggestion) {
                this.transportInfo.flightNumber = suggestion?.flightNumber;
                this.transportInfo.pickupDate = suggestion?.startDate?.split('T')[0];
                this.transportInfo.pickupTime = suggestion?.startTime;
                this.transportInfo.flightAirline = suggestion?.carrier;
                this.transportInfo.pickupLoaction = suggestion?.destination?.name;
            }
        },
        async setPickup(item) {
            // when we already have saved search options, like in cross-sell, the destination will be just a string
            // need to exchange it for a location object.
            if (typeof item === 'string') {
                const code = item.split('(')[1]?.split(')')[0].trim();
                item = utilService.deepClone(this.allAirports[code]);
            }
            // When item is picked, get his lat/lng and placeId and add the 'from' marker
            this.pickUpCountryCode = item.CountryCode;
            // equating item.isPOI specifically with true to prevent failure of the whole expression when item.isPOI is undefined.
            if (!item.lat || !item.lng || item.isPOI === true) {
                try {
                    item = await this.addLatLngAndPlaceId(item);
                }
                catch (err) {
                    loggerService.error(err);
                }
            }
            this.transportInfo.pickupLoaction = item;
            if (!this.pickUpCountryCode) {
                this.pickUpCountryCode = item.countryCode;
            }
            this.addMarker(item, 'from');
        },
        async getPlaceId(req) {
            try {
                const googleResponse = await googleAddressService.googleAutocompleteSearch(req);
                if (googleResponse?.length) {
                    return googleResponse[0].PlaceId;
                }
            }
            catch (err) {
                loggerService.error(err);
                return null;
            }
        },
        async addLatLngAndPlaceId(item) {
            if (item.isPOI) {
                //get palce id of point of intrest
                const req = {
                    input: item.Name,
                    sessionToken: this.googleSessionToken,
                    filterTypes: [],
                    location: null,
                    radius: null,
                };
                item.PlaceId = await this.getPlaceId(req);
                return item;
            }
            if (item.Code) {
                // get place id of airport
                const req = {
                    input: item.Name,
                    sessionToken: this.googleSessionToken,
                    filterTypes: ['airport'],
                    location: null,
                    radius: null,
                };
                item.PlaceId = await this.getPlaceId(req);
            }
            // get the details of the location based on place id (including lat/lng)
            const details = await googleAddressService.googlePlaceSearchByIdRichContent(item.PlaceId);
            if (details) {
                item.lat = details.geometry?.location.lat();
                item.lng = details.geometry?.location.lng();
                item.street = details.formatted_address;
                if (details.address_components) {
                    details.address_components.forEach((a) => {
                        if (a.types) {
                            a.types.forEach((b) => {
                                if (b === 'postal_code') {
                                    item.zip = a.long_name;
                                }
                                else if (b === 'country') {
                                    item.countryName = a.long_name;
                                    item.countryCode = a.short_name;
                                }
                                else if (b === 'locality') {
                                    item.cityName = a.long_name;
                                    item.countryCode = a.short_name;
                                }
                            });
                        }
                    });
                }
            }
            return item;
        },
        addMarker(location, desc) {
            // add merker to array
            const marker = {
                desc,
                txt: location.Name || location.description,
                lat: location.lat,
                lng: location.lng,
            };
            const idx = desc === 'from' ? 0 : 1;
            if (!this.transportInfo.markers) {
                this.transportInfo.markers = [];
            }
            this.transportInfo.markers[idx] = marker;
            this.mapOptions.center.lng = marker.lng;
            this.mapOptions.center.lat = marker.lat;
            //notify the ground-transport-map to show new markers
            eventBus.$emit('ground-transport-map-change');
        },
        removeMarker(desc) {
            //remove marker by desc ('from' or 'to')
            const idx = desc === 'from' ? 0 : 1;
            if (!this.transportInfo.markers[idx]) {
                return;
            }
            this.transportInfo.markers[idx] = null;
            const otherLocationIdx = idx === 0 ? 1 : 0;
            if (this.transportInfo.markers[otherLocationIdx]) {
                //change the center of the map to the remaining marker
                this.mapOptions.center.lng = this.transportInfo.markers[otherLocationIdx].lng;
                this.mapOptions.center.lat = this.transportInfo.markers[otherLocationIdx].lat;
            }
            else {
                this.mapOptions.center.lng = DEFAULT_LOCATION.lng;
                this.mapOptions.center.lat = DEFAULT_LOCATION.lat;
            }
            eventBus.$emit('ground-transport-map-change');
        },
        handleLastSearch() {
            // update the component's data with the last search that was made
            this.transportInfo.pickupBy = this.lastSearchOptions.pickupLoaction.description ? 'address' : 'airport';
            this.transportInfo.dropoffBy = this.lastSearchOptions.dropoffLoction.description ? 'address' : 'airport';
            this.transportInfo = utilService.deepClone(this.lastSearchOptions);
            eventBus.$emit('ground-transport-map-change');
        },
        autocompleteChangePickup(val) {
            if (this.transportInfo.pickupBy === 'airport') {
                if (val !== this.transportInfo.pickupLoaction.Name) {
                    //this.transportInfo.pickupLoaction = {};
                    this.removeMarker('from');
                }
            }
            else {
                if (val !== this.transportInfo.pickupLoaction.description) {
                    // this.transportInfo.pickupLoaction = {};
                    this.removeMarker('from');
                }
            }
        },
        async setDropoff(item) {
            // When item is picked, get his lat/lng and add the 'to' marker
            if (!item.lat || item.isPOI) {
                item = await this.addLatLngAndPlaceId(item);
            }
            this.transportInfo.dropoffLoction = item;
            this.addMarker(item, 'to');
        },
        autocompleteChangeDropoff(val) {
            if (this.transportInfo.dropoffBy === 'airport') {
                if (val !== this.transportInfo.dropoffLoction.Name) {
                    this.removeMarker('to');
                }
            }
            else {
                if (val !== this.transportInfo.dropoffLoction.description) {
                    this.removeMarker('to');
                }
            }
        },
        async getCarriers() {
            if (!this.carriresOptions.length) {
                await this.$store.dispatch('groundTransportStore/loadCarrires');
            }
        },
        async getVehicleTypes(tripId) {
            if (!this.vehicleTypeOptions.length) {
                await this.$store.dispatch({ type: 'groundTransportStore/loadVehicalTypes', tripId });
            }
        },
        handleSelected(field, value) {
            if (field === 'vehicleType') {
                const vehicleType = this.vehicleTypeOptions.find((v) => {
                    return v.Code === value;
                });
                this.transportInfo.travelersAmount = null;
                return (this.transportInfo[field] = vehicleType);
            }
            this.transportInfo[field] = value;
        },
        isValid() {
            this.markFields = true;
            const isValid = !this.v$.$invalid;
            if (!isValid) {
                return false;
            }
            return true;
        },
        search() {
            this.transportInfo.pickupDate = this.$filters?.date(this.transportInfo.pickupDate, 'yyyy-MM-dd');
            this.$store.commit('groundTransportStore/setGroundTransportSearchOptions', { searchOptions: this.transportInfo });
            this.$emit('close');
            if (this.$route.name === RoutesNames.groundTransportResults) {
                eventBus.$emit('reloadGroundTransportResults');
            }
            else {
                this.$router.push({ name: RoutesNames.groundTransportResults });
            }
        },
    },
    computed: {
        userSystemUnits() {
            return this.$store.getters['userStore/userUnitsSystem'];
        },
        allAirports() {
            return this.$store.getters['flightStore/allAirports'];
        },
        isUserUnitsMetric() {
            return this.userSystemUnits === UnitsSystem.Metric;
        },
        carriresOptionsToShow() {
            return this.carriresOptions.map((c) => {
                return {
                    value: c.code,
                    label: c.name,
                };
            });
        },
        vehicleTypesOptionsToShow() {
            return this.vehicleTypeOptions.map((vt) => {
                return {
                    value: vt.Code,
                    label: vt.Code,
                };
            });
        },
        travelersAmountOptionsToShow() {
            if (!this.transportInfo.vehicleType.MaxNoOfPassengers) {
                return [];
            }
            const options = [];
            for (let i = 1; i <= this.transportInfo.vehicleType.MaxNoOfPassengers; i++) {
                options.push({ value: i, label: i });
            }
            return options;
        },
        numOfLuggage() {
            const options = [];
            for (let i = 0; i <= 20; i++) {
                options.push({ value: i, label: i });
            }
            return options;
        },
        lastSearchOptions() {
            return this.$store.getters['groundTransportStore/searchOptions'];
        },
        carriresOptions() {
            return this.$store.getters['groundTransportStore/carrires'];
        },
        vehicleTypeOptions() {
            return this.$store.getters['groundTransportStore/vehicalTypes'];
        },
        googleSessionToken() {
            return this.$store.getters.googleSessionToken;
        },
        isMobile() {
            return this.$store.getters.isMobile;
        },
    },
    watch: {
        // this is used to enforce the pickUp map marker when navigating to this page from cross-sell.
        'transportInfo.pickupLoaction'(newVal, oldVal) {
            if (newVal && newVal !== oldVal) {
                this.setPickup(newVal);
            }
        },
    },
    validations() {
        return {
            transportInfo: {
                pickupLoaction: {
                    required,
                },
                dropoffLoction: {
                    required,
                },
                pickupDate: {
                    required,
                },
                pickupTime: {
                    required,
                },
                vehicleType: {
                    required,
                },
                travelersAmount: {
                    required,
                },
            },
        };
    },
});
