import { httpService } from '@/services/http.service';
export const ukRailService = {
    getRailCards,
    getJournyDetails,
    ukRailAutocompleteSearch,
    getTrainsInfo,
    selectTickets,
    getResultsFareRules,
    getQuoteFareRules,
    getDeliveryMethod,
    getNonLowestReason,
    getSeats,
    getAccounts,
    saveDeliveryMethod,
    saveNonLowestReason,
    saveAccounts,
    saveClientReference,
    getClientReference,
    saveSeats,
    getSplitMax,
    setSplitMax,
};
// GET
async function ukRailAutocompleteSearch(queryString) {
    return httpService.get(`/EvolviAutocomplete?term=${queryString}`, null);
}
async function getRailCards() {
    return httpService.get('/EvolviRailsCards', null);
}
async function getJournyDetails(tripId, searchCacheKey, trainCacheKey) {
    return httpService.get(`/EvolviJorneyDetails?tripId=${tripId}&searchCacheKey=${searchCacheKey}&trainCacheKey=${trainCacheKey}`, null);
}
async function getResultsFareRules(tripId, transactionId, cacheKey) {
    return httpService.get(`/EvolviFareRules?tripId=${tripId}&transactionId=${transactionId}&cacheKey=${cacheKey}`, null);
}
async function getQuoteFareRules(quoteId, tripId) {
    return httpService.get(`/EvolviFareRules?quoteId=${quoteId}&tripId=${tripId}`, null);
}
async function getDeliveryMethod(quoteId) {
    return httpService.get(`/EvolviDeliveryMethod?quoteId=${quoteId}`, null);
}
async function getNonLowestReason(quoteId) {
    return httpService.get(`/EvolviNonLowest?quoteId=${quoteId}`, null);
}
async function getSeats(quoteId) {
    return httpService.get(`/EvolviSeats?quoteId=${quoteId}`, null);
}
async function getAccounts(quoteId) {
    return httpService.get(`/EvolviAccounts?quoteId=${quoteId}`, null);
}
async function getClientReference(quoteId) {
    return httpService.get(`/EvolviClientReference?quoteId=${quoteId}`, null);
}
async function getSplitMax(tripId) {
    return httpService.get(`/evolviSplitMax?tripId=${tripId}`, null);
}
// POST
async function saveDeliveryMethod(deliveryMethodToSave) {
    return httpService.post('/EvolviDeliveryMethod', deliveryMethodToSave);
}
async function saveNonLowestReason(quoteId, id) {
    return httpService.post('/EvolviNonLowest', { quoteId, id });
}
async function saveAccounts(savedAccounts) {
    return httpService.post('/EvolviAccounts', savedAccounts);
}
async function selectTickets(selectedTicketsInfo) {
    return httpService.post('/EvolviSelect', selectedTicketsInfo);
}
async function saveSeats(seatsSaveReq) {
    return httpService.post('/EvolviSeats', seatsSaveReq);
}
async function getTrainsInfo(searchParams) {
    return httpService.post('/EvolviSearch', searchParams);
}
async function setSplitMax(tripId, splitMax) {
    return httpService.post(`/evolviSplitMax?tripId=${tripId}&splitMax=${splitMax}`, null);
}
// PUT
async function saveClientReference(clientReferenceReq) {
    return httpService.put('/EvolviClientReference', clientReferenceReq);
}
